import React, { ReactNode, Ref } from "react";

interface ICustomDropDownToggleProps {
    disabled?: Boolean|undefined 
    children: ReactNode;
    onClick: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>)  => void;
}
    
export const CustomDropDownToggle = React.forwardRef((props: ICustomDropDownToggleProps, ref: Ref<HTMLSpanElement>) => (
    <span
        ref={ref}
        onClick={(event) => {
            if(!props.disabled){
                event.preventDefault();
                props.onClick(event);
            }
        }}
    >
        {props.children}
    </span>
));