import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react'
import {Button, Dropdown, Form, Modal, Tab, Tabs} from 'react-bootstrap'
import {constants, DEPENDANT_TYPE, GUEST_TYPE} from '../../../../config/constants'
import {baseCountries, countries} from '../../../../common/country_state/Countries'
import {Field, useFormik, FormikProvider} from 'formik'
import * as Yup from 'yup'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../store'
import * as ApiCallService from '../../../../services/ApiCallService'
import Swal from 'sweetalert2'
import moment from 'moment'
import {addGuestSchema, addDependantGuestSchema} from '../../../login/FormHelper'
import {useLocationBasedBtnColor} from '../../../../utilities/helpers'
import {useWarningConfirmAlert} from '../../../../utilities/alerts'
import {useTextColor} from '../../../../services/hooks/useTextColor'
import {CustomDropDownToggle} from '../../../profile/components/CustomDropDownToggle'
import {CustomDropDownMenu} from '../../../profile/components/CustomDropDownMenu'
import {FormattedMessage, useIntl} from 'react-intl'
import CountrySelector from '../../../../common/country_state/CountrySelector'

export interface IGuestInterface {
   first_name: string
   last_name: string
   phone: string
   relation: string
   imageSrc: string
   birthDate: string
}

export interface GuestDetails {
   first_name: string
   last_name: string
   phone_global_country_id?: number
   phone_number?: string
   global_association_id?: number
   email?: string
   gender?: number
   date_of_birth?: string
   primary?: number
   guest_id?: number
   status_pre_checkin?: number
   status_checkin?: number
   is_dependant: number
   global_country_id?: number
   country?: string
   state_name?: string
   global_state_id?: number
}

export interface IAddNewGuestForm {
   show: boolean
   type: GUEST_TYPE
   onHide: () => void
   onSuccess: (guest: GuestDetails) => void
}

interface IIdName {
   id: number
   name: string
}

const AddNewGuestForm = (props: IAddNewGuestForm) => {
   const intl = useIntl()

   const [selectedRelation, setSelectedRelation] = useState<null | IIdName>()
   const [selected, setSelected] = useState('')
   const [previousSelected, setPreviousSelected] = useState('')
   const [selectedCountry, setSelectedCountry] = useState('')
   const LocSettings = useSelector((state: RootState) => state.LocSettings.value)
   const [selectedGender, setSelectedGender] = useState<null | IIdName>()
   const [newDepedentGuestSchema] = useState(addDependantGuestSchema)
   const [newGuestSchema] = useState(addGuestSchema)
   const birthDateRef = useRef<HTMLInputElement>(null)
   const dynamicBtnColor = useLocationBasedBtnColor()
   const discardWarningAlert = useWarningConfirmAlert()
   const [textBgColor] = useTextColor()
   const [previousSelectedCountry, setPreviousSelectedCountry] = useState('')
   const [GlobalStateList, setGlobalStateList] = useState<Array<IIdName>>()
   const dateInputFormat = intl.formatMessage({id: 'registration_dob.input_format'})

   let initialValues: GuestDetails = {
      first_name: '',
      last_name: '',
      phone_number: '',
      global_country_id: 0,
      email: '',
      gender: -1,
      date_of_birth: '',
      global_association_id: -1,
      primary: 0,
      guest_id: 0,
      is_dependant: 0,
      country: '',
      global_state_id: 0,
      state_name: '',
   }

   const formik = useFormik<GuestDetails>({
      initialValues,
      validationSchema:
         props.type == GUEST_TYPE.DEPENDANT ? newDepedentGuestSchema : newGuestSchema,
      onSubmit: (values) => {
         values.global_country_id = parseInt(
            countries[props.type == GUEST_TYPE.DEPENDANT ? selected : selectedCountry]['id']
         )
         values.phone_number = values.phone_number?.replace(/[- )(]/g, '')
         if (values.email?.length == 0) delete values.email
         if (values.phone_number?.length == 0) delete values.phone_number
         if (props.type == GUEST_TYPE.OTHER) {
            delete values.global_association_id
            if (values.gender == -1) delete values.gender
            values.phone_global_country_id = parseInt(countries[selected]['id'])
         } else {
            delete values.phone_global_country_id
            delete values.state_name
            delete values.global_state_id
            values.is_dependant = 1
         }
         delete values.guest_id
         delete values.country
         delete values.state_name
         values.date_of_birth = moment(values.date_of_birth, dateInputFormat).format('YYYY-MM-DD')
         props.onSuccess(values)
      },
   })

   //Phone Number
   const [pn_place, setpn_place] = useState('(000) 000-0000')
   const [mask_format, setmask_format] = useState('(999) 999-9999')

   //Sets country flag on phone number field
   useEffect(() => {
      let defaultCountryCode = 'US'
      if (LocSettings.location.global_country_id > 0) {
         Object.keys(baseCountries).map(function (globalID) {
            if (globalID == LocSettings.location.global_country_id) {
               let countrycode = baseCountries[globalID]['id']
                  ? baseCountries[globalID]['id']
                  : defaultCountryCode
               setSelectedCountry(countrycode)
               setSelected(countrycode)
            }
         })
      } else {
         setSelectedCountry(defaultCountryCode)
         setSelected(defaultCountryCode)
      }
   }, [])

   useEffect(() => {
      let pn_length = 10
      if (countries[selected]?.pn_length > 0) {
         pn_length = countries[selected]?.pn_length
      }
      let Format = ''
      let placeHolder = ''
      for (let i = 0; i < pn_length; i++) {
         if (i == 0) {
            Format = Format + '('
            placeHolder = placeHolder + '('
         }
         if (i == 3) {
            Format = Format + ') '
            placeHolder = placeHolder + ') '
         }
         if (i == 6) {
            Format = Format + '-'
            placeHolder = placeHolder + '-'
         }
         Format = Format + '9'
         placeHolder = placeHolder + '0'
      }
      setpn_place(placeHolder)
      setmask_format(Format)
   }, [selected])

   Inputmask({
      mask: mask_format,
      oncomplete: function () {
         // setCompletedphone_number(true)
         return
      },
      removeMaskOnSubmit: true,
      onincomplete: function () {
         // setCompletedphone_number(false)
         //fireErrorMessage('Please complete phone number')
         return
      },
   }).mask('#kt_inputmask_1')

   const maskBirthDateInput = () => {
      const minimumAge = LocSettings.registration_minimum_age ?? 1

      Inputmask({
         alias: 'datetime',
         inputFormat: intl.formatMessage({id: 'registration_dob.input_format'}).toLowerCase(),
         placeholder: intl.formatMessage({id: 'registration_dob.placeholder'}),
         min: `${moment().subtract(100, 'years').format(dateInputFormat)}`,
         max: `${moment().subtract(minimumAge, 'years').format(dateInputFormat)}`,
         removeMaskOnSubmit: true,
         inputmode: 'numeric',
         onincomplete: function () {
            formik.setFieldValue('date_of_birth', birthDateRef.current!.value)
            return
         },
         oncomplete: function () {
            formik.setFieldValue('date_of_birth', birthDateRef.current!.value)
            return
         },
      }).mask('.birthdate_mask')
   }

   useEffect(() => {
      maskBirthDateInput()
      if (!props.show) {
         formik.resetForm()
         setSelectedRelation(undefined)
         setSelectedGender(undefined)
      }
   }, [props.show])

   const handleDiscard = () => {
      discardWarningAlert(
         intl.formatMessage({id: 'myaccount.addguest.alert.like_to_discard'}),
         intl.formatMessage({id: 'myaccount.addguest.alert.discard_it'}),
         intl.formatMessage({id: 'alert.no_return'})
      ).then((result) => {
         if (result.isDenied) {
            closeForm()
         }
      })
   }

   const closeForm = () => {
      props.onHide()
      formik.resetForm()
   }

   useEffect(() => {
      if (selectedCountry && selectedCountry != '') {
         let id = countries[selectedCountry]['id']
         ApiCallService.ApiCall(
            {global_country_id: id},
            `global/states`,
            'GET',
            LocSettings,
            true
         ).then((res: any) => {
            setGlobalStateList(res.data.states)
            if (formik.values.global_state_id! > 0) {
               let StateObj = res.data.states?.filter(
                  (r: any) => r.id == formik.values.global_state_id
               )[0]
               if (StateObj) {
                  formik.setFieldValue('state_name', StateObj.name)
               }
            }
         })
      }
   }, [selectedCountry])

   function SetCustomFieldValue(value: any, field: string) {
      formik.setFieldValue(field, parseInt(value))
      if (field == 'global_state_id') {
         if (value > 0) {
            let StateObj = GlobalStateList?.filter((state: IIdName) => state.id == value)[0]
            formik.setFieldValue('state_name', StateObj?.name)
         } else {
            formik.setFieldValue('state_name', '')
         }
      }
   }

   function GetRelationships() {
      const minimumAge = LocSettings.registration_minimum_age ?? 1
      if (minimumAge > 12)
         return constants.relationTypes.filter((type) => type.id != DEPENDANT_TYPE.CHILD)
      return constants.relationTypes
   }

   return (
      <Modal show={props.show} centered>
         <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit} className='form'>
               <Modal.Header>
                  <Modal.Title id='contained-modal-title-vcenter'>
                     {props.type == GUEST_TYPE.DEPENDANT
                        ? intl.formatMessage({id: 'myaccount.addguest.add_dependent'})
                        : intl.formatMessage({id: 'myaccount.addguest.add_new_guest'})}
                  </Modal.Title>
               </Modal.Header>
               <Modal.Body>
                  <div className='d-flex mb-7 fv-row fv-plugins-icon-container col-12'>
                     <div className='col-6 px-2'>
                        <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                           <span className='required'>
                              <FormattedMessage
                                 id='common.first_name'
                                 defaultMessage='First Name'
                              />
                           </span>
                        </label>
                        <input
                           onChange={formik.handleChange}
                           value={formik.values.first_name}
                           id='first_name'
                           type='text'
                           name='first_name'
                           className='form-control form-control-solid customInputBackground'
                        />
                        {formik.touched.first_name && formik.errors.first_name && (
                           <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                 {intl.formatMessage({id: formik.errors.first_name})}
                              </div>
                           </div>
                        )}
                     </div>
                     <div className='col-6 px-2'>
                        <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                           <span className='required'>
                              <FormattedMessage id='common.last_name' defaultMessage='Last Name' />
                           </span>
                        </label>
                        <input
                           onChange={formik.handleChange}
                           value={formik.values.last_name}
                           id='first_name'
                           type='text'
                           name='last_name'
                           className='form-control form-control-solid customInputBackground'
                        />
                        {formik.touched.last_name && formik.errors.last_name && (
                           <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                 {intl.formatMessage({id: formik.errors.last_name})}
                              </div>
                           </div>
                        )}
                     </div>
                  </div>

                  <div className='d-flex mb-7 fv-row fv-plugins-icon-container col-12'>
                     <div className='col-12 px-2'>
                        <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                           <span
                              className={`${props.type == GUEST_TYPE.DEPENDANT ? '' : 'required'}`}
                           >
                              <FormattedMessage id='common.phone' defaultMessage='Phone' />
                           </span>
                        </label>
                        <span className='input-group-text p-0 m-0' id='basic-addon1'>
                           <CountrySelector
                              selected={selected}
                              mode='phone'
                              setSelected={setSelected}
                           />
                           <input
                              onChange={formik.handleChange}
                              value={formik.values.phone_number}
                              id='kt_inputmask_1'
                              className='form-control form-control-solid'
                              inputMode='numeric'
                              type='text'
                              name='phone_number'
                              placeholder={pn_place}
                           />
                        </span>
                        {selected === '' && formik.errors.global_country_id && (
                           <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                 {intl.formatMessage({id: formik.errors.global_country_id})}
                              </div>
                           </div>
                        )}
                        {formik.touched.phone_number && formik.errors.phone_number && (
                           <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                 {intl.formatMessage({id: formik.errors.phone_number})}
                              </div>
                           </div>
                        )}
                        <div className='fv-plugins-message-container invalid-feedback'></div>
                     </div>
                  </div>
                  {props.type == GUEST_TYPE.OTHER && (
                     <div className='row row-cols-1 row-cols-md-2'>
                        <div className='col' style={{paddingLeft: '16px'}}>
                           <div className='fv-row mb-7 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid'>
                              <label htmlFor='country' className='fs-6 fw-bold form-label mb-2'>
                                 <span className='required'>
                                    <FormattedMessage
                                       id='common.country'
                                       defaultMessage='Country'
                                    />
                                 </span>
                              </label>
                              <div
                                 {...formik.getFieldProps('country')}
                                 className='p-0 m-0 form-control form-control-solid'
                                 id='basic-addon2'
                              >
                                 <React.Fragment>
                                    <CountrySelector
                                       selected={selectedCountry}
                                       mode='country'
                                       setSelected={setSelectedCountry}
                                    />
                                 </React.Fragment>
                              </div>
                              {formik.touched.country && formik.errors.country && (
                                 <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                       {intl.formatMessage({id: formik.errors.country})}
                                    </div>
                                 </div>
                              )}
                           </div>
                        </div>
                        <div className='col' style={{paddingLeft: '16px', paddingRight: '15px'}}>
                           <div className='fv-row mb-7 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid'>
                              <label
                                 htmlFor='global_state_id'
                                 className='fs-6 fw-bold form-label mb-2'
                              >
                                 <span className='required'>
                                    <FormattedMessage id='common.state' defaultMessage='State' />
                                 </span>
                              </label>
                              <Dropdown
                                 onSelect={(e: any) => {
                                    SetCustomFieldValue(e, 'global_state_id')
                                 }}
                              >
                                 <Dropdown.Toggle
                                    as={CustomDropDownToggle}
                                    id='dropdown-custom-components-country'
                                 >
                                    <div
                                       style={{height: '43px'}}
                                       className='form-select form-select-solid select2-hidden-accessible form-control-solid customInputBackground'
                                    >
                                       {formik.values.state_name}
                                    </div>
                                 </Dropdown.Toggle>

                                 <Dropdown.Menu
                                    className='w-100 fs-6'
                                    style={{overflowY: 'scroll', height: '200px'}}
                                    as={CustomDropDownMenu}
                                 >
                                    {GlobalStateList?.map(function (state: IIdName) {
                                       return (
                                          <Dropdown.Item
                                             key={state.id}
                                             className={
                                                formik.values.global_state_id == state.id
                                                   ? 'activeCallCodeID genderMenuOptions'
                                                   : 'genderMenuOptions'
                                             }
                                             eventKey={state.id}
                                          >
                                             {state.name}
                                          </Dropdown.Item>
                                       )
                                    })}
                                 </Dropdown.Menu>
                              </Dropdown>
                              {formik.touched.global_state_id && formik.errors.global_state_id && (
                                 <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                       {intl.formatMessage({id: formik.errors.global_state_id})}
                                    </div>
                                 </div>
                              )}
                           </div>
                        </div>
                     </div>
                  )}
                  <div className='d-flex mb-7 fv-row fv-plugins-icon-container col-12'>
                     <div
                        className={`col-${
                           selectedGender && selectedGender.id == 0 && window.innerWidth < 450
                              ? '7'
                              : '6'
                        } px-2`}
                     >
                        <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                           <span className={props.type == GUEST_TYPE.DEPENDANT ? 'required' : ''}>
                              <FormattedMessage id='common.gender' defaultMessage='Gender' />
                           </span>
                        </label>

                        <Dropdown
                           onSelect={(key, event) => {
                              let selGender = constants.GenderTypes.find(
                                 (genderType) => genderType.id.toString() === key
                              )
                              setSelectedGender(selGender)
                              formik.setFieldValue('gender', selGender?.id)
                           }}
                        >
                           <Dropdown.Toggle
                              as={CustomDropDownToggle}
                              id='dropdown-custom-components'
                           >
                              <div
                                 style={{height: '43px', whiteSpace: 'nowrap'}}
                                 className={`form-select form-select-solid select2-hidden-accessible form-control-solid customInputBackground`}
                              >
                                 {selectedGender
                                    ? intl.formatMessage({id: selectedGender.name})
                                    : intl.formatMessage({id: 'common.select'})}
                              </div>
                           </Dropdown.Toggle>

                           <Dropdown.Menu className='w-100 fs-6'>
                              {constants.GenderTypes.map((item: any, index) => {
                                 return (
                                    <Dropdown.Item
                                       eventKey={item.id}
                                       className={
                                          selectedGender && selectedGender.name == item.name
                                             ? 'activeCallCodeID genderMenuOptions'
                                             : 'genderMenuOptions'
                                       }
                                    >
                                       {intl.formatMessage({id: item.name})}
                                    </Dropdown.Item>
                                 )
                              })}
                           </Dropdown.Menu>
                        </Dropdown>

                        {formik.touched.gender && formik.errors.gender && (
                           <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                 {intl.formatMessage({id: formik.errors.gender})}
                              </div>
                           </div>
                        )}
                     </div>
                     <div
                        className={`col-${
                           selectedGender && selectedGender.id == 0 && window.innerWidth < 450
                              ? '5'
                              : '6'
                        } px-2`}
                     >
                        <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                           <span className='required'>
                              <FormattedMessage
                                 id='common.birth_date'
                                 defaultMessage='Birth Date'
                              />
                           </span>
                        </label>
                        <input
                           onBlur={formik.handleChange}
                           ref={birthDateRef}
                           id='date_of_birth'
                           type='text'
                           className='birthdate_mask form-control form-control-solid'
                           name='date_of_birth'
                           placeholder={intl.formatMessage({id: 'registration_dob.placeholder'})}
                           disabled={false}
                           inputMode='numeric'
                        />
                        {formik.touched.date_of_birth && formik.errors.date_of_birth && (
                           <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                 {intl.formatMessage({id: formik.errors.date_of_birth})}
                              </div>
                           </div>
                        )}
                     </div>
                  </div>
                  <div className='d-flex mb-7 fv-row fv-plugins-icon-container col-12'>
                     {props.type == GUEST_TYPE.DEPENDANT && (
                        <div className='col-6 px-2'>
                           <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                              <span className='required'>
                                 <FormattedMessage
                                    id='myaccount.addguest.relationship'
                                    defaultMessage='Relationship'
                                 />
                              </span>
                           </label>

                           <Dropdown
                              onSelect={(key, event) => {
                                 let selRelation = constants.relationTypes.find(
                                    (relationType) => relationType.id.toString() === key
                                 )
                                 setSelectedRelation(selRelation)
                                 formik.setFieldValue('global_association_id', selRelation?.id)
                              }}
                           >
                              <Dropdown.Toggle
                                 as={CustomDropDownToggle}
                                 id='dropdown-custom-components'
                              >
                                 <div
                                    style={{height: '43px'}}
                                    className='form-select form-select-solid select2-hidden-accessible form-control-solid customInputBackground'
                                 >
                                    {selectedRelation
                                       ? intl.formatMessage({id: selectedRelation.name})
                                       : intl.formatMessage({id: 'common.select'})}
                                 </div>
                              </Dropdown.Toggle>
                              <Dropdown.Menu className='w-100 fs-6'>
                                 {GetRelationships().map((item: IIdName, index) => {
                                    return (
                                       <div>
                                          <Dropdown.Item
                                             eventKey={item.id}
                                             className={
                                                selectedRelation &&
                                                selectedRelation.name == item.name
                                                   ? 'activeCallCodeID genderMenuOptions'
                                                   : 'genderMenuOptions'
                                             }
                                          >
                                             {intl.formatMessage({id: item.name})}
                                          </Dropdown.Item>
                                          {item.id == 1 && (
                                             <label className='d-flex fs-8 mx-5 mb-2'>
                                                <FormattedMessage
                                                   id='myaccount.addguest.child_account_create_by_guardian'
                                                   defaultMessage='Child account can be created by a parent or guardian 12 and younger'
                                                />
                                             </label>
                                          )}
                                       </div>
                                    )
                                 })}
                              </Dropdown.Menu>
                           </Dropdown>

                           {formik.touched.global_association_id &&
                              formik.errors.global_association_id && (
                                 <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                       {intl.formatMessage({
                                          id: formik.errors.global_association_id,
                                       })}
                                    </div>
                                 </div>
                              )}
                        </div>
                     )}
                     <div className={`col-${props.type == GUEST_TYPE.OTHER ? '12' : '6'} px-2`}>
                        <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                           <FormattedMessage id='common.email' defaultMessage='Email' />
                        </label>
                        <input
                           onChange={formik.handleChange}
                           value={formik.values.email}
                           id='email'
                           type='text'
                           name='email'
                           className='form-control form-control-solid customInputBackground'
                        />
                        {formik.touched.email && formik.errors.email && (
                           <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                 {intl.formatMessage({id: formik.errors.email})}
                              </div>
                           </div>
                        )}
                     </div>
                  </div>
               </Modal.Body>
               <Modal.Footer>
                  <div className='card-footer d-flex justify-content-end  pt-0 ps-9 pe-0 pb-0'>
                     <button
                        type='button'
                        onClick={handleDiscard}
                        id='discardGuestbtn'
                        className='btn btn-light-danger border-active me-2'
                     >
                        <FormattedMessage id='common.discard' defaultMessage='Discard' />
                     </button>
                     <button
                        type='submit'
                        id='saveGuestbtn'
                        className={`btn btn-primary ${textBgColor?.textBorderColorBasedBgPCLoction} ${dynamicBtnColor}`}
                     >
                        <FormattedMessage id='common.save' defaultMessage='Save' />
                     </button>
                  </div>
               </Modal.Footer>
            </form>
         </FormikProvider>
      </Modal>
   )
}

export default AddNewGuestForm
