import React, {Suspense, useContext, useEffect} from 'react'
import {getCSSVariableValue} from "../../../../_spc/assets/ts/_utils";
import TopBarProgress from "react-topbar-progress-indicator";
import { toAbsoluteUrl } from '../../../../_spc/helpers';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { HeaderTitleContext } from '../../../../_spc/layout/components/HeaderTitleContext/HeaderTitleContextStore';
const GLOBAL_ON_ID = process.env.REACT_APP_GLOBAL_ON;

const Spinner = () => {
  const LocSettings = useSelector((state: RootState) => state.LocSettings.value);
  const { setPageTitle } = useContext(HeaderTitleContext);
useEffect(() => {
    if(LocSettings && setPageTitle)
    setPageTitle(LocSettings?.location?.location_name)
},[LocSettings])

 let  softpointSmallLogo ='';

    if (LocSettings?.icon) {
        softpointSmallLogo = `${process.env.REACT_APP_SP_API_URL}/download-file?path=${LocSettings?.icon}&global_on_id=${GLOBAL_ON_ID}&user_id=${LocSettings.location.owner}`;
    } else if (LocSettings?.location?.logo) {
        softpointSmallLogo = `${process.env.REACT_APP_SP_API_URL}/download-file?path=${LocSettings.location.logo}&global_on_id=${GLOBAL_ON_ID}&user_id=${LocSettings.location.owner}`;
    }

    return (

        <div style={{height:'75vh'}} className="d-flex justify-content-center flex-column align-items-center w-100">
            {
                softpointSmallLogo ? <img className='h-45px w-45px mb-5 symbol'   src={ softpointSmallLogo} 
                onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.alt="logo";
                  }}
                 />:''
            }
            <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden"></span>
            </div>
        </div>
    );
};

export const LoadingPage = () => {
    const baseColor = getCSSVariableValue('--kt-primary')
    TopBarProgress.config({
        barColors: {
            '0': baseColor,
        },
        barThickness: 1,
        shadowBlur: 5,
    })
    return <Spinner />
}
