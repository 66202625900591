/* eslint-disable react/jsx-no-target-blank */
import { useEffect, useState } from 'react'
import {AsideMenuItem} from './AsideMenuItem'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../app/store';
import StaticRoutePath from '../../../../../app/routing/StaticRoutePath'
import { constants } from '../../../../../app/config/constants'
import { setSidebarActiveMenu } from '../../../../../app/services/store/sidebar'
import { useIntl } from 'react-intl';

export function AsideMenuMain() {
    const intl = useIntl();
    const StoreDispatch = useDispatch();

    const LocSettings = useSelector((state: RootState) => state.LocSettings.value);
    const sidebarActiveMenu = useSelector((state: RootState) => state.sidebarActiveMenu.value);
    const STATUSES = constants.status
    
    const [activeMenu,setActiveMenu] = useState<number>(1)

    const locationIntegrations:any[] = LocSettings?.integrations
    const globalCheckinIntegrationIds = constants.integrations.checkin.global_ids

    const checkinProducts = LocSettings?.location?.location_products?.filter((locp:any)=>locp.code=="CHECK_IN")
    const checkinProduct = checkinProducts.length > 0 ? checkinProducts[0] : null
    const checkInIntegrations = locationIntegrations.filter((locationIntegration)=> {
        return globalCheckinIntegrationIds.includes(locationIntegration.global_integration_id)
    })
    const checkinIntegrationConfigured = checkInIntegrations.length > 0
    const locationCheckinProductEnabled = checkinProduct && checkinProduct.status == STATUSES.ON
    const globalCheckinProductEnabled = checkinProduct && checkinProduct.global_product_status == STATUSES.ON
    const checkinIsAvailable = locationCheckinProductEnabled && checkinIntegrationConfigured && globalCheckinProductEnabled

    const globalReservationIntegrationIds = constants.integrations.reservations.global_ids
    const locationReservationProducts = LocSettings?.location?.location_products?.filter((locp:any)=>locp.code=="RESERVATION")
    const locationReservationProduct = locationReservationProducts.length > 0 ? locationReservationProducts[0] : null
    const reservationIntegrations = locationIntegrations.filter((integration)=>{
        return globalReservationIntegrationIds.includes(integration.global_integration_id)
    })
    const reservationIntegrationConfigured = reservationIntegrations.length > 0
    const locationReservationProductEnabled = locationReservationProduct && locationReservationProduct.status == STATUSES.ON
    const globalReservationProductEnabled = locationReservationProduct && locationReservationProduct.global_product_status == STATUSES.ON
    const reservationsAreAvailable = locationReservationProductEnabled && globalReservationProductEnabled && reservationIntegrationConfigured

    let IsOnlineOrder = false;
    if(LocSettings.olo?.allow_ordering==true){
        IsOnlineOrder = true;
    }

    const onBackButtonEvent = (e:any) => {
        let pathToChecks:Map<string,number> = new Map<string,number>();
        pathToChecks.set(constants.routes.private.pre_checkin,4);// = 4;
        pathToChecks.set(constants.routes.private.my_account,5);
        pathToChecks.set(constants.routes.public.olo,2);
        pathToChecks.forEach((menuId:number,path:string)=>{
            if((window.location.pathname).indexOf(path) > -1)
                StoreDispatch(setSidebarActiveMenu(menuId));
        })
    }

    useEffect(() => {
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);  
    };
  }, []);
    
  return (
    <>
        {/* "to" points to desired private routes */}
        <AsideMenuItem
            to='/'
            title={intl.formatMessage({id:"dashboard.menu.home"})}
            icon='/media/icons/duotune/custom/home.svg'
            fontIcon='bi-layers'
            id={1}
            setActiveMenu={setActiveMenu}
            activeMenu={sidebarActiveMenu}
        />
        {/* <AsideMenuItem
            to='/order'
            title='Start A Order'
            icon='/media/icons/duotune/ecommerce/ecm012.svg'
            fontIcon='bi-layers'
            redirectTo={false}
            id={2}
            activeMenu={sidebarActiveMenu}
            setActiveMenu={setActiveMenu}
        /> */}
         {
                IsOnlineOrder?
                <AsideMenuItem
                    to= {StaticRoutePath.OLO_MENU }
                    title={intl.formatMessage({id:"dashboard.menu.online_order"})}
                    icon='/media/icons/duotune/ecommerce/ecm012.svg'
                    fontIcon='bi-layers'
                    id={2}
                    redirectTo={false}
                    activeMenu={sidebarActiveMenu}
                    setActiveMenu={setActiveMenu}
                 />:''
            }
            
             {
                reservationsAreAvailable?
                <AsideMenuItem
                    to='/reservations'
                    title={intl.formatMessage({id:"dashboard.menu.book_a_reservation"})}
                    icon='/media/icons/duotune/files/fil026.svg'
                    fontIcon='bi-layers'
                    redirectTo={false}
                    id={3}
                    activeMenu={sidebarActiveMenu}
                    setActiveMenu={setActiveMenu}
                 />:''
            }
            {
                checkinIsAvailable?
                <AsideMenuItem
                    to= 'pre-checkin'
                    title={intl.formatMessage({id:"sidebar.menu.check_in"})}
                    icon='/media/icons/duotune/general/gen063.svg'
                    fontIcon='bi-chat-left'
                    redirectTo={false}
                    id={4}
                    activeMenu={sidebarActiveMenu}
                    setActiveMenu={setActiveMenu}
                />:''
            }
        
        <AsideMenuItem
            to='profile'
            title={intl.formatMessage({id:"dashboard.menu.my_account"})}
            icon='/media/icons/duotune/general/gen064.svg'
            fontIcon='bi-person'
            redirectTo={false}
            id={5}
            activeMenu={sidebarActiveMenu}
            setActiveMenu={setActiveMenu}



        />
    </>
  );
};
