import * as Yup from 'yup'

export interface ITENDBAR {
    phoneNumber: string
    birthDate: string,
    firstName: string,
    lastName: string,
    email: string,
    state?: string,
    city?: string,
    checked: [],
}

const firstNameValidation = Yup.string()
        .min(2, 'form.error.too_short')
        .max(20, 'form.error.too_long')
        .matches(/^(?! )(?!.* {2})[a-zA-ZñÑãÃõÕéÉáÁíÍóÓúÚ\s,.'-]+$/, 'form.error.invalid_first_name')
        .required("form.error.first_name_required");    

const lastNameValidation = Yup.string()
        .min(2, 'form.error.too_short')
        .max(20, 'form.error.too_long')
        .matches(/^(?! )(?!.* {2})[a-zA-ZñÑãÃõÕéÉáÁíÍóÓúÚ\s,.'-]+$/, 'form.error.invalid_last_name')
        .required('form.error.last_name_required');

const dobValidation = Yup.string().required('form.error.birth_date_required').test('dob_date_check', 'form.error.enter_full_date', (value) => {
    if (!value) return false

    let dateRegexen = /^\d{2}-\d{2}-\d{4}$/
    let dateRegexsp = /^\d{2}\/\d{2}\/\d{4}$/
    return dateRegexen.test(value) || dateRegexsp.test(value) 
});

const emailValidation = Yup.string().email('form.error.must_be_valid_email').max(255).required('form.error.email_required');
const emailValidationNonReq = Yup.string().email('form.error.must_be_valid_email').notRequired().max(255);

const phoneValidation = Yup.string().required('form.error.phone_required');

const createSchema = [
    Yup.object({
        phoneNumber: phoneValidation
    }),
    Yup.object({
        birthDate: dobValidation
    }),
]

const signupSchema = [
    Yup.object().shape({
        phoneNumber: phoneValidation,
        birthDate: dobValidation,
        firstName: firstNameValidation,
        lastName: lastNameValidation,
        email: emailValidation,
    })
]

const signupSchemaEmailNonReq = 
    Yup.object().shape({
        phoneNumber: phoneValidation,
        birthDate: dobValidation,
        firstName: firstNameValidation,
        lastName: lastNameValidation,
        email: emailValidationNonReq,
        state: Yup.string().required().label('State'),
    })

const signupSchemaEmailPhoneNonReq = 
    Yup.object().shape({
        birthDate: dobValidation,
        firstName: firstNameValidation,
        lastName: lastNameValidation,
        email: emailValidationNonReq,
        state: Yup.string().required('form.error.state_required'),
    })

const OCRSchema = [
    Yup.object().shape({
        birthDate: dobValidation,
        firstName: firstNameValidation,
        lastName: lastNameValidation,
    })]

const addDependantGuestSchema = 
    Yup.object().shape({
        date_of_birth: dobValidation,
        first_name: firstNameValidation,
        last_name: lastNameValidation,
        global_association_id: Yup.number().moreThan(-1,"form.error.relation_required").required(),
        gender: Yup.number().moreThan(-1,"form.error.gender_required").required(),
        email: emailValidationNonReq,
    })

const addGuestSchema =
    Yup.object().shape({
        phone_number: phoneValidation,
        first_name: firstNameValidation,
        last_name: lastNameValidation,
        email: emailValidationNonReq,
        date_of_birth: dobValidation,
        global_state_id: Yup.number().notOneOf([0], 'form.error.state_required'),
    })


const guestContactInfoFormValidationSchema = Yup.object().shape({
    first_name: firstNameValidation,
    last_name: lastNameValidation,
    email: emailValidation,
    phone: phoneValidation,
    })

    const emailValidationSchema =  Yup.object().shape({
        email: emailValidation,
    })

const inits: ITENDBAR = {
    phoneNumber: '',
    birthDate: '',
    firstName: '',
    lastName: '',
    email: '',
    checked: [],
}

export { createSchema, inits, signupSchema,signupSchemaEmailNonReq, signupSchemaEmailPhoneNonReq, OCRSchema,addDependantGuestSchema,addGuestSchema,guestContactInfoFormValidationSchema, emailValidationSchema }

