import {Outlet, useNavigate} from "react-router-dom";
// @ts-ignore
import Cookies from 'js-cookie'
import {constants} from "../../config/constants";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../store";
import {useEffect, useState} from "react";
import {useLocation} from "react-router";
import {locationHasBiometricsOn, locationHasCOFOn} from "../../common/core/BusinessLogic";
import {
    clearRegistrationChecklist, setAcceptTermsDone,
    setAddedCOFDone,
    setIdentityFormsDone, setRegistrationID, setSessionGuestId, setVerifiedIdentityDone
} from "../../services/store/registration/RegistrationSlice";
import {useLocationDomain} from "../../common/hooks/CommonHooks";
import {logOutGuest, setGuest} from "../../services/store/guests";
import * as ApiCallService from "../../services/ApiCallService";
import {instanceOfSpError, ISpError} from "../../common/api_interfaces/Errors";
import Swal from "sweetalert2";
import {useErrorAlert} from "../../utilities/alerts";
import {LoadingPage} from "../widgets/Loaders/LoadingPage";
import {ISPGuest} from "../../common/api_interfaces/Guest";
import { useIntl } from "react-intl";

export const RegistrationController = () => {
    const intl = useIntl();
    const appDispatch = useDispatch<AppDispatch>()
    const navigateTo = useNavigate()
    const locationDomain = useLocationDomain()
    const {cookies_name, errors, routes} = constants
    const locationSettings = useSelector((state:RootState)=>state.LocSettings.value)
    const reservation = useSelector((state:RootState)=>state.reservation)
    const [guestIsLoading, setGuestLoading] = useState<boolean>(true)
    const registrationSteps:any = useSelector((state:RootState)=>state.registrationSessionTracker)
    const guestData:any = useSelector((state:RootState)=>state.guest.data)
    const currentUrlHistory = useLocation()
    const fireErrorAlert = useErrorAlert()
    const currentRouteArray = currentUrlHistory.pathname.split('/')
    const currentChildRoute = currentRouteArray[currentRouteArray.length-1]
    const guest = useSelector((state: any) => state.guest);

    useEffect(()=>{        
        if(guest.auth.isAuthenticated == true && reservation.isTempReg!=true) {            
            navigateTo(`/${locationDomain}/my-account`)
        }
        else if(['register', ''].includes(currentChildRoute) || registrationSteps.guest_id === null){
            console.log("Redirec to AUTH from Registration COntroller")
            appDispatch(clearRegistrationChecklist())
            appDispatch(logOutGuest())
            navigateTo(`/${locationDomain}/auth`)
        }
    }, [])

    useEffect(() => {
        if(registrationSteps.guest_id !== null){
            const guestID = registrationSteps.guest_id
            ApiCallService.ApiCall({location_id: locationSettings.location.id}, `guests/${guestID}`, 'GET', locationSettings, true)
                .then((res: any) => {
                    const jsonRes: any | ISpError = res
                    let msg = intl.formatMessage({id:errors.static.default_error})
                    if(instanceOfSpError(jsonRes)){
                        msg = jsonRes.error.message
                        Swal.close()
                        fireErrorAlert(msg)
                            .then(result => navigateTo('/' + locationDomain))
                    }

                    const guest = res.data as ISPGuest
                    appDispatch( setGuest(res.data) )
                    const missingStep = calculateMissingSteps()
                    setGuestLoading(false)

                    console.log("MISSING STEPS", missingStep)
                    console.log("guest", guest)

                    //

                    if(missingStep === null){
                        /*appDispatch(setAcceptTermsDone(true))
                        appDispatch(setIdentityFormsDone(true))
                        appDispatch(setVerifiedIdentityDone(true))
                        appDispatch(setAddedCOFDone(true))*/
                        navigateTo(`/${locationDomain}/${routes.private.submission_review}`)
                    }
                    else{
                        navigateTo(`/${locationDomain}${missingStep}`)
                        /*if(missingStep === routes.private.registration_terms){
                            /!*appDispatch(setAcceptTermsDone(false))
                            appDispatch(setIdentityFormsDone(false))
                            appDispatch(setVerifiedIdentityDone(false))
                            appDispatch(setAddedCOFDone(false))*!/
                            navigateTo(`/${locationDomain}${missingStep}`)
                        }
                        else if(missingStep === routes.private.share_guest){
                            navigateTo(`/${locationDomain}${missingStep}`)
                        }else if(missingStep === routes.private.registration_forms){
                            const guestChildObjs = guest._embedded
                            /!*appDispatch(setAcceptTermsDone(true))
                            appDispatch(setIdentityFormsDone(false))
                            appDispatch(setVerifiedIdentityDone(false))
                            appDispatch(setAddedCOFDone(false))*!/
                            /!*if(guestChildObjs !== null && guestChildObjs.registration !== null) {
                                appDispatch(setRegistrationID(guestChildObjs.registration.id))
                            }*!/
                            navigateTo(`/${locationDomain}${missingStep}`)
                        }
                        else{
                            const guestChildObjs = guest._embedded
                            /!*appDispatch(setAcceptTermsDone(true))
                            appDispatch(setIdentityFormsDone(true))
                            appDispatch(setVerifiedIdentityDone(true))
                            appDispatch(setAddedCOFDone(false))*!/
                            /!*if(guestChildObjs !== null && guestChildObjs.registration !== null) {
                                appDispatch(setRegistrationID(guestChildObjs.registration.id))
                            }*!/
                            navigateTo(`/${locationDomain}${missingStep}`)
                        }*/
                    }
                }).catch((err) => {
                appDispatch( setGuest({}));
                setGuestLoading(false);
            })
        }else{
            setGuestLoading(false);
        }
    }, [registrationSteps.guest_id])

    useEffect(()=>{
        /*return () => {
            appDispatch(clearRegistrationChecklist())
        }*/
    }, [])

    const calculateMissingSteps = () => {

        const locationBioOn = locationHasBiometricsOn(locationSettings)
        const locationPayOn = locationHasCOFOn(locationSettings)

        if(registrationSteps.check_list.accept_share_guest == 0) {
            return constants.routes.private.share_guest
        } else if(registrationSteps.check_list.terms == 0) {
            return constants.routes.private.registration_terms
        } else if(registrationSteps.check_list.identity_forms == 0 && locationBioOn) {
            return constants.routes.private.registration_forms
        } else if(registrationSteps.check_list.identity_verified == 0) {
            return constants.routes.private.registration_verification
        } else if(registrationSteps.check_list.add_cof == 0 && locationPayOn) {
            return constants.routes.private.registration_pay_method
        }
        return null
    }


    return(
        <>
         <div style={{display:guestIsLoading?"none":'block'}}>
             <Outlet/>
         </div>
         {guestIsLoading && (
                  <div className="position-absolute top-0 start-0 end-0 bottom-0" style={{backgroundColor:locationSettings.appearance === 1
                    ? '#fffff'
                    : '#151521'}}>
                <LoadingPage/>
                </div>
             )}
        </>
    )
}




const calculateMissingStepsOld = (guest:ISPGuest, locationSettings:any) => {
    const hasFirstname = guest.first_name !== null
    const hasLastname = guest.last_name !== null
    const hasEmail = guest.email !== null
    const hasPhone = guest.phone !== null

    const guestIsVerified = hasFirstname && hasLastname && hasPhone && hasEmail

    const guestAcceptedTerms = guest._embedded?.registration !== undefined &&
        guest._embedded?.registration !== null &&
        'id' in guest._embedded?.registration

    let selected_location_found = guest._embedded?.location_groups.find((guest_loc: { location_id: number; }) => guest_loc.location_id == locationSettings.location.id);
    const guestAcceptedShareProfile = selected_location_found == undefined;

    const hasBiometrics = Array.isArray(guest._embedded?.biometrics) && guest._embedded!.biometrics.length > 0

    const locationBioOn = locationHasBiometricsOn(locationSettings)
    const locationPayOn = locationHasCOFOn(locationSettings)

    if(!guestAcceptedShareProfile) return constants.routes.private.share_guest
    if(!guestAcceptedTerms) return constants.routes.private.registration_terms
    if(locationBioOn && !hasBiometrics) return constants.routes.private.registration_forms
    if(!guestIsVerified) return constants.routes.private.registration_verification
    if(locationPayOn) return constants.routes.private.registration_pay_method

    return null
}
