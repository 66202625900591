import { useState} from 'react'
import { Modal} from 'react-bootstrap'
import {Field, useFormik, FormikProvider} from 'formik'
import * as Yup from 'yup'
import {useSelector} from 'react-redux'
import {RootState} from '../../../store'
import {useLocationBasedBtnColor} from '../../../utilities/helpers'
import {useWarningConfirmAlert} from '../../../utilities/alerts'
import {useTextColor} from '../../../services/hooks/useTextColor'
import {FormattedMessage, useIntl} from 'react-intl'
import { emailValidationSchema } from '../../../pages/login/FormHelper'
 
export interface ISendCodeByEmailForm {
   show: boolean
   onSuccess: (email: string) => void
   onHide: () => void
}

interface IEmailCode {
   email: string
}

const SendCodeByEmailForm = (props: ISendCodeByEmailForm) => {
   const intl = useIntl()
 
   const [validationSchema] = useState(emailValidationSchema)
   const dynamicBtnColor = useLocationBasedBtnColor()
   const discardWarningAlert = useWarningConfirmAlert()
   const [textBgColor] = useTextColor()

   let initialValues: IEmailCode = {
      email: ''
   }

   const formik = useFormik<IEmailCode>({
      initialValues,
      validationSchema: validationSchema,
      onSubmit: (values) => {
        debugger
         props.onSuccess(values.email)
      },
   })

 
   const handleDiscard = () => {
        closeForm()
   }

   const closeForm = () => {
      props.onHide()
      formik.resetForm()
   }
   
   return (
      <Modal show={props.show} centered>
         <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit} className='form'>
               <Modal.Header>
                  <Modal.Title id='contained-modal-title-vcenter text-center'>
                        Send verification code by email
                  </Modal.Title>
               </Modal.Header>
               <Modal.Body>
                  <div className='d-flex fv-row fv-plugins-icon-container col-12'>
                     <div className='col-12'>
                     <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                           <FormattedMessage id='common.email' defaultMessage='Email' />
                        </label>
                        <input
                           onChange={formik.handleChange}
                           value={formik.values.email}
                           id='email'
                           type='text'
                           name='email'
                           className='form-control form-control-solid customInputBackground'
                        />
                        {formik.touched.email && formik.errors.email && (
                           <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                 {intl.formatMessage({id: formik.errors.email})}
                              </div>
                           </div>
                        )}
                     </div>
                  </div>
               </Modal.Body>
               <Modal.Footer>
                  <div className='card-footer d-flex justify-content-end  pt-0 ps-9 pe-0 pb-0'>
                     <button
                        type='button'
                        onClick={handleDiscard}
                        id='discardGuestbtn'
                        className='btn btn-light-danger border-active me-2'
                     >
                        <FormattedMessage id='alert.cancel' defaultMessage='Cancel' />
                     </button>
                     <button
                        type='submit'
                        id='saveGuestbtn'
                        className={`btn btn-primary ${textBgColor?.textBorderColorBasedBgPCLoction} ${dynamicBtnColor}`}
                     >
                        <FormattedMessage id='common.send' defaultMessage='Send' />
                     </button>
                  </div>
               </Modal.Footer>
            </form>
         </FormikProvider>
      </Modal>
   )
}

export default SendCodeByEmailForm
