import React from 'react'
import moment from 'moment'
import {useSelector} from 'react-redux'
import {LoadingPage} from '../../../../modules/widgets/Loaders/LoadingPage'
import {FormattedMessage, useIntl} from 'react-intl'

export const HeaderReservation = () => {
   const intl = useIntl()
   const location_settings_data = useSelector((state: any) => state.LocSettings.value)
   const reservation = useSelector((state: any) => state.reservation)

   if (reservation.isLoading) {
      return <LoadingPage />
   }

   const reservation_data = reservation.data
   const reservation_embedded = reservation_data?._embedded
   const {guests: reservation_guests} = reservation_embedded ? reservation_embedded : ''
   const GLOBAL_ON = process.env.REACT_APP_GLOBAL_ON

   let reservation_banner = ''

   if (location_settings_data?.reservation_banner) {
      reservation_banner = `${process.env.REACT_APP_SP_API_URL}/download-file?path=${location_settings_data?.reservation_banner}&global_on_id=${GLOBAL_ON}&user_id=${location_settings_data.location.owner}`
   }

   let date_at = intl.formatMessage({id: 'dates.at'})

   return (
      <div data-theme='light'>
         <div
            style={{
               ...styles.card_image,
               backgroundColor: location_settings_data?.background_color,
            }}
            className='w-100 card-rounded mb-5'
         >
            {reservation_banner ? (
               <img
                  className='w-100 card-rounded'
                  style={{
                     ...styles.card_image,
                     backgroundColor: location_settings_data?.background_color,
                  }}
                  src={reservation_banner}
               />
            ) : (
               ''
            )}
         </div>

         <div className='card'>
            <div style={styles.card_body} className='card-body d-flex ps-xl-15'>
               <div className='m-0'>
                  <div className='position-relative fs-2x z-index-2 fw-bold text-white mb-7'>
                     <span className='me-2'>
                        <span className='position-relative d-inline-block text-danger'>
                           <a href='src/app/pages/pre-checkin/steps/reviewDetailsPage/ReviewPage#'></a>
                           <span className='position-absolute opacity-50 bottom-0 start-0 border-4 border-bottom w-100'></span>
                        </span>
                     </span>
                     <br />
                  </div>
                  <div>
                     <span style={styles.card_fontSize} className='card-label fw-bold text-white'>
                        {location_settings_data?.location.location_name}
                     </span>
                     <br />
                     <span className='text-gray-400 mt-1 fw-semibold fs-6'>
                        {moment(reservation_data.check_in).format(`ddd, MMM DD [${date_at}] LT`)}
                        <br /> {reservation_guests.guests_count}{' '}
                        <FormattedMessage id='common.guests' defaultMessage='Guest(s)' />
                     </span>
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}

const styles: {[key: string]: React.CSSProperties} = {
   card_body: {
      bottom: '5px',
      position: 'absolute',
   },
   card_fontSize: {
      fontSize: 'large',
   },
   card_image: {
      filter: 'brightness(0.7)',
      height: '300px',
   },
}
