import './css/ProductStyle.scss'
import React, {useEffect, useState} from 'react'
import {AppDispatch, RootState} from '../../store'
import {HeroImage} from './components/HeroImage'
import {useDispatch, useSelector} from 'react-redux'
import 'tiny-slider/dist/tiny-slider.css'
import {MenuItemCard} from './components/MenuItemCard'
import {useActiveMenu} from 'react-active-menu'
import {useNavigate, useLocation} from 'react-router-dom'
import {capitalizeWords, renderDisclaimer} from '../../utilities/helpers'
import {itemObject, menuCategory} from '../../services/store/ordering/menu/categories'
import StaticRoutePath from '../../routing/StaticRoutePath'
import {constants} from '../../config/constants'
// @ts-ignore
import Cookies from 'js-cookie'
import {setCartButtonDisplay} from '../../services/store/ordering/cart/CartButtonSlice'
import {FormattedMessage, useIntl} from 'react-intl'

export interface IOrderAllCatPageProps {}

export interface ILocationState {
  catId: number
  categorySectionId: string
}

export const AllMenuItemsView: React.FunctionComponent<IOrderAllCatPageProps> = () => {
  const intl = useIntl()
  const location = useLocation()
  const {pathname} = useLocation()
  const navigateTo = useNavigate()
  const dispatch = useDispatch<AppDispatch>()
  const [showAll, setShowAll] = useState(false)
  const locationState = location.state as ILocationState
  const LocSettings = useSelector((state: RootState) => state.LocSettings.value)
  const disclaimer = useSelector((state: RootState) => state.LocSettings.value.olo?.disclaimer)
  const menuCategoriesData: menuCategory[] = useSelector(
    (state: RootState) => state.menuCategories.data
  )
  const menuCategoriesIsloading: boolean = useSelector(
    (state: RootState) => state.menuCategories.isLoading
  )
  let base_domain = LocSettings.base_domain
  const {cookies_name} = constants
  const [categoryData, setCategory] = useState<menuCategory>()

  //Get data of selected category
  const findCategory = () => {
    setCategory(menuCategoriesData.find((category) => category.id === locationState?.catId))
  }

  if ((base_domain && base_domain.trim() === '') || base_domain == null) {
    base_domain = `/${LocSettings.location.id}`
  }
  let IsOnlineOrder = false
  if (LocSettings.olo.allow_ordering == true) {
    IsOnlineOrder = true
  }
  useEffect(() => {
    let domain = LocSettings.base_domain
    if (domain && domain.trim() === '') {
      domain = LocSettings.location.id
    }
    if (pathname.split('/')[2] == 'menu' || pathname.split('/')[3] == 'menu') {
      console.log('LocSettings.olo.allow_ordering', LocSettings.olo.allow_ordering)
      !IsOnlineOrder && navigateTo(`/${domain}/`)
    }

    findCategory()
  }, [])

  useEffect(() => {
    //This is to set the FOOTER Being USED in MENU PAGES FLOW
    dispatch(setCartButtonDisplay(true))
  }, [])
  return (
    <>
      {IsOnlineOrder && (
        <div
          style={{marginBottom: '30px', paddingBottom: '30px'}}
          className='container-fluid max-width-menu-page pt-lg-5 mx-auto'
        >
          <HeroImage />

          <nav className='triggers ps-6'>
            <ul>
              {menuCategoriesIsloading == false && categoryData && (
                <li>
                  <div className='headerRootLinkTitle mt-1 mb-3' data-test-breadcrumbs=''>
                    <span className='headerLinkTitle'>
                      <button
                        onClick={() => {
                          navigateTo(`${base_domain + StaticRoutePath.OLO_MENU}`)
                        }}
                        className={`active fs-3 fw-bold`}
                        type='button'
                      >
                        <FormattedMessage id='olo.all_categories' defaultMessage='All Categories' />
                      </button>
                    </span>
                    <span className='headerLinkTitle'>
                      <span className='headerLinkTitle fs-3 fw-bold'>
                        {categoryData && capitalizeWords(categoryData.name)} (
                        {categoryData?._embedded.items.length})
                      </span>
                    </span>
                  </div>
                </li>
              )}
            </ul>
          </nav>

          <div className='flex-lg-row-fluid justify-content-center align-items-center'>
            <div>
              {menuCategoriesIsloading == false && categoryData && (
                <div className='px-0 grid-container'>
                  {categoryData?._embedded &&
                    categoryData._embedded.items.map((innerKey: itemObject, innerIndex: number) => {
                      return (
                        <div key={innerIndex}>
                          <MenuItemCard
                            itemName={innerKey.name}
                            itemPrice={innerKey.price_per_unit}
                            itemImage={innerKey.image}
                            itemId={innerKey.id}
                            xtraClassName={''}
                            categoryName={categoryData.name}
                            categoryId={categoryData.id}
                            from='view-category' // view-category
                            categorySectionId={locationState.categorySectionId}
                          />
                          {categoryData._embedded.items.length % 2 != 0 &&
                            innerIndex + 1 === categoryData._embedded.items.length && (
                              <div className='col'></div>
                            )}
                        </div>
                      )
                    })}
                </div>
              )}
            </div>
          </div>
          {menuCategoriesIsloading == false && disclaimer != null && (
            <div
              className={`bg-transparent p-5 w-100 mt-5  ${
                LocSettings.appearance == 0 ? 'text-light' : 'text-dark'
              } `}
            >
              <div>
                <div>{renderDisclaimer(disclaimer, showAll)}</div>
                {30 < (disclaimer?.split(' ')?.length || 0) && (
                  <div className='cursor-pointer mw-100px' onClick={() => setShowAll(!showAll)}>
                    {showAll
                      ? intl.formatMessage({id: 'olo.show_less'})
                      : intl.formatMessage({id: 'olo.show_more'})}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  )
}
