/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useRef} from 'react'
import {Link,useNavigate} from 'react-router-dom'
import clsx from 'clsx'
import {useLayout} from '../../../core'
import {KTSVG, toAbsoluteUrl} from '../../../../helpers'
import { AsideMenu } from './AsideMenu'
import { useSelector,useDispatch } from 'react-redux'
import { RootState,AppDispatch } from '../../../../../app/store'
import {logOutGuest, setLogOut, verifyGuestSession} from "../../../../../app/services/store/guests";
import {setGuest, setGuestAuthState} from "../../../../../app/services/store/guests/guestSlice";
import { setFlow } from "../../../../../app/services/SourceSlice";
import { setUnableToUseBiometricsLogin } from '../../../../../app/services/PhoneAuthStepTracker';
import { setSidebarActiveMenu } from '../../../../../app/services/store/sidebar';
import { constants } from '../../../../../app/config/constants';
// @ts-ignore
import Cookies from 'js-cookie'
import { useLocationBasedBtnColor } from '../../../../../app/utilities/helpers'
import { FormattedMessage, useIntl } from 'react-intl'
// import { AsideMenu } from '../aside/AsideMenu'
// import {AsideMenu} from './AsideMenu'

const Aside: FC = () => {
  const intl = useIntl();
  const {config, classes} = useLayout()
  const asideRef = useRef<HTMLDivElement | null>(null)
  const dispatchApp = useDispatch<AppDispatch>();
  const {aside} = config
  const LocSettings = useSelector((state: RootState) => state.LocSettings.value);
  const guest = useSelector((state: any) => state.guest);
  const GLOBAL_ON_ID = process.env.REACT_APP_GLOBAL_ON;
  const { cookies_name, flow } = constants;
  let navigateTo = useNavigate();
  const dynamicBtnColor = useLocationBasedBtnColor()


  let LocationLogo = '';
  if (LocSettings?.location?.logo) {
    LocationLogo = `${process.env.REACT_APP_SP_API_URL}/download-file?path=${LocSettings.location.logo}&global_on_id=${GLOBAL_ON_ID}&user_id=${LocSettings.location.owner}`;
  }

let LocationIcon = '';
if (LocSettings?.icon) {
  LocationIcon = `${process.env.REACT_APP_SP_API_URL}/download-file?path=${LocSettings?.icon}&global_on_id=${GLOBAL_ON_ID}&user_id=${LocSettings.location.owner}`;
}

  const minimize = () => {
    asideRef.current?.classList.add('animating')
    setTimeout(() => {
      asideRef.current?.classList.remove('animating')
    }, 300)
  }
  const base_domain = LocSettings.base_domain;
  const redirectToPage=() =>{
    if(guest.auth.isAuthenticated==true){
      dispatchApp(setLogOut())
      navigateTo(base_domain + '/');
    }else{
      
      dispatchApp(setFlow(flow.profile));
      dispatchApp(setUnableToUseBiometricsLogin(false));
      dispatchApp(setSidebarActiveMenu(5));
      Cookies.set(cookies_name.my_account.my_account, 'yes')
      navigateTo(base_domain + '/my-account');
    }
  }
  let footerLogoPath =LocSettings.appearance === 0 ? '/media/logos/softpoint_logo_app_white.svg' : '/media/logos/lightModeFullSoftPointLogo.svg'
  
  return (
    <div
      id='kt_aside'
      className={clsx('aside theme-bg', classes.aside.join(' '))}
      data-kt-drawer='true'
      data-kt-drawer-name='aside'
      data-kt-drawer-activate='{default: true, lg: true}'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'200px', '300px': '250px'}"
      data-kt-drawer-direction='start'
      data-kt-drawer-toggle='#kt_aside_mobile_toggle'
      ref={asideRef}
    >
      {/* begin::Brand */}
      <div className='flex-column-auto' id='kt_aside_logo'>
       {/* begin::Logo */}
        <div className='mt-3 ms-10 h-40px'>
          {
            LocationIcon
              ?
              <img alt=''
                src={LocationIcon}
                className='h-40px symbol'
              />
              :
              LocationLogo
                ?
                <div className='d-flex'>
                  <img alt=''
                    src={LocationLogo}
                    className='h-40px symbol'
                  />
                  <h5 className='fw-bold dislpay-6 my-auto ms-2'> {LocSettings?.location?.location_name}</h5>
                </div>
                :
                <h5 className='fw-bold dislpay-6 my-auto'> {LocSettings?.location?.location_name}</h5>
          }

        </div>

        {/* end::Logo */}
      </div>
      {/* end::Brand */}

      {/* begin::Aside menu */}
      <div className='aside-menu flex-column-fluid'>
        <AsideMenu asideMenuCSSClasses={classes.asideMenu} />
      </div>
      {/* end::Aside menu */}

      {/* begin::Footer */}
      <div className='aside-footer flex-column-auto pt-5 pb-7 px-5' id='kt_aside_footer'>
        {
          guest.auth.isAuthenticated?
          <div className='text-center mt-5 '><h4 className='my-auto'><FormattedMessage id='sidebar.welcome_back' defaultMessage='Welcome back'/>, {guest.data.first_name}!</h4></div>
          :''
        }        
        <div className='text-center mt-5 '>
            
            <button
            onClick={() => redirectToPage()}
            type='button'
            id='kt_sign_in_submit'
            className={`btn btn-lg btn-primary w-100 mb-5 ${dynamicBtnColor}`}
            >
                <span className='indicator-label'>{guest.auth.isAuthenticated==true?intl.formatMessage({id:"sidebar.log_out"}):intl.formatMessage({id:"common.login"})}</span>
            </button>
        </div>        
        <div className={` d-flex flex-column align-items-center justify-content-end  my-auto mx-auto`}>
          {/* flex-md-row */}
          {/* Nav */}
          <ul className='menu menu-gray-600 menu-hover-primary fw-bold order-1'>
            <li className=''>
              <h4 className='my-auto' style={{fontWeight: 'normal'}}>
                <a id='footerLink' className='' href="https://www.softpointcloud.com/" rel="noopener noreferrer" target="_blank" style={{fontSize:"0.8em"}}>
                <FormattedMessage id='sidebar.powered_by' defaultMessage='Powered By'/> <img style={{ imageRendering: 'auto'}} src={footerLogoPath} className="w-100px h-30px" alt="" />
                </a>
              </h4>
            </li>
          </ul>
        </div>
      </div>
      {/* end::Footer */}
    </div>
  )
}

export {Aside}
