import {constants} from "../../config/constants";
import {GlobalSettingsState} from "../../services/store/globalSettings";

interface ILocationIntegration{
    global_integration_code:string
    global_integration_id:number
    global_integration_name:string|null
    global_integration_url:string|null
    global_integration_user:string|null
}

interface ILocationProduct{
    code:string
    description:string|null
    global_integrations_types_id:number
    global_product_id:number
    global_product_status:number
    id:number
    name:string|null
    status:number
}

export const LocationHasOLOOn = (locationSettings:any) => {
    const idGlobalOloInt = constants.integrations.olo.global_integration_id;
    const OloIntegrations = (locationSettings.olo_integrations as []).filter(localIntegration => {
        const integration = localIntegration as ILocationIntegration
        return integration.global_integration_id == idGlobalOloInt
    })
    if(OloIntegrations.length < 1) return false
    return OloIntegrations !== undefined
}

export const locationHasIdMeOn = (locationSettings:any) => {
    const idGlobalProdID = constants.products.id.global_product_id
    const idGlobalIntegrationID = constants.integrations.id_me.global_integration_id
    const IDME_CODE = constants.integrations.id_me.code

    let locationProducts = locationSettings?.location?.location_products
    if(locationProducts === undefined) return false

    const locationIntegrations = locationSettings.integrations
    if(locationIntegrations === undefined) return false


    const idMeIntegrations = (locationIntegrations as []).filter(localIntegration => {
        const integration = localIntegration as ILocationIntegration
        return integration.global_integration_id == idGlobalIntegrationID && integration.global_integration_code == IDME_CODE
    })
    if(idMeIntegrations.length < 1) return false
    const idMeIntegration = idMeIntegrations[0]


    const idProducts = locationProducts.filter((locp:any)=>locp.global_product_id == idGlobalProdID)
    if(idProducts.length < 1) return false
    const idMeProduct = idProducts[0]


    return locationSettings.id_integration == 1 && idMeProduct.status == 1 && idMeIntegration !== undefined
}

export const locationHasBiometricsOn = (locationSettings:any) => {
    const bioGlobalProdID = constants.products.biometrics.global_product_id
    const bioGlobalIntegrationID = constants.integrations.facial_recognition.true_face.global_integration_id
    const TF_CODE = constants.integrations.facial_recognition.true_face.code

    const locationProducts = locationSettings?.location?.location_products
    if(locationProducts === undefined) return false

    const locationIntegrations = locationSettings.integrations
    if(locationIntegrations === undefined) return false


    const biometricIntegrations = (locationIntegrations as []).filter(localIntegration => {
        const integration = localIntegration as ILocationIntegration
        return integration.global_integration_id == bioGlobalIntegrationID && integration.global_integration_code == TF_CODE
    })
    if(biometricIntegrations.length < 0) return false
    const biometricIntegration = biometricIntegrations[0]


    const biometricProducts = locationProducts.filter((locp:any)=>locp.global_product_id == bioGlobalProdID)
    if(biometricProducts.length < 1) return false
    const biometricProduct = biometricProducts[0]


    return biometricProduct.status == 1 && locationSettings.biometrics == 1 && biometricIntegration !== undefined
}

export const locationHasCOFOn = (locationSettings:any, preCheckinType:undefined|'room'|'table' = undefined) => {
    if(locationSettings.store_card != 1) return false;

    if(preCheckinType == undefined) {
        if(locationSettings.store_card_for_registration === undefined) return false
        return locationSettings.store_card_for_registration == 1
    } else if(preCheckinType == 'room') {
        return locationSettings?.store_card_for_room == 1
    } else if(preCheckinType == 'table') {
        if(locationSettings.store_card_for_registration === undefined) return false
        return locationSettings?.store_card_for_table == 1
    }
}

export const globalConfigHasOCREnabled = (globalSettings:GlobalSettingsState) => {
    if(globalSettings.global_default_integrations == null) {
        return false;
    }

    const ocr_global_integration_id = constants.integrations.ocr.global_integration_id
    let global_integration_found = globalSettings.global_default_integrations.find((integration:any)=>integration.id==ocr_global_integration_id);
    if(global_integration_found == undefined) {
        return false
    }

    return global_integration_found.status == 1;
}

export const globalConfigHasPDF417Enabled = (globalSettings:GlobalSettingsState) => {
    if(globalSettings.global_default_integrations == null) {
        return false;
    }

    const ocr_global_integration_id = constants.integrations.pdf417.global_integration_id
    let global_integration_found = globalSettings.global_default_integrations.find((integration:any)=>integration.id==ocr_global_integration_id);
    if(global_integration_found == undefined) {
        return false
    }

    return global_integration_found.status == 1;
}
