import {createSlice, PayloadAction} from '@reduxjs/toolkit'

export interface IlocationInfo {
    name:string
    address:string
    address2:string
    state_name:string
    phone:string
    city:string
    zip:string
    footer?:string|null,

}

export interface iPaymentData {
    id: number|null,
    payment_id: number,
    tip: number,
    amount: number,
    location_id: number,
    status: number,
    transaction_no: string,
    ticket_number: null|string,
    card_holder: null|string,
    last4: null|string,
    reference_id: null|string,
    auth_code: null|string,
    card_type: null|string,
    emv_tags: null|string,
    serial_number: null|string,
    payment_status: string,
    result_message: null|string,
    base_amount: number,
    local_created_datetime: string,
    transaction_type_name?:string|null
    _embedded: {
        location_info:IlocationInfo
    }
}

export interface iFrame {
    isLoading: boolean,
    processor_config_error: boolean
}

export interface TicketState {
    isLoading: boolean,
    clickPayButton: boolean,
    selectedCardToPay: object,
    newCardSelected:boolean
    response: object,
    lastPayment: object,
    SplitData: object,
    fromUrl: null | {
        id: number,
        location_id: number,
        url_type: string,
        ticket_id: null|number,
        payment_id: number,
        short_code: string,
        url: null|string,
    },
    PaymentData:iPaymentData|null,
    error: null | {
        code:string
        details:string,
        message:string,
    },
    iframe: iFrame|null
}


const initialState: TicketState = {
   isLoading: false,
   clickPayButton: false,
   iframe: null,
   selectedCardToPay: {},
   newCardSelected: false,
   response: {},
   fromUrl: null,
   lastPayment: {},
   SplitData: {},
   PaymentData: null,
   error: null
}

export const paymentSlice = createSlice({
    name: 'payment',
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setClickPayButton: (state, action) => {
            state.clickPayButton = action.payload
        },
        setSelectedCardToPay: (state, action) => {
            const oldCardIsEmpty = Object.keys(state.selectedCardToPay).length == 0
            const newCardIsEmpty = Object.keys(action.payload).length == 0

            if(!oldCardIsEmpty && !newCardIsEmpty){
                state.newCardSelected = true
            }

            state.selectedCardToPay = action.payload
        },
        setNewCardToggle: (state, action:PayloadAction<boolean>) => {
            state.newCardSelected = action.payload
        },
        setLastPayment: (state, action) => {
            state.lastPayment = action.payload
        },
        setSplitData: (state, action) => {
            state.SplitData = action.payload
        },
        setPaymentData: (state, action) => {
            state.PaymentData = action.payload
            state.isLoading = false
        },
        setError: (state, action) => {
            state.error = action.payload
            state.isLoading = false
        },
        setUrlData: (state, action) => {
            state.fromUrl = action.payload
            state.isLoading = false
        },
        setIframe: (state, action) => {
            state.iframe = action.payload
        },
      setPayInitialState: (state) => {
         state.isLoading = false
         state.clickPayButton = false
         state.selectedCardToPay = {}
         state.response = {}
         state.lastPayment = {}
         state.SplitData = {}
         state.PaymentData = null
         state.error = null
         state.fromUrl = null
         state.iframe =null
        }
    },
})

// Action creators are generated for each case reducer function
export const {
   setLoading,
   setClickPayButton,
   setSelectedCardToPay,
   setPayInitialState,
   setLastPayment,
   setSplitData,
   setNewCardToggle,
   setPaymentData,
   setError,
   setUrlData,
   setIframe
} = paymentSlice.actions
