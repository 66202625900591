import React, { ReactElement, useContext, useEffect, useRef, useState } from 'react'
import { Step4 } from "../../../../modules/wizards/components/steps/Step4";
import { Formik, Field, Form} from "formik";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { useLocationBasedBtnColor,useLocationBasedNoBgBtnColor } from "../../../../utilities/helpers";
import Swal, { SweetAlertResult } from "sweetalert2";
import { baseCountries, countries } from "../../../../common/country_state/Countries";
import Inputmask from "inputmask";
import * as ApiCallService from "../../../../services/ApiCallService";
import { Button, Dropdown,Modal,Form as bsForm } from 'react-bootstrap';
import {
    createAccountSchemas,
    ICreateAccount,
    inits
} from "../../../../modules/wizards/components/CreateAccountWizardHelper";
import { constants, GUEST_TYPE } from "../../../../config/constants";
import { HeaderTitleContext } from "../../../../../_spc/layout/components/HeaderTitleContext/HeaderTitleContextStore";
import { useNavigate } from "react-router-dom";
import { locationHasCOFOn } from "../../../../common/core/BusinessLogic";
import moment from "moment";
import AddNewGuestForm, { GuestDetails, IGuestInterface } from './AddNewGuestForm';
import NewGuestDropDown from './NewGuestDropDown';
import CustomButton from '../../../../common/button/CustomButton'
import { KTSVG } from '../../../../../_spc/helpers';
import { FormattedMessage, useIntl } from 'react-intl';



export const AddGuests = () => {
    const intl = useIntl();
    const navigateTo = useNavigate()
    const LocSettings = useSelector((state: RootState) => state.LocSettings.value);
    const reservation = useSelector((state: any) => state.reservation);
    const SourceFlow = useSelector((state: RootState) => state.Source.flow);
    const [currentSchema] = useState(createAccountSchemas[0])
    const dynamicBtnColor = useLocationBasedBtnColor()
    const { setPageTitle } = useContext(HeaderTitleContext);
    const [initValues] = useState<ICreateAccount>(inits);
    const reservation_data = reservation.data;
    const reservation_embedded = reservation_data._embedded;
    const guestArray:GuestDetails[] = [];
    const base_domain = LocSettings.base_domain;
    const softPointCheckinIntegration = constants.integrations.checkin.providers.softpoint
    let isSoftpointIntegration = LocSettings.integrations?.find((integration: { global_integration_id: number; }) => (integration.global_integration_id === softPointCheckinIntegration.global_integration_id)) !== undefined
    const [showAddGuest, setShowAddGuest] = useState(false);
    const [showNewGuestMenu,displayNewGuestMenu] = useState(false);
    const [addGuestType,setAddGuestType] = useState<GUEST_TYPE>();
    const guestStore = useSelector((state: RootState) => state.guest)

    let display_wallet: any = null;
    if (reservation.data.rooms_type_id) {
        display_wallet = locationHasCOFOn(LocSettings, 'room')
    } else {
        display_wallet = locationHasCOFOn(LocSettings, 'table')
    }

    setPageTitle(intl.formatMessage({id:constants.staticPageTitle.add_guests}));
    for (var i = 0; i < reservation_embedded.guests.guests_count; i++) {
        let guestData = reservation_embedded.guests[i];
        let guest = {
            first_name: guestData.guest.split(" ")[0],
            last_name: typeof guestData.guest.split(" ")[2] != 'undefined' ? guestData.guest.split(" ")[1] + " " + guestData.guest.split(" ")[2] : guestData.guest.split(" ")[1],
            guest_id: guestData.guest_id,
            phone_number: guestData.phone,
            primary: guestData.primary,
            global_country_id: guestData.global_country_id,
            global_association_id:guestData.global_association_id,
            gender:guestData.gender,
            email:guestData.email,
            date_of_birth:guestData.date_of_birth,
            status_checkin:guestData.status_checkin,
            status_pre_checkin:guestData.status_pre_checkin,
            is_dependant:guestData.is_dependant
        };
        if (guestData.primary == 1)
            guestArray.unshift(guest);
        else
            guestArray.push(guest);
    }
    const [guestList, setGuestList] = useState(guestArray);
    let verifyMessage1 = (
        <p>
            <FormattedMessage id='checkin.guest_will_receive_text' defaultMessage='All guest added will receive text notifications of reminders.' /> <br />
            <br />
            {typeof reservation_data._embedded.room != "undefined" ? intl.formatMessage({id:'checkin.guest_able_to_generate_digital_keys'}) : ""}
        </p>
    )

    let verifyMessage2 = <></>;
    let room_table_name = '';
    let allowedGuest = 0;
    if (reservation_data.rooms_type_id !== null) {
        verifyMessage1 = (
            <>
                <FormattedMessage id='checkin.guest_will_receive_text' defaultMessage='All guest added will receive text notifications of reminders.' /> <br />
                <FormattedMessage id='checkin.guest_able_to_generate_digital_keys' defaultMessage='All additional guests will be able to generate digital keys with SoftPoint mobile app. Adding guests here will not grant them charging privileges.' />
            </>
        );

        let guest_available = reservation_data?._embedded?.room_type?.guest;
        let guests_already_added = reservation_data?._embedded?.guests?.guests_count !== undefined ? reservation_data._embedded.guests.guests_count : 0;

        let remain_guests = guest_available - guests_already_added;
        remain_guests = remain_guests < 0 ? 0 : remain_guests;
        allowedGuest = remain_guests;

        verifyMessage2 = <><FormattedMessage id='checkin.room_can_accomodate' defaultMessage='This room can accommodate' /> {remain_guests} <FormattedMessage id='checkin.additional_guests' defaultMessage='additional guests' /></>
        room_table_name = reservation_data?._embedded?.room_type?.name;
    } else {
        let guest_available = reservation_data?._embedded?.revenue_center?.seats !== undefined ? reservation_data._embedded.revenue_center.seats : 1;
        let seats_already_added = reservation_data?._embedded?.guests?.guests_count !== undefined ? reservation_data._embedded.guests.guests_count : 0;
        let remain_seats = guest_available - seats_already_added;
        remain_seats = remain_seats < 0 ? 0 : remain_seats;
        allowedGuest = remain_seats;
        verifyMessage2 = <p> <FormattedMessage id='checkin.table_can_accomodate' defaultMessage='This table can accommodate' /> {remain_seats} <FormattedMessage id='checkin.additional_guests' defaultMessage='additional guests' /></p>
        room_table_name = reservation_data?._embedded?.revenue_center?.name;
    }

    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-danger btn-sm fs-5',
        },
        buttonsStyling: false,
        icon: 'error',
        background: LocSettings.appearance === 1 //if 1 or light mode set white background
            ? '#fffff'
            : '#151521',
        confirmButtonText: intl.formatMessage({id:'alert.ok_got_it'}),
        width: '29rem',
        heightAuto: false,
    })
    //handles the error messages
    const fireErrorMessage = async (_errorMessage: string): Promise<SweetAlertResult<ReactElement<any, any>>> => swalWithBootstrapButtons.fire({
        html: `<h6 class="swal2-html-container fs-5 mx-0 gray-800-dark">${_errorMessage}</h6>`
    });

    let guestCount = Number(allowedGuest);
    const renderNewGuestForm = (type:GUEST_TYPE) => {
        displayNewGuestMenu(false);
        if (guestCount != 0) {
            if (guestList.length <= guestCount) {
                setAddGuestType(type);
                setShowAddGuest(true);
            } else {
                fireErrorMessage(intl.formatMessage({id:'checkin.alert.maximum_guest_limit_reached'}))
                return;
            }
        } else {
            fireErrorMessage(intl.formatMessage({id:'checkin.alert.maximum_guest_limit_reached'}))
            return;
        }
    }
    const FinalSubmit = () => {
        let arrGuestList = [...guestList]

        let newguests: any = [];
        arrGuestList.map(function (guest, i) {
            if (!guest?.guest_id) {
                newguests.push(guest);
            }
        })
        console.log(newguests);
        if (newguests.length > 0) {
            let data = {
                parent_id: guestStore.data.id,
                guests: newguests,
                flow: SourceFlow
            }
            
            ApiCallService.ApiCall(data, `reservations/${reservation_data.id}/guests`, 'POST', LocSettings)
                .then((res: any) => {
                    if (res.error !== undefined) {
                        fireErrorMessage(res.error.message);
                    } else if (res.exception !== undefined) {
                        fireErrorMessage(intl.formatMessage({id:"common.alert.error_sending_data"}))
                    } else{
                        return redirectToNextStep()
                    }
                }).catch((err) => {
                    fireErrorMessage(intl.formatMessage({id:"common.alert.error_sending_data"}))
                })
        } else {
            return redirectToNextStep()
        }
    } 

    const redirectToNextStep = () => {
        if (display_wallet) {
            navigateTo(`${base_domain}/${constants.routes.private.pre_checkin_select_payment_method}`);
        } else {
            navigateTo(`${base_domain}/${constants.routes.private.pre_checkin_confirmation_done}`);
        }
    }

    const getFormatterPhoneNumber = (code:number,phone?:string) => {
        if (!phone || phone.length == 0) return "";
        let varcontcode = "";
        if(code>0){            
            if(baseCountries[code].id){
                varcontcode = countries[baseCountries[code].id].secondary;
            }
        }        
        phone = phone.replace(".","");
        var match = phone.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
        if (match) {
            var intlCode = (match[1] ? '+1 ' : ''),
                number = [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    
            return varcontcode+" "+number;
        }
        return varcontcode+" "+phone;
    }

    const addNewGuestHandle = () => {
        if (LocSettings.allow_connected_accounts){
            displayNewGuestMenu(true);
        }else{
            renderNewGuestForm(GUEST_TYPE.OTHER);
        }
    }

    return (
        <div className='d-flex justify-content-center h-100 pt-5 px-5 p-lg-20'>
            <div style={{ maxWidth: '500px' }}>
                <div data-kt-stepper-element='content'>
                    <Formik
                        validationSchema={currentSchema}
                        initialValues={initValues}
                        onSubmit={() => { }}
                    >
                        <Form className='d-flex card-rounded flex-stack justify-content-center flex-column card p-5' noValidate id='kt_create_account_form'>
                            <div className='w-100 h-xl-100'>
                                <div className=''>
                                    <div className='pb-2'>
                                        {/* <h1 className='fw-bold dislpay-6'>Add Guests</h1> */}
                                        <div className='fs-6 form-label'>{verifyMessage1}</div>
                                    </div>
                                    <div className='pb-2'>

                                        <h1 className='fw-bold dislpay-6'>{room_table_name}</h1>

                                        <div className='text-gray-700 fs-6 pt-4 form-label'>{verifyMessage2}</div>
                                    </div>
                                    {/* removed error messages from mapping over with
                                        since the embedded guests will already be checked for blank fields in the dashboard
                                        kept the error messages on the fields for adding a new guest
                                        and that error message is controlled in the CreateAccountWizardHelper component per Formik
                                    */}
                                    <div className="card" style={{padding:"0px !important"}}>
                                        <div className="card-body pt-0 px-2">
                                            <div id="kt_table_guest_detail_history_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                                <div className="table-responsive">
                                                    <table className="table align-middle table-row-dashed gy-5 dataTable no-footer" id="kt_table_guest_detail_history">
                                                        <thead className="border-bottom border-gray-200 fs-7 fw-bolder">
                                                            <tr className="text-muted text-uppercase gs-0">
                                                                <th className="px-6"><FormattedMessage id='common.guest' defaultMessage='Guest'/></th>
                                                                <th className="text-center"><FormattedMessage id='common.confirmed' defaultMessage='Confirmed'/></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="fs-6 fw-bold text-gray-600">


                                                            {guestList.map((ele, i) => {
                                                                return (
                                                                    <tr>
                                                                        <td>
                                                                            <div className="mx-6">
                                                                                <div>                                  
                                                                                    <h4 className='text-left'> {ele.first_name} {ele.last_name}                   {ele.primary == 1 &&
                                                                                    <KTSVG
                                                                                        path='/media/svg/general/heart.svg'
                                                                                        className='svg-icon-2x svg-icon-primary mx-2 mt-1'
                                                                                        svgClassName='mh-15px' />}</h4>

                                                                                    <p>      {getFormatterPhoneNumber(ele.global_country_id!, ele.phone_number)}   </p>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td className='text-center'>
                                                                            {(!ele.status_pre_checkin  || ele.status_pre_checkin == 0) &&
                                                                                <div className="badge bg-light-danger text-danger"><FormattedMessage id='checkin.in_complete' defaultMessage='Incomplete'/></div>
                                                                            }
                                                                            {ele.status_pre_checkin != null && ele.status_pre_checkin != 0 &&
                                                                                <div className="badge bg-light-success text-success"><FormattedMessage id='checkin.complete' defaultMessage='Complete'/></div>
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>                                    
                                </div>
                            </div>

                            <div className='mt-10 w-100 d-flex'>
                                {
                                    isSoftpointIntegration ?
                                        <>
                                        <div className="w-50 pe-2">
                                            <CustomButton title={intl.formatMessage({id:"myaccount.addguest.add_new_guest"})} onClick={addNewGuestHandle} extraClass='w-100 customBtnNoBg'  />

                                            {/* <NewGuestDropDown addChild={() => {
                                                addNewGuestNew();
                                            }} /> */}

                                            {/* <button type="button"
                                                onClick={() => {
                                                    addNewGuestNew()
                                                    if (firstName === '' && lastName === '' && phoneNumber === '') {
                                                        setUserAddNewGuest(false) //this value resets to false when the input fields are filled in the addNewGuest function
                                                    }
                                                }} className='btn btn-primary customBtnNoBg w-100'>
                                                <span className={`indicator-label ${dynamicBtnColorNoBg}`}> Add New Guest </span>
                                            </button> */}
                                        </div>
                                        <div className="w-50 ps-2">
                                            <button onClick={FinalSubmit} type='button' className='btn btn-lg btn-primary w-100'>
                                                <span className={`indicator-label ${dynamicBtnColor}`}><FormattedMessage id='common.continue' defaultMessage='Continue' /></span>
                                            </button>
                                        </div>
                                        </>
                                        :
                                        <div className='mt-5 w-100'>
                                            <button onClick={FinalSubmit} type='button' className='btn btn-lg btn-primary w-100'>
                                                <span className={`indicator-label ${dynamicBtnColor}`}><FormattedMessage id='common.continue' defaultMessage='Continue' /></span>
                                            </button>
                                        </div>
                                    
                                } 
                                
                            </div>
                        </Form>
                    </Formik>
                </div>
            </div>
            
            <AddNewGuestForm show={showAddGuest} type={addGuestType!} onSuccess={(member: any) => {   
                let curr = [...guestList]
                curr.push(member)
                setGuestList(curr);       
                setShowAddGuest(false);                                      
            }} onHide={() => {
                setShowAddGuest(false);
            }} />
            <NewGuestDropDown show={showNewGuestMenu} onClick={renderNewGuestForm} onHide={() => {displayNewGuestMenu(false)}} />
        </div>
    )
}

