import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { CustomDropDownMenu } from '../../pages/profile/components/CustomDropDownMenu';
import { CustomDropDownToggle } from '../../pages/profile/components/CustomDropDownToggle';
import { countries } from './Countries';

interface Country {
  countryCode: string;
  id: string;
  primary: string;
  secondary: string;
  pn_length: string;
  flag: string;
}

interface CustomDropdownProps {
  selected: string
  disabled?:boolean
  mode:"country" | "phone"
  setSelected: Dispatch<SetStateAction<string>>;
}

const CountrySelector: React.FC<CustomDropdownProps> = ({ selected, disabled, mode, setSelected }) => {
const [countryArr, setCountries] = useState<Country[]>([]);
let selectedCountry:Country|undefined = undefined;

useEffect(() => {
  const fetchData = async () => {
    const result: Country[] = await getCountryCodes();
    const selcon = result.find(country => country.countryCode == selected)
    setCountries(result);
  };

  fetchData();
}, []);
 
const getCountryCodes = async (): Promise<Country[]> => {
  const putOnTop = ["United States","Canada","Mexico"];
    const countriesArray = Object.entries(countries)
    .map(([key, value]) => ({ countryCode: key, ...value }));
    countriesArray.sort((a, b) => {
      if(mode == 'phone'){
        if (putOnTop.includes(a.primary)) return -1;
        if (putOnTop.includes(b.primary)) return 1;
      }
      return a.primary.localeCompare(b.primary);
    }) 
    return countriesArray; 
  }
  const getSelectedCountry = (): Country => {
    const result = countryArr.find(country => country.countryCode == selected)!
    selectedCountry = result
    return result
  }
  return (
       <Dropdown
        onSelect={(key, event) => {
          if(key){
            setSelected(key)
            selectedCountry = countryArr.find(country => country.countryCode == key)!
          }
        }}
      >
        <Dropdown.Toggle
          as={CustomDropDownToggle}
          disabled={disabled}
          id='dropdown-custom-components-country'
        >
          <div
              style={{}}
              className={`form-select d-flex fs-5 h-100 align-items-center ${!disabled ? 'form-select-solid' : 'form-select-disable'} ${mode == 'phone' ? 'justify-content-center':'w-100'}`}
          >          
          <div className={mode == 'country' ? 'me-3' : 'm-1'}>
            {getSelectedCountry() && selectedCountry!.flag}
          </div> 
          {mode == 'phone' && getSelectedCountry() && `${selectedCountry!.secondary}`}
            {mode == 'country' && getSelectedCountry() && `${selectedCountry!.primary}`}
            </div>
        </Dropdown.Toggle>
        <Dropdown.Menu
          className={`fs-6 ${mode == 'country' && 'w-100'}`}
          style={{overflowY: 'scroll', height: '200px', overflowX:'hidden'}}
          as={CustomDropDownMenu}
        >
          {Object.entries(countryArr).map(([index,country]) => {
              return (
                <Dropdown.Item
                    key={country.countryCode}
                    className={
                      country.countryCode == selected
                          ? 'activeCallCodeID genderMenuOptions'
                          : 'genderMenuOptions' 
                    }
                    eventKey={country.countryCode}
                >
                    {`${country.flag}  ${country.primary}`}
                    {mode == 'phone' && ` (${country.secondary})`}
                </Dropdown.Item>
              )
          })}
        </Dropdown.Menu>
    </Dropdown>
   );
};


export default CountrySelector;