import { Dispatch, SetStateAction, useState, useEffect, FC } from "react";
import { Modal } from "react-bootstrap";
import { ReactQueryDevtools } from "react-query/types/devtools";
import { KTSVG, toAbsoluteUrl } from '../../../_spc/helpers'
import CustomButton from '../../common/button/CustomButton';
import { ReceiptTicketDetails } from "./components/ReceiptTicketDetails";
import {TextBorderColorBasedBgPCLoction,useLocationBasedBtnColor, useLocationBasedTextBorderColor} from "../../utilities/helpers";
import { useDispatch, useSelector } from 'react-redux';
import {setModTicket} from "../../services/store/mod_ticket";
import {AppDispatch, RootState} from '../../store';
import Swal from 'sweetalert2';
import { FormattedMessage, useIntl } from "react-intl";

export interface ITicketDetails {
    emp_name: string | null,
    opened_date: string,
    ticket_number: string,
    location_name: string | null,
    opened_time: string,
    voided: number,
}
export interface ITicketItem{
    modifiers: any;
    id:number,
    quantity:number|null,
    price:number|null,
    tax:number|null,
    name:string|null
}

interface SplitPaymentModalProps {
    toggleModal: () => void,
    SetSplitItemModal: () => void,
    handleGoToTicketDetails: () => void,
    ticketDetails: any,
    HeaderData: ITicketDetails,
    items:[ITicketItem],
    IsNonSeatPayment:boolean,
}

//setRefreshTicket: Dispatch<SetStateAction<boolean>>;
//setRefreshTicket
export const SplitPaymentModal: FC<SplitPaymentModalProps> = ({ toggleModal, ticketDetails, HeaderData,items,SetSplitItemModal,handleGoToTicketDetails,IsNonSeatPayment }) => {  
    const intl = useIntl();  
    const [SplitAmt, setSplitAmt] = useState(0);
    const [SplitTaxAmt, setSplitTaxAmt] = useState(0);
    const [SplitOpt, setSplitOpt] = useState(1);
    const [SplitByGuest, setSplitByGuest] = useState(1);
    const [TotalGuest, setTotalGuest] = useState(ticketDetails.guest_count ? ticketDetails.guest_count : 1);
    const [payforamt, setpayforamt] = useState(0);
    const [GuestName, setGuestName] = useState("");  
    const dispatchDirect = useDispatch();  
    const LocSettings = useSelector((state: RootState) => state.LocSettings.value);
    const [IsSbmtDisabled,setIsSbmtDisabled] = useState(false);
    

    const handleChange = (e: any) => {
        let { value, max, name } = e.target;
        if (name == "people_for") {
            if (Number(value) <= Number(max)) {
                setSplitByGuest(Number(value));                
                let totalByGuest = (Number(ticketDetails.totals.grand_due) / Number(TotalGuest)) / 100;
                let SplitAmt = Number(value) * totalByGuest;
                setSplitAmt(SplitAmt);

                let totalTaxByGuest = (Number(ticketDetails.totals.tax) / Number(TotalGuest)) / 100;
                let SplitTaxAmt =  Number(value) * totalTaxByGuest;
                setSplitTaxAmt(SplitTaxAmt);
            }
        } else if (name == "people_from") {
            if(LocSettings.location.split_max>0){
                if(value>LocSettings.location.split_max){
                    return false;
                }
            }
            setTotalGuest(Number(value));
            let totalByGuest = (Number(ticketDetails.totals.grand_due) / Number(value)) / 100;
            let SplitAmt = Number(SplitByGuest) * totalByGuest;
            setSplitAmt(SplitAmt);

            let totalTaxByGuest = (Number(ticketDetails.totals.tax) / Number(value)) / 100;
            let SplitTaxAmt =  Number(SplitByGuest) * totalTaxByGuest;
            setSplitTaxAmt(SplitTaxAmt);
        } else if (name == "payforamt") {
            if (Number(value) <= Number(max)) {
                value = value != "" ? Number(value) : value;
                setpayforamt(value);
                setSplitAmt(value);
            }
        } else if (name == "guest_name") {
            setGuestName(value);
        }
    }    
    useEffect(() => {
        setSplitAmt(0);
        if (SplitOpt == 1) {
            let totalByGuest = (Number(ticketDetails.totals.grand_due) / Number(TotalGuest)) / 100;
            let SplitAmt = Number(SplitByGuest) * totalByGuest;
            setSplitAmt(SplitAmt); 
            let totalTaxByGuest = (Number(ticketDetails.totals.tax) / Number(TotalGuest)) / 100;
            let SplitTaxAmt =  Number(SplitByGuest) * totalTaxByGuest;
            setSplitTaxAmt(SplitTaxAmt);
        } else if (SplitOpt == 2) {
            setSplitAmt(payforamt)
        }
    }, [SplitOpt]);
    useEffect(() => {
        if (SplitOpt == 1) {
            if(SplitByGuest<=0 || TotalGuest<=0){
                setIsSbmtDisabled(true);
            }else{
                setIsSbmtDisabled(false);
            }
        }else if(SplitOpt == 2){
            if(payforamt<=0){
                setIsSbmtDisabled(true);
            }else{
                setIsSbmtDisabled(false);
            }
        }else{
            if(GuestName==""){
                setIsSbmtDisabled(true);
            }else{
                setIsSbmtDisabled(false);
            }
        }
    }, [SplitOpt,SplitByGuest,TotalGuest,payforamt,GuestName]);

    

    const HandleSubmit = () => {
        if(IsSbmtDisabled){
            Swal.fire({
                customClass: {
                    confirmButton: 'btn btn-danger btn-sm fs-5',
                },
                icon: 'error',
                background: LocSettings.appearance === 1 ? '#fffff' : '#151521',
                text: SplitOpt==1? intl.formatMessage({id:"receipt.split_payment.alert.number_of_people_split_check"}):SplitOpt==2?intl.formatMessage({id:"receipt.split_payment.alert.select_amount_to_pay"}):intl.formatMessage({id:"receipt.split_payment.alert.enter_guest_name"}),
                confirmButtonText: intl.formatMessage({id:'alert.ok_got_it'}),
                width: '29rem',
                heightAuto: false,
            })
            return false;
        }
        if (SplitOpt == 3) {            
            SetSplitItemModal();
            toggleModal();
        } else {            
            dispatchDirect(setModTicket({
                SplitOpt:SplitOpt,
                CheckedItems:[],
                CheckedItemsTotal:Math.round((SplitAmt-SplitTaxAmt)*100),
                CheckedItemsTax:Math.round(SplitTaxAmt*100),
                CheckedItemsDiscount:0,
                CheckedItemsRemain:0,
                ToPayAmt:Math.round(SplitAmt*100),
                GuestName:GuestName,
                SplitByGuest:SplitByGuest,
            }))
            handleGoToTicketDetails()
            toggleModal();
        }
    }
    
    return (
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog-centered w-100 set-manager-login-modal h-auto px-5'
            show={true}
            onHide={toggleModal}
        >       
            <div className='modal-content shadow-none'>
                <div className="modal-header">
                    <h2 className="modal-title text-center"><FormattedMessage id='receipt.split_payment.how_you_like_to_split' defaultMessage='How would you like to split?' /></h2>
                    <div className="btn btn-icon btn-sm btn-light-primary ms-2" onClick={toggleModal} aria-label="Close" >
                        <KTSVG path="/media/icons/duotune/arrows/arr061.svg" className="svg-icon svg-icon-2x" />
                    </div>
                </div>
                <div className='container-xxl w-lg-100'>
                    <div className='modal-body pt-0'>
                        <div className="d-flex flex-row flex-column-fluid h-xl-100 pb-10 pt-5">
                            <div className="row theme-background py-3">
                                <div className='card form-check p-0 form-check-solid' data-kt-stepper-element='content'>
                                    <div className="mb-10">
                                        <div className="form-check form-check-custom form-check-solid">
                                            <input onClick={() => setSplitOpt(1)} name="radioBtn" className="form-check-input" type="radio" defaultChecked value="" id="flexRadioDefault" />
                                            <label className="form-check-label" htmlFor="flexRadioDefault">
                                                <span className="d-flex">
                                                    <span className="ms-4">
                                                        <span className="fs-4 form-label "><FormattedMessage id='receipt.split_payment.pay_for' defaultMessage='Pay for' /></span>
                                                        <span className="fs-4 form-label m-2">
                                                            <input readOnly={SplitOpt == 1 ? false : true} className="form-control form-control-solid d-inline-block mw-40px" max={TotalGuest > 0 ? TotalGuest : 1} type="number" name="people_for" onChange={handleChange} value={SplitByGuest > 0 ? SplitByGuest : ""} />
                                                        </span>
                                                        <span className="fs-4 form-label" > <FormattedMessage id='receipt.split_payment.of' defaultMessage='of' /> </span>
                                                        <span className="fs-4 form-label m-2">
                                                            <input readOnly={SplitOpt == 1 ? false : true} className="form-control form-control-solid d-inline-block mw-40px" onChange={handleChange} type="number" name="people_from" value={TotalGuest > 0 ? TotalGuest : ''} />
                                                        </span>
                                                        <span className="fs-4 form-label" > <FormattedMessage id='receipt.split_payment.people' defaultMessage='people' /> </span>
                                                    </span>
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="mb-10">
                                        <div className="form-check form-check-custom form-check-solid">
                                            <input onClick={() => setSplitOpt(2)} name="radioBtn" className="form-check-input" type="radio" value="" id="flexRadioDefault1" />
                                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                <span className="d-flex">
                                                    <span className="ms-4">
                                                        <span className="fs-4 form-label"><FormattedMessage id='common.pay' defaultMessage='Pay' /> $</span>
                                                        <span className="fs-4 form-label m-2">
                                                            <input max={(ticketDetails.totals?.grand_due / 100.00)} readOnly={SplitOpt == 2 ? false : true} onChange={handleChange} className="form-control form-control-solid d-inline-block mw-50px" type="number" name="payforamt" value={payforamt} /></span>
                                                        <span className="fs-4 form-label m-2" ><FormattedMessage id='receipt.split_payment.Of' defaultMessage='Of' /> ${(ticketDetails.totals?.grand_due / 100.00).toFixed(2)}</span>
                                                    </span>
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                    {
                                        !IsNonSeatPayment?
                                        <div className="mb-10">
                                            <div className="form-check form-check-custom form-check-solid">
                                                <input onClick={() => setSplitOpt(3)} name="radioBtn" className="form-check-input" type="radio" value="" id="flexRadioDefault2" />
                                                <label className="form-check-label" htmlFor="flexRadioDefault2">
                                                    <span className="d-flex">
                                                        <span className="ms-4">
                                                            <span className="fs-4 form-label"><FormattedMessage id='receipt.split_payment.pick_your_items' defaultMessage='Pick your item(s)' /></span>
                                                        </span>
                                                    </span>
                                                </label>
                                            </div>
                                        </div>:''
                                    }
                                </div>
                                {
                                    SplitOpt == 1 || SplitOpt == 2 ?
                                        <>
                                            <div className="separator separator-dashed my-3"></div>
                                            <div className="d-flex flex-stack" style={{ fontSize: '17px' }}>
                                                <div className="text-start fw-semibold me-2"><FormattedMessage id='receipt.split_payment.bill_total' defaultMessage='Bill Total' /></div>
                                                <div className="d-flex align-items-senter">
                                                    <span className="text-start fw-bold">${(ticketDetails.totals.grand_due / 100.00).toFixed(2)}</span>
                                                </div>
                                            </div>
                                            <div className="d-flex flex-stack" style={{ fontSize: '17px' }}>
                                                <div className="text-start fw-semibold me-2"><FormattedMessage id='receipt.split_payment.your_split' defaultMessage='Your Split' /></div>
                                                <div className="d-flex align-items-senter">
                                                    <span className="text-start fw-bold">${SplitAmt && isFinite(SplitAmt)?Number(SplitAmt).toFixed(2):(0).toFixed(2)}</span>
                                                </div>
                                            </div>
                                        </> :
                                        <>
                                            <input onChange={handleChange} className="form-control form-control-solid d-inline-block w-100" type="text" placeholder="Enter Your Name" name="guest_name" value={GuestName} />
                                        </>
                                }
                                <div className="separator separator-dashed my-3"></div>
                                <h5 className="fs-6"><FormattedMessage id='receipt.split_payment.please_select_same_method' defaultMessage='For best results, please select the same method for entire party'/></h5>
                                <div className="col-xl-12 col-md-12 col-sm-12 text-center mt-5">
                                    <CustomButton
                                        title={'Continue'}
                                        onClick={HandleSubmit}
                                        extraClass={`w-100 ${IsSbmtDisabled?'opacity05':''}`}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
}