import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IOrderContactInfo} from '../../../../pages/online_ordering/requests/process_order'

interface IOrderConfigurations{
    type:'pickup'|'curbside'|'asap'|'dine-in',
    pickup_time:string|null
}
export interface IGuestVehicleInfo{
    vehicleModel:string|null
    vehicleMake:string|null
    vehicleColor:string|null
    vehicleLicensePlates:string|null
}

interface IModItem{
    mod_item_id:number,
    name:string
}

interface IAnonymousGuest{
    first_name:string,
    last_name:string,
    phone:string
}

// interface ICartItem{
//     item_id:number,
//     name:string,
//     quantity:number,
//     mods:IModItem[]
// }

export interface IOrderSession{
    location_id:number,
    expiration_datetime:string
}

export interface IGuestOrder{
    configurations:IOrderConfigurations|null,
    guest:IAnonymousGuest|null,
    table_id:number|null,
    phone_no:string|null,
    itemId:number|null,
    session:IOrderSession|null,
    tipAmount: number | string,
    orderType: 'pickup' | 'curbside'| 'asap' | 'dine-in' | null,
    orderPickupTime:string|null,
    orderPaymentMethod:string|null,
    orderPaymentChange:boolean,
    orderPickupDate:Date|null,
    guestVehicleInfo:IGuestVehicleInfo|null
    guestCardDetails:IGuestCardInfo|null,
    guestDraftCardDetails:IGuestCardInfo|null
    guestOrderContactInfo:IOrderContactInfo|null

}

export interface IGuestCardInfo{
    cardNum:string|null|undefined,
    cardType:string|null|undefined,
    cvv: string|null|undefined,
    cardHolderName: string|null|undefined,
    expiry: string|null|undefined,
    zipCode:string|null|undefined,
    token:string|null|undefined,
    mode:string|null|undefined

}

const initialState:IGuestOrder = {
    configurations:null,
    guest:null,
    table_id:null,
    phone_no:null,
    itemId:null,
    session:null,
    tipAmount:0,
    orderType:null,
    orderPickupTime:null,
    orderPaymentMethod:null,
    orderPaymentChange:false,
    orderPickupDate:null,
    guestVehicleInfo:null,
    guestCardDetails:null,
    guestDraftCardDetails:null,
    guestOrderContactInfo:null,
}

export const orderSlice = createSlice({
    name:'Order',
    initialState,
    reducers:{
        setOrderConfigurations: (state, action:PayloadAction<IOrderConfigurations|null>) => {
            state.configurations = action.payload
        },
        setOrderGuest: (state, action:PayloadAction<IAnonymousGuest|null>) => {
            state.guest = action.payload
        },
        setPhoneNo: (state, action:PayloadAction<string|null>)=> {
            state.phone_no = action.payload
        },
        setSelectedItemId: (state, action:PayloadAction<number|null>)=> {
            state.itemId = action.payload
        },
        setOrderSession:(state, action:PayloadAction<IOrderSession|null>)=>{
            state.session = action.payload
        },
        setOrderType:(state, action:PayloadAction<'pickup' | 'curbside' | 'asap' | 'dine-in' | null>)=>{
            state.orderType = action.payload
        },
        setPickupDate:(state, action:PayloadAction<Date|null>)=>{
            state.orderPickupDate = action.payload
        },
        setPickupTime:(state, action:PayloadAction<string|null>)=>{
            state.orderPickupTime = action.payload
        },
        setPaymentMethod:(state, action:PayloadAction<string|null>)=>{
            state.orderPaymentMethod = action.payload
        },
        setGuestVehicleInfo:(state, action:PayloadAction<IGuestVehicleInfo|null>)=>{
            state.guestVehicleInfo = action.payload
        },
        togglePaymentChange:(state, action:PayloadAction<boolean>)=>{
            state.orderPaymentChange = action.payload
        },
        resetOrder: (state, action:PayloadAction<IOrderSession|null>) => {
            state.session = action.payload
            state.configurations = null
            state.itemId = null
            state.guest = null
            state.phone_no = null
            state.table_id = null
            state.guestOrderContactInfo = null
            state.guestVehicleInfo = null
            state.orderType = null
            state.orderPickupTime = null
            state.orderPickupDate = null
        },
        setFinalTipAmount: (state, action:PayloadAction<number | string>) => {
            state.tipAmount = action.payload
        },
        setGuestCardDetails:(state, action:PayloadAction<IGuestCardInfo|null>)=>{
            state.guestCardDetails = action.payload
        },
        setGuestDraftCardDetails:(state, action:PayloadAction<IGuestCardInfo|null>)=>{
            state.guestDraftCardDetails = action.payload
        },
        setOrderContactInfo:(state, action:PayloadAction<IOrderContactInfo|null>)=>{
            state.guestOrderContactInfo = action.payload
        }
    }
})



export const {setOrderConfigurations, setOrderGuest, setPhoneNo, setSelectedItemId, setOrderSession, resetOrder, setFinalTipAmount,setPickupTime,setOrderType,setPickupDate, setPaymentMethod, togglePaymentChange, setGuestCardDetails,setGuestVehicleInfo, setGuestDraftCardDetails,setOrderContactInfo } = orderSlice.actions


