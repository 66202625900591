import {createSlice} from '@reduxjs/toolkit'
import type {PayloadAction} from '@reduxjs/toolkit'
import {constants} from "../config/constants";

export interface Source {
    flow: string|null,
    business_source: number|null,
    payment_source: string|null,
    profile_auth: string|null,
}

const initialState: Source = {
    flow: constants.flow.profile,
    business_source: null,
    payment_source:null,
    profile_auth:null,
}

export const SourceSlice = createSlice({
    name: 'flow',
    initialState,
    reducers: {
        setFlow: (state, action: PayloadAction<any>) => {
            console.log('[SourceSlice] flow update: ', action.payload)
            state.flow = action.payload
        },
        setBusinessSource: (state, action: PayloadAction<any>) => {
            state.business_source = action.payload
        },
        setProfileAuth: (state, action: PayloadAction<any>) => {
            state.profile_auth = action.payload
        },
        setPaymentSource: (state, action: PayloadAction<any>) => {
            state.payment_source = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const {
    setFlow,
    setBusinessSource,
    setPaymentSource,
    setProfileAuth
} = SourceSlice.actions

export default SourceSlice.reducer
