import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

const OLO_KEYS = [
  'curbside', 'disclaimer', 'min_pre_time',
  'intervals', 'orders_per_interval', 'orders_before_open', 'scheduled_orders','schedule_orders_days',
  'revenue_center', 'order_type', 'ordering_employee', 'tips', 'tip_selections', 'pay_in_store', 'pay_by_card', 'pay_by_gift_card',
  'display_customer_name', 'display_customer_phone', 'display_customer_pick_up_time', 'display_customer_cubside'
]

export interface IOloSettings{
  curbside:null|number,
  disclaimer:null|string,
  min_pre_time:null|number,
  intervals:null|number,
  orders_per_interval:null|number,
  orders_before_open:number,
  scheduled_orders:number,
  schedule_orders_days:null|number,
  revenue_center:null|number,
  order_type:null|number,
  ordering_employee:null|number,
  tips:number,
  tip_selections:null|string,
  pay_in_store:number,
  pay_by_card:number,
  pay_by_gift_card:number,
  display_customer_name:number,
  display_customer_phone:number,
  display_customer_pick_up_time:number,
  display_customer_cubside:number
}

export interface LocationSettings {
  value: any
}

export const initialState: LocationSettings = {
  value: {}
}

const AppSettingsSlice = createSlice({
  name: 'LocSettings',
  initialState,
  reducers: {
    StoreLocationSettings: (state, action: PayloadAction<any>) => {
      state.value = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { StoreLocationSettings } = AppSettingsSlice.actions

export default AppSettingsSlice.reducer
