import { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "../../store";
import { constants } from "../../config/constants";
import { HeaderTitleContext } from "../../../_spc/layout/components/HeaderTitleContext/HeaderTitleContextStore";
// @ts-ignore
import Cookies from 'js-cookie'
import { toAbsoluteUrl } from '../../../_spc/helpers';
import {SecureImageAttributes, SecureImg} from '../../common/images/SecureImg';
import { AuthViewTypes, useAuthViewReducer } from '../../modules/auth/hooks/AuthReducers';
import { SuspensedView } from '../../routing/PrivateRoutes';
import TwoFactorAuthView from '../../modules/auth/components/TwoFactorAuthView';
import * as ApiCallService from "../../services/ApiCallService";
import { useAppAlerts } from '../../utilities/alerts';
import { baseCountries, countries } from '../../common/country_state/Countries';
import { I2faRequest, IRegistrationValidationReq } from '../../modules/auth/requests/Mobile';
import axios from 'axios';
import Swal from 'sweetalert2';
import { instanceOfSpError, ISpError } from '../../common/api_interfaces/Errors';
import { setLogOut } from '../../services/store/guests';
import { IFamilyMember } from '../profile/MyAccount';
import { FormattedMessage, useIntl } from 'react-intl';

interface IDependentState {
    dependent: IFamilyMember
}

interface IUserImageRequest {
    locationOwnerId:number,
    guestId:number,
    globalOn:string,
    apiUrl:string
}   

const getUserImage = (request:IUserImageRequest) => {
    const placeholderImageUrl = <img src={toAbsoluteUrl('/media/svg/avatars/blank.svg')} className="w-50px h-50px me-6 rounded-circle" />;
    const headers = {
        Authorization: `Bearer ${Cookies.get(constants.cookies_name.access_token)}`,
    }
    let ImgData = `${request.apiUrl}/global/download_secure_file/biometrics?global_on_id=${request.globalOn}&user_id=${request.locationOwnerId}&biometric_type=selfie_thumbnail&guest_id=${request.guestId}`;

    const selfieOptions:SecureImageAttributes = {
        src:ImgData,
        className:'w-50px h-50px me-6 rounded-circle',
        alt:'selfie'
    }
    
    return(
        <SecureImg
        imageAttributes={selfieOptions}
        placeholder={placeholderImageUrl}
        headers={headers} />
    )
}

const DeleteAccount = () => {
    const intl = useIntl();

    const LocSettings = useSelector((state: RootState) => state.LocSettings.value);
    const navigateTo = useNavigate();
    const { setPageTitle } = useContext(HeaderTitleContext)
    const guest = useSelector((state: any) => state.guest);
    const GLOBAL_ON = process.env.REACT_APP_GLOBAL_ON
    const API_URL = process.env.REACT_APP_SP_API_URL;
    const [authViewState, dispatchNewView] = useAuthViewReducer()
    const {fireSuccessAlert, fireErrorAlert, fireLoadingAlert} = useAppAlerts()
    const {errors} = constants
    const dispatchApp = useDispatch<AppDispatch>();
    const location = useLocation()
    const [verified, setVerified] = useState<boolean>(false);
    const locationState = location.state as IDependentState

    useEffect(() => {
        setPageTitle(intl.formatMessage({id:constants.staticPageTitle.delete_account}));
    }, [])

    const isDependentGuest = () => {
        return locationState?.dependent !== undefined;
    }

    const getGuestId = () => {
        return locationState?.dependent?.id ? locationState.dependent.id : guest.data.id;
    }

    const guestPhone = () => {
        let phoneLength = 10;
        if(guestCountryId){
            let countryCode  = baseCountries[guestCountryId]['id'] ? baseCountries[guestCountryId]['id'] : "US";
            if(countries[countryCode]?.pn_length>0){
                phoneLength = countries[countryCode]?.pn_length;
            }
        }
        return guest?.data?.phone?.replace(/[^0-9]/g, "").slice(phoneLength*-1);
    }
    const phoneCountryId = () => {
        let global_country_id = guest?.data?.phone_global_country_id;
        if(!global_country_id)
        global_country_id = guest?.data?.global_country_id;
        return global_country_id;
    }

    let guestCountryId = phoneCountryId();
    let guestPhoneNumber = guestPhone();

    const handleDeleteAccountBtn = () => {
        if (isDependentGuest() || (guest?.data?.phone && guest.data.phone.length < 6)){
            deleteAccount();
            return;
        }

        let payload:I2faRequest = {
            phone_number: guestPhoneNumber,
            global_country_id: guestCountryId,
            user_id: LocSettings.location.owner,
            global_on_id: GLOBAL_ON
        }
        
        axios.post(`${API_URL}/locations/${LocSettings.location.id}/mobile/send-2fa`, payload)
        .then((authRes: any) => {
            fireSuccessAlert(intl.formatMessage({id:'common.verification_code_sent'}))
            .then((result) => {
                dispatchNewView({type:AuthViewTypes.CODE})
            })
        }).catch((error) => {
            const jsonRes:any | ISpError = error?.response?.data
            const msg = jsonRes && instanceOfSpError(jsonRes) ?
                error.response.data.error.message :
                intl.formatMessage({id:errors.static.default_error})
            fireErrorAlert(msg);
        })
    }

    const postCode = (code:number|string) => {
        let reqPayload:IRegistrationValidationReq={
            phone_number: guestPhoneNumber,
            global_country_id: guestCountryId,
            verification_code: code,
            type: "2fa",
        }
        fireLoadingAlert(intl.formatMessage({id:"myaccount.delete.alert.verifying_code"}))
        ApiCallService.ApiCall(reqPayload, 'mobile/guest-registration-validation', 'POST', LocSettings)
        .then((res: any) => {
            Swal.close()
            if (res.error) {
                fireErrorAlert(res.error?.message ? res.error.message : intl.formatMessage({id:errors.static.default_error}))
            }else{
                console.log("READY TO DELETE");
                setVerified(true);
            }
        })
    }

    const deleteAccount = () => {
        fireLoadingAlert(intl.formatMessage({id:"myaccount.delete.alert.deleting"}))
        axios.post(`${API_URL}/trueface/template/delete?global_on_id=${GLOBAL_ON}&guest_id=${getGuestId()}`, {})
                    .then(response => {
                        Swal.close()
                        fireSuccessAlert(isDependentGuest() ? intl.formatMessage({id:"myaccount.deleted.your_dependent_deleted"}): intl.formatMessage({id:"myaccount.deleted.your_account_deleted"}),intl.formatMessage({id:"alert.ok"}))
                        .then((result) => {
                            if(isDependentGuest()){
                                navigateTo(-1);
                            }else{
                                dispatchApp(setLogOut())    
                                navigateTo(LocSettings.base_domain + '/');
                            }
                        });                
                    })
                    .catch(error => {
                        console.log("error.response - ", error.response);
                        const jsonRes:any | ISpError = error.response.data
                        const alert = instanceOfSpError(jsonRes) ?
                            error.response.data.error.message :
                            intl.formatMessage({id:errors.static.default_error})
                        Swal.close()
                        fireErrorAlert(alert)
                            .then(() => {
                                console.log(error.response.data)
                            })
                    })
    }

    const fetchNewCode = () => {
        let reqPayload:I2faRequest={
            phone_number: guestPhoneNumber,
            global_country_id: guestCountryId,
            user_id: LocSettings.location.owner,
            global_on_id: GLOBAL_ON
        }
 
        axios.post(`${API_URL}/locations/${LocSettings.location.id}/mobile/send-2fa`, reqPayload)
        .then((authRes: any) => {
            fireSuccessAlert(intl.formatMessage({id:'common.verification_code_sent'}))
        }).catch((error) => {
            const jsonRes:any | ISpError = error?.response?.data
            const msg = jsonRes && instanceOfSpError(jsonRes) ?
                error.response.data.error.message :
                intl.formatMessage({id:errors.static.default_error})
            fireErrorAlert(msg);
        })
    }
 
    if(authViewState.displayCodeView && !verified){
        return(
            <SuspensedView>
                <TwoFactorAuthView phoneNumber={guestPhoneNumber} phoneCountryId={guestCountryId} onCodeSubmit={postCode} onResendCode={fetchNewCode} />
            </SuspensedView>
        )
    } 

    return (
        <>
            <div className='container-fluid mw-500px px-0 mt-n5 '>
                <div className='d-flex flex-column h-100 flex-xl-row p-5 p-lg-10 p-xl-15 pt-10'>
                    {!verified && (
                        <div className='logo pt-0'>
                            <div className="py-2">
                                <div className="d-flex ">
                                    {getUserImage({locationOwnerId:LocSettings.location.owner, guestId:getGuestId(), apiUrl:API_URL!, globalOn: GLOBAL_ON!})}
                                    {isDependentGuest() && (
                                        <div className="d-flex flex-column">
                                            <a href="#" className="fs-5 text-dark text-hover-primary fw-bold">{locationState.dependent.firstName} {locationState.dependent.lastName}</a>
                                            <div className="fs-6 fw-semibold text-gray-400" style={{textAlign:'left'}}>{locationState.dependent.relation}</div>
                                        </div>
                                    )}    
                                    {!isDependentGuest() && (
                                        <div className="d-flex flex-column justify-content-center fs-5 text-dark text-hover-primary fw-bold">
                                            {guest?.data?.first_name} {guest?.data?.last_name}
                                        </div>
                                    )}    
                                </div>
                            </div>
                        </div>
                    )}
                    <div className='w-100 card p-5 card card-flush'>
                        {!verified && (
                            <>
                                <div className="card-body pt-2 p-0">
                                    <h3 className="card-title px-2 align-items-start flex-column">
                                    <FormattedMessage id='myaccount.delete.permanently_delete' defaultMessage='Permanently Delete' />
                                    </h3>
                                    <div className="form-label fs-6 mt-5 mx-2"><FormattedMessage id='myaccount.delete.guest_and_data_will_be_removed' defaultMessage='This Guest and there personal data (i.e Biometric Data) will be permanently removed from SoftPoint. This action can not be reversed.' />
                                    </div>
                                </div>
                                <div className='card-footer d-flex justify-content-end pt-7 pe-5 pb-2'>
                                    <button type='button' onClick={handleDeleteAccountBtn} id='discardGuestbtn' className='btn btn-light-danger border-active me-2'><FormattedMessage id='myaccount.delete.permanently_delete' defaultMessage='Permanently Delete' /></button>
                                </div>
                            </>
                        )}
                        {verified && (
                            <>
                                <div className="card-body pt-2 p-0 form-label text-center fw-bold fs-6 my-3 mx-2">
                                <FormattedMessage id='myaccount.delete.you_about_to_delete_your_account' defaultMessage="You're about to Permanently delete your account. If you are ready to delete, click Delete. After the deletion process you won't be able to retrieve any of the content or information you have added." />
                                </div>
                                <div className='card-footer d-flex flex-stack pt-7 pe-5 pb-2'>
                                        <Link to={`${LocSettings.base_domain}/my-account`} state={{openGeneralSettingsTab:true}}>
                                        <FormattedMessage id='common.go_back' defaultMessage='Go Back' />
                                        </Link>
                                        <button
                                            onClick={() => deleteAccount()}
                                            type='button'
                                            className='btn btn-light-danger border-active w-20'> <FormattedMessage id='myaccount.delete' defaultMessage='Delete' />
                                        </button>
                                    </div>
                            </>
                        )}
                    </div>
                     
                </div>
            </div>
        </>
    )
}

export default DeleteAccount