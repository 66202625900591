import { createSlice } from '@reduxjs/toolkit'

export interface TicketState {
    isLoading: boolean,
    data: object,
    isPublicReceipt: boolean,
    fromUrl: object,
    error: object,
}

const initialState: TicketState = {
    isLoading: false,
    data: {},
    isPublicReceipt: false,
    fromUrl: {},
    error: {},
}

export const ticketSlice = createSlice({
    name: 'ticket',
    initialState,
    reducers: {
        startLoading: (state) => {
            state.isLoading = true;
        },
        setTicket: (state, action) => {
            state.data = action.payload
            state.isLoading = false
        },
        setUrlData: (state, action) => {
            state.fromUrl = action.payload
            state.isLoading = false
        },
        setIsPublicReceipt: (state, action) => {
            state.isPublicReceipt = action.payload
            state.isLoading = false
        },
        setError: (state, action) => {
            state.error = action.payload
            state.isLoading = false
        },
        setTicketInitialState: (state) => {
            state.isLoading = false
            state.data = {}
            state.isPublicReceipt = false
            state.fromUrl = {}
            state.error = {}
        }
    },
})

// Action creators are generated for each case reducer function
export const { startLoading, setTicket, setUrlData, setError, setIsPublicReceipt, setTicketInitialState } = ticketSlice.actions
