/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState, useContext, useRef} from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { KTSVG, toAbsoluteUrl } from '../../../helpers';
import { useLayout } from '../../core';
import clsx from 'clsx';
import { HeaderTitleContext } from '../HeaderTitleContext/HeaderTitleContextStore';
import { useSelector, useDispatch } from 'react-redux'
import type { AppDispatch, RootState } from '../../../../app/store'
import { StoreLocationSettings } from '../../../../app/services/AppSettingsSlice'
import { useThemeMode } from '../../../partials'
import { constants } from "../../../../app/config/constants";
import {isEmpty} from "lodash"

// @ts-ignore
import Cookies from 'js-cookie'
import { Aside } from './Sidebar/Aside';
import { CartBar } from '../Cartbar/Cartbar';
import { setFlow } from '../../../../app/services/SourceSlice';
import { setUnableToUseBiometricsLogin } from '../../../../app/services/PhoneAuthStepTracker';
import {
  useLocationBasedBtnColor,
  useSVGHeaderCartTextColorBasedPCLoction
} from '../../../../../src/app/utilities/helpers';
import { capitalizeWords } from '../../../../app/services/hooks/useCapitalizeWords';
import axios, {AxiosRequestConfig} from "axios";
import {useErrorAlert} from "../../../../app/utilities/alerts";
import {instanceOfSpError} from "../../../../app/common/api_interfaces/Errors";
import { setSidebarActiveMenu } from '../../../../app/services/store/sidebar';
import {FormattedMessage, useIntl} from 'react-intl';
import './Header.scss';
import {
  getI18n,
  getI18nFlag,
  getI18nName,
  getSupportedLanguages,
  setLanguageManually
} from "../../../i18n/I18n";



export function HeaderWrapper() {
  const intl = useIntl();
  const { mode, menuMode, updateMode, updateMenuMode } = useThemeMode()
  const dispatch = useDispatch<AppDispatch>();
  const LocSettings = useSelector((state: RootState) => state.LocSettings.value)
  const SourceFlow = useSelector((state: RootState) => state.Source.flow)
  const cartBtnDisplay = useSelector((state: RootState) => state.cartButton.cartButtonDisplay)
  const { pageTitle } = useContext(HeaderTitleContext)
  let location = useLocation()
  let pathName = location.pathname
  const [title, setTitle] = useState('')
  const [displayCartIcon, setDisplayCartIcon] = useState(true)
  const [profileIconShow, setProfileIconShow] = useState(true)
  const [profileRightMenuShow, setProfileRightMenuShow] = useState(false)
  const rightMenuBtn = useRef<HTMLDivElement | null>(null);
  const rightMenuContainer = useRef<HTMLDivElement | null>(null);
  const { config, classes, attributes } = useLayout()
  const { aside } = config
  const { cookies_name,flow } = constants;
  const guest = useSelector((state: any) => state.guest);
  const { location_id: location_id_or_name } = useParams();
  let navigateTo = useNavigate()
  const capPageTitle = pageTitle === constants.staticPageTitle.verify_id ? intl.formatMessage({id:constants.staticPageTitle.verify_id}) : capitalizeWords(pageTitle);
  const capitalizeTitle =  capitalizeWords(title);
  const fireErrorAlert = useErrorAlert()
  const dynamicBtnColor = useLocationBasedBtnColor()

  const svgStyleColor = useSVGHeaderCartTextColorBasedPCLoction()
  const GLOBAL_ON_ID = process.env.REACT_APP_GLOBAL_ON
  const exceptProfileIconRoute = ['checkin','phone_auth','auth','register', 'activate', 'activate-done']
  const exceptProfileIconRouteWithoutLocation = ['activate']
  const cartItems = useSelector((state: RootState) => state.cartItems.data)

  const I18n = getI18n();

  useEffect(()=>{console.log('Object.keys(LocSettings).length != 0', Object.keys(LocSettings).length != 0)}, [LocSettings])

  useEffect(() => {
    if(Object.keys(LocSettings).length != 0){
      const API_URL = process.env.REACT_APP_SP_API_URL;
      const callConfigs:AxiosRequestConfig = {
        headers:{
          'X-Skip-Interceptor':'true'
        }
      }
      axios.get(`${API_URL}/locations/${LocSettings.location.id}/mobile/settings?global_on_id=${GLOBAL_ON_ID}`, callConfigs)
          .then(response => {
            let  base_domain = `/${location_id_or_name}`

            if (response?.data.domain_name && response?.data.domain_name.trim() !== '') {
              Cookies.set(cookies_name.location_domain_name, response?.data.domain_name)
              base_domain = `/${response?.data.domain_name}`
            }

            dispatch(StoreLocationSettings({
              ...response?.data,
              'base_domain': base_domain
            }))
          })
          .catch(error => {
            const errorContent = error.response.data
            let errorMessage =  intl.formatMessage({id:constants.errors.static.unexpected_contact_support})
            if(instanceOfSpError(errorContent)){
              errorMessage = errorContent.error.message
            }
            fireErrorAlert(errorMessage)
          })
    }
  }, []);

  useEffect(() => {
    // Function to handle clicks outside the right menu container
    const handleClickOutside = (event: MouseEvent) => {
      if (
          rightMenuContainer.current &&
          rightMenuBtn.current &&
          !rightMenuContainer.current.contains(event.target as Node) &&
          !rightMenuBtn.current.contains(event.target as Node)
      ) {
        // Click occurred outside the right menu container, so close the menu
        if(profileRightMenuShow) {
          setProfileRightMenuShow(false);
        }
      } else {
        console.log("clicked no menu", profileRightMenuShow)
      }
    };

    // Attach the event listener when the menu is open
    if (profileRightMenuShow) {
      document.addEventListener('click', handleClickOutside);
    } else {
      // Remove the event listener when the menu is closed
      document.removeEventListener('click', handleClickOutside);
    }

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [profileRightMenuShow]);

  useEffect(()=>{
    setDisplayCartIcon(!cartBtnDisplay)
  },[cartBtnDisplay])


  let user_id = 0;
  if (LocSettings?.location?.owner !== undefined && LocSettings?.location?.owner > 0) {
    user_id = LocSettings?.location?.owner;
  }

  let LogoUrlBlack = toAbsoluteUrl('/media/logos/softPointBlack.svg');
  let LogoUrlLight = toAbsoluteUrl('/media/logos/softpoint_small.svg');
  let LocationIcon = '';
  let profileIcon = null;
  if (LocSettings?.icon) {
    LocationIcon = `${process.env.REACT_APP_SP_API_URL}/download-file?path=${LocSettings?.icon}&global_on_id=${GLOBAL_ON_ID}&user_id=${user_id}`;
  }

  if (!isEmpty(guest.data)) {
    profileIcon = !isEmpty(guest.data) && guest.data.image !== null ? `${process.env.REACT_APP_SP_API_URL}/download-file?path=${guest.data.image}&global_on_id=${GLOBAL_ON_ID}&user_id=${user_id}`:null;
  }
  if (LocSettings?.primary_color) {
    const root = document.documentElement;
    root.style.setProperty('--kt-primary-active', LocSettings?.primary_color);
    root.style.setProperty('--kt-primary', LocSettings?.primary_color);
    root.style.setProperty('--kt-text-primary', LocSettings?.primary_color);
  }
const cartButtonStyle = () =>{
  if (
    LocSettings.appearance === 0 &&
    LocSettings?.primary_color == '#ffffff'
  ) {
    return 'text-black'
  }
  if (
    LocSettings.appearance === 1 &&
    LocSettings?.primary_color == '#151520'
  ) {
    return 'text-white'
  } else {
    return ''
  }
}
  useEffect(() => {
    if (LocSettings?.appearance == 0) {
      updateMenuMode('dark');
      updateMode('dark');
    } else {
      updateMenuMode('light');
      updateMode('light');
    }
  }, []);

  const headerTitle = () => {
    switch (pathName) {
      case '/dashboard':
        return setTitle('Contactless Hub');
      case '/pre-checkin':
        return setTitle('Check In');
      case '/phone_auth':
      case '/auth':
        return setTitle('Verify');
      default:
        return;
    }
  };

  const handleProfile = () => {

    if(exceptProfileIconRoute.includes(pathName.split("/")[2]) || exceptProfileIconRouteWithoutLocation.includes(pathName.split("/")[1])){
      setProfileIconShow(false)
    }else{
      setProfileIconShow(true)
    }
    if(!pathName.split("/")[2])
      dispatch(setSidebarActiveMenu(1));
  }
  useEffect(() => {
    headerTitle()
    handleProfile()
  }, [pathName]);


  const handleProfileRedirect = () => {
    setProfileRightMenuShow(true);
  }

  const redirectToLogin = () => {
    setProfileRightMenuShow(false)
    dispatch(setFlow(flow.profile));
    dispatch(setUnableToUseBiometricsLogin(false));
    dispatch(setSidebarActiveMenu(5));
    Cookies.set(cookies_name.my_account.my_account, 'yes')
    navigateTo(LocSettings.base_domain + '/my-account');
  }

  const showCartIcon = () => {
    return (pathName.includes('/menu/') || pathName.endsWith('/menu') ) && displayCartIcon;
  }

  return (
    <>
      <Aside />
      <div
        style={{ left: '0px',zIndex:'9999' }}
        id='kt_header'
        className={clsx('header', classes.header.join(' '), 'align-items-stretch')}
        {...attributes.headerMenu}
      >

        <div
          className={clsx(
            classes.headerContainer.join(' '),
            'd-flex align-items-stretch justify-content-between'
          )}
        >
          <div className="d-flex align-items-center me-1" title="Show aside menu">
            <div className="btn btn-icon  w-30px h-30px w-md-40px h-md-40px" style={{justifyContent:'left'}} id="kt_aside_mobile_toggle">
              <span className="svg-icon svg-icon-2x mt-1">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z" fill="#565674"></path>
                  <path opacity="0.3" d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z" fill="#565674"></path>
                </svg>
              </span>
            </div>
          </div>
          {!aside.display && (
            <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0'>
              <Link to={`/${location_id_or_name}/`} className='d-lg-none'>
                <img
                  alt=''
                  src={LogoUrlLight}
                  className='h-40px'
                />
              </Link>
            </div>
          )}


          {/* end::Logo */}
          {aside.display && (
            <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0 overflow-hidden'>
              {LocationIcon !== '' ?
                <div className=' symbol symbol-35px symbol-md-40px' >
                  <img
                    // alt='Merchant Logo'
                    src={LocationIcon}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.alt = "";
                    }}
                    loading="lazy"
                  />
                </div>
                : ''
              }
              <div className='fw-bold fs-2 ps-2' style={{clear: 'both',display: 'inline-block',overflow: 'hidden',whiteSpace: 'nowrap'}}>{capPageTitle === '' ? capitalizeTitle : capPageTitle}</div>
            </div>
          )}
          {/* begin::Wrapper */}
          <div className='d-flex align-items-stretch justify-content-end flex-lg-grow-1'>
            <div className='d-flex align-items-stretch flex-shrink-0'>
              {/* <Topbar /> */}
            </div>
          </div>

          {/* end::Wrapper */}
          <div ref={rightMenuBtn} className="d-flex align-items-center flex-grow-1 flex-lg-grow-0 justify-content-end">
            {
              profileIconShow
                ?
                profileIcon && guest.data.icon !== '' ?
                  <img src={profileIcon}
                    className={`h-40px w-40px symbol cursor-pointer symbol ${showCartIcon() ? 'me-3' : ''}`}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = toAbsoluteUrl('/media/svg/avatars/blank.svg');
                    }}
                    onClick={handleProfileRedirect}

                  /> :

                  <div className={`d-flex align-items-center flex-grow-1 flex-lg-grow-0 justify-content-end ${showCartIcon() ? 'me-3' : ''}`}>
                    <div className="cursor-pointer symbol symbol-35px symbol-md-40px">
                      <img src='/media/svg/avatars/blank.svg'
                        onError={({ currentTarget }) => {
                          currentTarget.alt = ''; // prevents looping
                        }}

                        onClick={handleProfileRedirect}
                      />
                    </div>
                  </div>
                : ''
            }

                  {
                      showCartIcon() ?
                        <div id='kt_cbar_mobile_toggle' className={`cursor-pointer symbol symbol-35px symbol-md-40px p-2 svg-cart-theme-styles`}>
                          <KTSVG
                            path='/media/icons/duotune/ecommerce/ecm012.svg'
                            className={`svg-icon-2x d-block mb-lg-0 ${cartButtonStyle()}`}
                          />
                          {/* <i style={{float:'right', fontSize:'20px'}} className={`svg-icon bi mt-1 ms-1 bi-${cartItems.length}-circle ${svgStyleColor}`}></i> */}
                          {
                              cartItems.length > 0 &&  <span className={`${cartItems.length > 99 ? 'custom-item-count-3-digit' :'custom-item-count-2-digit'} mt-1 ms-1 float-end ${cartButtonStyle()}`}>
                              <span className={`${cartButtonStyle()}`}>{cartItems.length}</span>
                            </span>
                            }
                        </div>
                        : ''
                    }
          </div>

          <div
              ref={rightMenuContainer}
              className={`menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-color fw-semibold py-4 fs-6 w-275px ${profileRightMenuShow ? 'show' : ''}`}
              data-kt-menu="true"
              data-popper-placement="bottom-end"
              style={{
                zIndex: 107,
                position: 'fixed',
                inset: '0px 0px auto auto',
                margin: '0px',
                transform: 'translate3d(-49.8182px, 58.9091px, 0px)',
              }}
          >
            {/* Menu item */}
            {
                guest.auth.isAuthenticated &&
                <>
                  <div className="menu-item px-3">
                    <div className="menu-content d-flex align-items-center px-3">
                      {/* Avatar */}
                      <div className="symbol symbol-50px me-5">
                        {
                          profileIconShow
                              ?
                              profileIcon && guest.data.icon !== '' ?
                                  <img src={profileIcon}
                                       className="h-40px w-40px symbol cursor-pointer symbol"
                                       onError={({ currentTarget }) => {
                                         currentTarget.onerror = null; // prevents looping
                                         currentTarget.src = toAbsoluteUrl('/media/svg/avatars/blank.svg');
                                       }}
                                       onClick={handleProfileRedirect}

                                  /> :

                                  <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0 justify-content-end'>
                                    <div className="cursor-pointer symbol symbol-35px symbol-md-40px">
                                      <img src='/media/svg/avatars/blank.svg'
                                           onError={({ currentTarget }) => {
                                             currentTarget.alt = ''; // prevents looping
                                           }}

                                           onClick={handleProfileRedirect}
                                      />
                                    </div>
                                  </div>
                              : ''
                        }
                      </div>
                      {/* Username */}
                      <div className="d-flex flex-column">
                        <div className="fw-bold d-flex align-items-center fs-5">
                          {guest.data.first_name}
                        </div>
                        <a
                            href="#"
                            className="fw-semibold text-muted text-hover-primary fs-7"
                        >
                          {guest.data.email}
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* Menu separator */}
                  <div className="separator my-2"></div>
                </>
            }
            {/* Menu item */}
            {
                guest.auth.isAuthenticated == false &&
                <div
                    className="menu-item px-5"
                    data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                    data-kt-menu-placement="left-start"
                    data-kt-menu-offset="-15px, 0"
                >
                  <button
                      type='button'
                      id='kt_sign_in_submit'
                      onClick={redirectToLogin}
                      className={`btn btn-lg btn-primary w-100 mb-5 ${dynamicBtnColor}`}
                  >
                    <span className='indicator-label'>{guest.auth.isAuthenticated==true?intl.formatMessage({id:"sidebar.log_out"}):intl.formatMessage({id:"common.login"})}</span>
                  </button>
                </div>
            }
            {/* Menu item */}
            {/* Menu item */}
            <div
                className="menu-item menu-item-language px-5"
                data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                data-kt-menu-placement="left-start"
                data-kt-menu-offset="-15px, 0"
            >
              <a href="#" className="menu-link px-5">
                <span className="menu-title position-relative">
                  <FormattedMessage id='common.language' defaultMessage='Language' />
                  <span className={`fs-8 rounded btn btn-primary px-3 py-2 position-absolute translate-middle-y top-50 end-0 ${dynamicBtnColor}`}>
                    {getI18nName()}
                    <KTSVG
                        path={getI18nFlag()}
                        className="w-15px h-15px rounded-1 ms-2"
                    />
                  </span>
                </span>
              </a>
              {/* Menu sub */}
              <div className="menu-sub menu-sub-dropdown w-175px py-4">
                {
                  Object.values(getSupportedLanguages()).map((langData, index) => (
                      <div className="menu-item px-3" key={index}>
                        <a
                            onClick={() => setLanguageManually(langData.lng)}
                            className={`menu-link d-flex px-5 ${I18n == langData.lng ? 'active' : ''}`}
                        >
                        <span className="symbol symbol-20px me-4">
                          <KTSVG
                              path={langData.flag_path}
                              className="rounded-1"
                          />
                        </span>
                          {langData.display_name}
                        </a>
                      </div>
                  ))
                }
              </div>
              {/* Menu sub */}
            </div>
            {/* Menu item */}
          </div>
        </div>
      </div>

      {
          (Object.keys(LocSettings).length != 0 && LocSettings.id != undefined) && <CartBar />
      }

    </>
  );
};
