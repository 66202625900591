/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, ReactElement, useContext, useEffect, useState} from 'react'
import {AppDispatch, RootState} from '../../store'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import Swal, {SweetAlertResult} from 'sweetalert2'
import {KTSVG} from '../../../_spc/helpers/components/KTSVG'
import {toAbsoluteUrl} from '../../../_spc/helpers/AssetHelpers'
// @ts-ignore
import Cookies from 'js-cookie'
import { constants } from '../../config/constants';
import { HeaderTitleContext } from '../../../_spc/layout/components/HeaderTitleContext/HeaderTitleContextStore';
import {isEmpty} from "lodash"
import { setFlow } from "../../services/SourceSlice";
import { setUnableToUseBiometricsLogin } from '../../services/PhoneAuthStepTracker';
import { SVGColorBasedPCLoction, SVGTextColorBasedPCLoction, TextColorBasedBgLoction } from '../../utilities/helpers';
import { setSidebarActiveMenu } from '../../services/store/sidebar';
import * as ApiCallService from "../../services/ApiCallService"
import { getMenuCategories } from '../../services/store/ordering/menu/categories';
import { setLocationState, startLoading } from '../../services/store/location_state/locationStateSlice';
import { capitalizeWords } from '../../services/hooks/useCapitalizeWords';
import { useLocation } from 'react-router-dom'
import StaticRoutePath from '../../routing/StaticRoutePath';
import { getOloSettings } from '../online_ordering/common/parsers';
import { getImage } from '../../services/hooks/useCachedImages';
import {FormattedMessage, useIntl} from "react-intl";
import {setLanguage} from "../../../_spc/i18n/I18n";
import {useErrorAlert} from "../../utilities/alerts";
import { useResetOrder } from '../online_ordering/hooks/order_session'

export const DashboardLandingPage = () => {
    const intl = useIntl()
    const fireErrorMessage = useErrorAlert()
    const STATUSES = constants.status
    const StoreDispatch = useDispatch<AppDispatch>();
    const [stateName, setStateName] = useState('');
    const LocSettings = useSelector((state: RootState) => state.LocSettings.value);
    const PRODUCTS = constants.products
    const resetOrderSession = useResetOrder({resetType: 'Nullify'})

    const capitalizeLocationName = capitalizeWords(LocSettings.location.location_name);

    let navigateTo = useNavigate();
    const SourceFlow = useSelector((state: RootState) => state.Source.flow);
    const { setPageTitle } = useContext(HeaderTitleContext);


    const root = document.documentElement;
    const base_domain = LocSettings.base_domain;
    root.style.setProperty('--kt-primary', LocSettings?.primary_color);
    root.style.setProperty('--kt-primary-active', LocSettings?.primary_color);
    root.style.setProperty('--kt-text-primary', LocSettings?.primary_color);
    const GLOBAL_ON_ID = process.env.REACT_APP_GLOBAL_ON;
    const location = useLocation();
    let darkModeLogo = '/media/logos/darkModeFullSoftPointLogo.svg';
    let lightModeLogo = '/media/logos/lightModeFullSoftPointLogo.svg';
    const guest = useSelector((state: any) => state.guest);
    const locationWebsite = LocSettings.location.website
    const oloSettings = getOloSettings(LocSettings);


    let IsQrCodeAvail = false;
    let IsOnlineOrder=false;
    const locationIntegrations:any[] = LocSettings?.integrations
    const globalCheckinIntegrationIds = constants.integrations.checkin.global_ids
    const globalReservationIntegrationIds = constants.integrations.reservations.global_ids

    const checkinProducts = LocSettings?.location?.location_products?.filter((locp:any)=>locp.code=="CHECK_IN")
    const checkinProduct = checkinProducts.length > 0 ? checkinProducts[0] : null
    const checkInIntegrations = locationIntegrations.filter((locationIntegration)=> {
        return globalCheckinIntegrationIds.includes(locationIntegration.global_integration_id)
    })
    const checkinIntegrationConfigured = checkInIntegrations.length > 0
    const locationCheckinProductEnabled = checkinProduct && checkinProduct.status == STATUSES.ON
    const globalCheckinProductEnabled = checkinProduct && checkinProduct.global_product_status == STATUSES.ON
    const checkinIsAvailable = locationCheckinProductEnabled && checkinIntegrationConfigured && globalCheckinProductEnabled


    if(LocSettings.olo.allow_ordering==true){
        IsOnlineOrder = true;

    }


const locationReservationProducts = LocSettings?.location?.location_products?.filter(
    (locp: any) => locp.code == 'RESERVATION'
  )
  const locationReservationProduct =
    locationReservationProducts.length > 0 ? locationReservationProducts[0] : null
  const reservationIntegrations = locationIntegrations.filter((integration) => {
    return globalReservationIntegrationIds.includes(integration.global_integration_id)
  })
  const reservationIntegrationConfigured = reservationIntegrations.length > 0
  const locationReservationProductEnabled =
    locationReservationProduct && locationReservationProduct.status == STATUSES.ON
  const globalReservationProductEnabled =
    locationReservationProduct && locationReservationProduct.global_product_status == STATUSES.ON
  const reservationsAreAvailable =
    locationReservationProductEnabled &&
    globalReservationProductEnabled &&
    reservationIntegrationConfigured

  let profileIcon = null
  if (LocSettings?.location?.logo) {
    lightModeLogo = `${process.env.REACT_APP_SP_API_URL}/download-file?path=${LocSettings.location.logo}&global_on_id=${GLOBAL_ON_ID}&user_id=${LocSettings.location.owner}`
    darkModeLogo = `${process.env.REACT_APP_SP_API_URL}/download-file?path=${LocSettings.location.logo}&global_on_id=${GLOBAL_ON_ID}&user_id=${LocSettings.location.owner}`
    getImage(lightModeLogo)
  }
  if (!isEmpty(guest.data) && guest.data.image) {
    profileIcon = `${process.env.REACT_APP_SP_API_URL}/download-file?path=${guest.data.image}&global_on_id=${GLOBAL_ON_ID}&user_id=${LocSettings.location.owner}`
  }
  let user_id = 0
  let LogoUrlBlack = toAbsoluteUrl('/media/logos/softPointBlack.svg')
  let LogoUrlLight = toAbsoluteUrl('/media/logos/softpoint_small.svg')
  if (LocSettings?.icon) {
    LogoUrlBlack = `${process.env.REACT_APP_SP_API_URL}/download-file?path=${LocSettings?.icon}&global_on_id=${GLOBAL_ON_ID}&user_id=${user_id}`
    LogoUrlLight = `${process.env.REACT_APP_SP_API_URL}/download-file?path=${LocSettings?.icon}&global_on_id=${GLOBAL_ON_ID}&user_id=${user_id}`
  }

  let LocationLogo = ''
  if (LocSettings?.location?.logo) {
    LocationLogo = `${process.env.REACT_APP_SP_API_URL}/download-file?path=${LocSettings.location.logo}&global_on_id=${GLOBAL_ON_ID}&user_id=${LocSettings.location.owner}`
  }

  let LocationIcon = ''
  if (LocSettings?.icon) {
    LocationIcon = `${process.env.REACT_APP_SP_API_URL}/download-file?path=${LocSettings?.icon}&global_on_id=${GLOBAL_ON_ID}&user_id=${LocSettings.location.owner}`
  }
  // console.log('LocationIco', LocationIcon);
  // console.log('LocationLogo',LocationLogo );

  const {cookies_name, flow} = constants

  //    useEffect(()=>{
  //      StoreDispatch(getMenuCategories(LocSettings))
  //    },[])

  useEffect(() => {
    if (
      typeof location.pathname?.split('/')[2] != 'undefined' &&
      location.pathname?.split('/')[2] != ''
    ) {
      if (SourceFlow === 'order') {
        fireErrorMessage('Coming Soon!')
      }
      if (SourceFlow === 'reservations') {
        fireErrorMessage('Coming Soon!')
      }
    }
    // document.body.classList.add('bg-body')
    // return () => {
    //     document.body.classList.remove('bg-body')
    // }
    if (LocSettings?.location?.location_name) {
      statesAPI()
    }

    resetOrderSession()
  }, [])
  useEffect(() => {
    setPageTitle(LocSettings.location.location_name)

    if (
      typeof location.pathname?.split('/')[2] != 'undefined' &&
      location.pathname?.split('/')[2] != ''
    ) {
      if (SourceFlow === 'order') {
        fireErrorMessage('Coming Soon!')
      }
      if (SourceFlow === 'reservations') {
        fireErrorMessage('Coming Soon!')
      }
    }
  }, [SourceFlow])

  const statesAPI = () => {
      if (LocSettings?.location?.global_country_id && LocSettings?.location?.global_state_id) {
          ApiCallService.ApiCall(
              {global_country_id: LocSettings?.location?.global_country_id},
              `global/states`,
              'GET',
              LocSettings,
              true
          ).then((res: any) => {
              let StateObj = res.data.states?.filter(
                  (r: any) => r.id == LocSettings?.location?.global_state_id
              )[0]
              // console.log('StateObj',StateObj);
              if (StateObj) {
                  setStateName(StateObj.name)
                  StoreDispatch(setLocationState(StateObj.name))
              }
          })
      }
  }

    //setting custom class for error message in modal
    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-danger btn-sm fs-5',
        },
        buttonsStyling: false,
        icon: 'error',
        background: LocSettings.appearance === 1 //if 1 or light mode set white background
            ? '#fffff'
            : '#151521',
        confirmButtonText: intl.formatMessage({id: 'alert.ok_got_it'}),
        width: '29rem',
        heightAuto: false,

    })
  const handleMyaccount = () => {
    StoreDispatch(setFlow(flow.profile))
    StoreDispatch(setUnableToUseBiometricsLogin(false))
    StoreDispatch(setSidebarActiveMenu(5))
    Cookies.set(cookies_name.my_account.my_account, 'yes')
    navigateTo(base_domain + '/my-account') //if biometrics = 0
  }

  const handleCheckin = () => {
    StoreDispatch(setFlow(flow.pre_checkin))
    StoreDispatch(setUnableToUseBiometricsLogin(false))
    StoreDispatch(setSidebarActiveMenu(4))
    Cookies.set(cookies_name.my_account.my_account, 'no')
    navigateTo(base_domain + '/pre-checkin')
  }
  const handleFindTicket = () => {
    navigateTo(base_domain + '/find_ticket')
  }


    const bgStyles: { [key: string]: React.CSSProperties } = {
        cardThemeStyles: {
            cursor: 'pointer',
            // background: localStorage.getItem('kt_theme_mode_value') === 'light'
            //     ? '#ffffff'
            //     : '#151521',
            borderRadius: '10px'
        },
    }
    const handleOnlineOrder  = () => {
        StoreDispatch(setFlow(flow.order));
        StoreDispatch(setUnableToUseBiometricsLogin(false));
        StoreDispatch(setSidebarActiveMenu(2));
        Cookies.set(cookies_name.my_account.my_account, 'no')
        if(IsOnlineOrder){
            navigateTo(base_domain + StaticRoutePath.OLO_MENU)
        }
    }
    const handleReservation  = () => {
        StoreDispatch(setSidebarActiveMenu(3))
        fireErrorMessage(intl.formatMessage({id: 'dashboard.menu.coming_song'}));
    }

    return (
        <div className='d-flex flex-row-fluid mt-50'>
            <div className='d-flex flex-row-fluid flex-column align-items-center'>
                <div className='d-flex flex-column flex-column-fluid mb-5 mb-lg-10 p-5'>

                    <div className='row g-5 w-xxl-850px '>
                        <div className='col-xxl-12'>
                            <div className='row'>
                                <div className='col-sm-12'>
                                    <div
                                        className='d-flex p-10 card'
                                        style={{backgroundColor: LocSettings?.background_color}}
                                    >
                                        <div className='row'>
                                            {/* <img src='../media/dashboad/banner.jpeg'
                                                className="w-100 h-100 bgi-no-repeat bgi-size-cover bgi-position-x-center pb-0"
                                                alt='Banner Image' /> */}
                                            <div className='col-sm-12 col-md-6 text-center text-md-start mb-5 mb-md-0'>
                                                {LocationLogo || LocationIcon ? (
                                                    <img
                                                        src={LocationIcon ? LocationIcon : LocationLogo}
                                                        onError={({currentTarget}) => {
                                                            currentTarget.onerror = null // prevents looping
                                                            currentTarget.alt = ''
                                                        }}
                                                        className='mh-150px w-150px symbol'
                                                    />
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                            <div
                                                className={`col-sm-12 col-md-6 ${TextColorBasedBgLoction(LocSettings)}`}>
                                                <h1 className={`text-start my-3 ${TextColorBasedBgLoction(LocSettings)}`}>
                                                    {capitalizeLocationName
                                                        ? capitalizeLocationName
                                                        : LocSettings?.location?.location_name}
                                                </h1>
                                                <div className='form-group d-flex fs-4'>
                                                    {/* <span className="control-label fw-bold text-nowrap" >Address : </span> */}
                                                    <div className='mt-1'>
                                                        <p className='p-0 m-0 fs-5'> {LocSettings?.location?.address},</p>
                                                    </div>
                                                </div>
                                                {LocSettings?.location?.address2 && (
                                                    <div className='form-group d-flex fs-4'>
                                                        {/* <span className="control-label fw-bold text-nowrap" >Address : </span> */}
                                                        <div className='mt-1'>
                                                            <p className='p-0 m-0 fs-5'> {LocSettings?.location?.address2}</p>
                                                        </div>
                                                    </div>
                                                )}

                                                <div className='form-group d-flex fs-4'>
                                                    {/* <span className="control-label fw-bold text-nowrap" >Address : </span> */}
                                                    <div className='mt-1'>
                                                        <p className='p-0 m-0 fs-5'>
                                                            {' '}
                                                            {`${LocSettings?.location?.city}, ${
                                                                stateName ? stateName + ',' : ''
                                                            }  ${LocSettings?.location?.zip}`}
                                                        </p>
                                                    </div>
                                                </div>

                                                <div className='form-group d-flex fs-4'>
                                                    {/* <label className="control-label fw-bold text-nowrap" htmlFor="usr">Phone : </label> */}
                                                    <div className='mt-1'>
                                                        <p className='p-0 m-0 fs-5'>{LocSettings?.location?.phone}</p>
                                                    </div>
                                                </div>
                                                <div className='form-group d-flex fs-4'>
                                                    {/* <label className="control-label fw-bold text-nowrap" htmlFor="usr">Site : </label> */}
                                                    {locationWebsite === null ? null : (
                                                        <div className='mt-1'>
                                                            <a
                                                                className={`p-0 m-0 fs-5 ${TextColorBasedBgLoction(LocSettings)}`}
                                                                target={'_blank'}
                                                                href={LocSettings.location.website}
                                                            >
                                                                {LocSettings.location.website.replace('https://', '')}
                                                            </a>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-xxl-12'>
                            <div className='row g-5'>
                                {
                                    checkinIsAvailable ?
                                        <div className='col-sm-3'>
                                            <div onClick={handleCheckin}
                                                style={bgStyles.cardThemeStyles}
                                                className='card border-0  pb-5 min-h-200px mb-5 mb-md-0'>
                                                <div
                                                    className='card-body d-flex flex-column flex-center text-center fw-bold'>
                                                    <KTSVG
                                                        path='/media/icons/duotune/general/gen063.svg'
                                                        className={`svg-icon-5x d-block my-2 pb-5 ${SVGColorBasedPCLoction(LocSettings)}`}
                                                    />
                                                    <span
                                                        className={`fs-6 fw-bold ${SVGTextColorBasedPCLoction(LocSettings)}`}>
                                                        <FormattedMessage
                                                            id='dashboard.menu.check_in'
                                                            defaultMessage='Checking In?' />
                                                    </span>
                                                </div>
                                            </div>
                                        </div> : ''
                                }
                                {
                                    IsQrCodeAvail ?
                                        <div className='col-sm-3'>
                                            <div onClick={handleFindTicket}
                                                style={bgStyles.cardThemeStyles}
                                                className='card border-0  pb-5 min-h-200px mb-5 mb-md-0'>
                                                <div
                                                    className='card-body d-flex flex-column flex-center text-center fw-bold'>
                                                    <KTSVG
                                                        path='/media/icons/duotune/custom/Ticket.svg'
                                                        className={`svg-icon-5x d-block my-2 pb-5 mb-lg-0 ${SVGColorBasedPCLoction(LocSettings)}`}
                                                    />
                                                    <span
                                                        className={`fs-6 fw-bold ${SVGTextColorBasedPCLoction(LocSettings)}`}>
                                                        <FormattedMessage
                                                            id='dashboard.menu.find_my_ticket'
                                                            defaultMessage='Find My Ticket' />
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        : ''
                                }
                                {
                                    IsOnlineOrder ?
                                        <div className='col-sm-3'>
                                            <div onClick={handleOnlineOrder}
                                                style={bgStyles.cardThemeStyles}
                                                className='card border-0  pb-5 min-h-200px mb-5 mb-md-0'>


                                                <div
                                                    className='card-body d-flex flex-column flex-center text-center fw-bold'>
                                                    <KTSVG
                                                        path='/media/icons/duotune/ecommerce/ecm012.svg'
                                                        className={`svg-icon-5x d-block my-2 pb-5 mb-lg-0 ${SVGColorBasedPCLoction(LocSettings)}`}
                                                    />
                                                    <span
                                                        className={`fs-6 fw-bold ${SVGTextColorBasedPCLoction(LocSettings)}`}>
                                                        <FormattedMessage
                                                            id='dashboard.menu.online_order'
                                                            defaultMessage='Online Order' />
                                                    </span>
                                                </div>
                                            </div>
                                        </div> : ''
                                }
                                {
                                    reservationsAreAvailable ?
                                        <div className='col-sm-3'>
                                            <div onClick={handleReservation}
                                                style={bgStyles.cardThemeStyles}
                                                className='card border-0  pb-5 min-h-200px mb-5 mb-md-0'>


                                                <div
                                                    className='card-body d-flex flex-column flex-center text-center fw-bold'>
                                                    <KTSVG
                                                        path='/media/icons/duotune/files/fil026.svg'
                                                        className={`svg-icon-5x d-block my-2 pb-5 mb-lg-0 ${SVGColorBasedPCLoction(LocSettings)}`}
                                                    />
                                                    <span
                                                        className={`fs-6 fw-bold ${SVGTextColorBasedPCLoction(LocSettings)}`}>
                                                        <FormattedMessage
                                                            id='dashboard.menu.book_a_reservation'
                                                            defaultMessage='Book A Reservation?' />
                                                    </span>
                                                </div>
                                            </div>
                                        </div> : ''
                                }

                                <div className='col-sm-3'>
                                    <div onClick={() => handleMyaccount()}
                                        style={bgStyles.cardThemeStyles}
                                        className='card border-0 min-h-200px pb-5'>
                                        <div
                                            className='card-body d-flex flex-column flex-center text-center'>
                                            <KTSVG
                                                path='/media/icons/duotune/general/gen064.svg'
                                                className={`svg-icon-5x d-block my-2 pb-5 ${SVGColorBasedPCLoction(LocSettings)}`} />

                                            <span
                                                className={`fs-6 fw-bold ${SVGTextColorBasedPCLoction(LocSettings)}`}>
                                                <FormattedMessage
                                                    id='dashboard.menu.my_account' />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  )
}
