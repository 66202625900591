import {useSelector} from "react-redux";
import {RootState} from "../../../store";
import {constants} from "../../../config/constants";
import {useNavigate, useSearchParams} from 'react-router-dom';
import {stopAllCamStreams} from "./../helpers";
// @ts-ignore
import Cookies from 'js-cookie'
import {isIPhone, useLocationBasedBtnColor} from "../../../utilities/helpers";
import {useContext, useEffect, useState} from "react";
import {EnableCameraInstructionsIphone} from "./EnableCameraInstructionsIphone";
import {EnableCameraInstructionsAndroid} from "./EnableCameraInstructionsAndroid";
import { HeaderTitleContext } from "../../../../_spc/layout/components/HeaderTitleContext/HeaderTitleContextStore";
import { FormattedMessage, useIntl } from "react-intl";

export const NoFeedOverlay = () => {
    const intl = useIntl();
    let navigateTo = useNavigate()
    const LocSettings = useSelector((state: RootState) => state.LocSettings.value);
    const {status, theme, cookies_name} = constants
    const dynamicBtnColor = useLocationBasedBtnColor()
    const appearanceStatus = status.location.appearance
    const backgroundTheme = LocSettings.appearance === appearanceStatus.lightMode ? theme.lightMode : theme.darkMode
    const textTheme = LocSettings.appearance === appearanceStatus.lightMode ? 'text-dark' : 'text-white'
    const [showEnableCameraInstructions, setShowEnableCameraInstructions] = useState<'forIphone'|'forAndroid'|null>(null)
    let [searchParams, setSearchParams] = useSearchParams();
    const redirect_param = searchParams.get('r');
    
    console.log(LocSettings.appearance)
    const containerStyles = {
        height: '100vh',
        width: '100vw',
        backgroundColor: backgroundTheme
    }
    const { setPageTitle } = useContext(HeaderTitleContext);

    let base_domain = Cookies.get(cookies_name.location_domain_name)
    if (base_domain && base_domain.trim() === '') {
        base_domain = LocSettings.location.id;
    }

    useEffect(()=>{
        const selfieVideoConstraints:MediaStreamConstraints = {
            audio:false,
            video:{
                facingMode:'user'
            }
        }
        navigator.mediaDevices.getUserMedia(selfieVideoConstraints)
        .then(function success(stream) {
            if(stream){
                console.log('Closing all streams...')
                stopAllCamStreams(stream);
                navigateTo(`${LocSettings.base_domain}/auth`)  
            }
        })
    },[]);

    const showHowToEnableCameraInstructionsHandled = () => {
        if(isIPhone()) {
            setShowEnableCameraInstructions('forIphone')
        } else {
            setShowEnableCameraInstructions('forAndroid')
        }
    }


    useEffect(() => {
        setPageTitle(intl.formatMessage({id:constants.staticPageTitle.camera_permissions}))

        if (searchParams.has("r")) {
            const redirect_param = searchParams.get("r");
            if (redirect_param == '0') {
                searchParams.delete("r");
                setSearchParams(searchParams);
            }
        } else {
            navigateTo(`${LocSettings.base_domain}/auth`)
        }
    },[])



   if(showEnableCameraInstructions === 'forIphone') {
       //return <EnableCameraInstructionsIphone />
       return <EnableCameraInstructionsAndroid />
    } else if(showEnableCameraInstructions === 'forAndroid') {
       return <EnableCameraInstructionsAndroid />
    } else {
       return (
            <div className='d-flex flex-center flex-column-fluid mt-n5'>
            <div className='w-100 mx-20px mt-20px mw-lg-500px'>
                   <div className='d-flex flex-center flex-column mt-n5'>
                       {/* begin::Logo */}
                      
                       <div className='text-center d-flex flex-column align-items-center logo w-100 mt-0'>
                            <div className='text-center d-flex flex-column'>
                            <div className="swal2-icon swal2-error swal2-icon-show mt-0 mb-0" style={{display: "flex"}}>
                                <span className="swal2-x-mark">
                                    <span className="swal2-x-mark-line-left"></span>
                                    <span className="swal2-x-mark-line-right"></span>
                                </span>
                            </div>
                            </div>
                        </div>
                       {/* end::Logo */}
                       <div className='mw-500px fs-6 form-label fw-bold w-100 card p-5'>
                           <div className='card' style={{textAlign: 'center'}}>
                               {LocSettings.location.location_name} <FormattedMessage id='camera.overlays.nofeed.requires_biometric' defaultMessage='requires biometrics to authenticate'/>. <br/><FormattedMessage id='camera.overlays.nofeed.enable_camera_to_begin' defaultMessage='Please enable
                               your camera to begin.' />
                           </div>
                           <div className='text-center mt-10 '>
                               {
                                   !isIPhone() &&
                                   <button
                                       onClick={showHowToEnableCameraInstructionsHandled}
                                       type='button'
                                       id='kt_sign_in_submit'
                                       className={`btn btn-lg btn-primary w-100 mb-5 ${dynamicBtnColor}`}
                                   >
                                       <span className='indicator-label'><FormattedMessage id='camera.overlays.nofeed.how_to_enable_camera' defaultMessage='How to enable the camera?'/></span>
                                   </button>
                               }


                               <button
                                   onClick={() => {
                                       navigateTo(`/${base_domain}`);
                                   }}
                                   type='button'
                                   id='kt_sign_in_submit'
                                   className={`btn btn-lg btn-primary w-100 ${dynamicBtnColor}`}
                               >
                                   <span className='indicator-label'><FormattedMessage id='common.return_to_home' defaultMessage='Return to home'/></span>
                               </button>
                           </div>
                       </div>
                   </div>
                   {/* end::Content */}
               </div>
           </div>
       )
    }

}
