import React, {useContext, useState, useRef, useEffect} from 'react'
import {HeaderReservation} from './HeaderReservation'
import {useLocationBasedBtnColor} from '../../../../utilities/helpers'
import moment from 'moment/moment'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {API_URL, APP_GLOBAL_ON, constants} from '../../../../config/constants'
import {AppDispatch, RootState} from '../../../../store'
import {locationHasBiometricsOn} from '../../../../common/core/BusinessLogic'
import {HeaderTitleContext} from '../../../../../_spc/layout/components/HeaderTitleContext/HeaderTitleContextStore'
import {LoadingPage} from '../../../../modules/widgets/Loaders/LoadingPage'
import {FormattedMessage, useIntl} from 'react-intl'
import {intReservationGuest, setisTempReg} from '../../../../services/store/reservations'
import QRCode from 'react-qr-code'
import {Modal} from 'react-bootstrap'
import * as ApiCallService from '../../../../services/ApiCallService'
import axios from 'axios'
import {parseRequestError} from '../../../../common/Utilities'
import {QRBadge} from '../../../profile/MyAccount'
import {useErrorAlert} from '../../../../utilities/alerts'

export const PreviewReservation = () => {
   const dispatch = useDispatch<AppDispatch>()
   const intl = useIntl()
   const navigateTo = useNavigate()
   const location_settings = useSelector((state: any) => state.LocSettings.value)
   const dynamicBtnColor = useLocationBasedBtnColor()
   const biometricsEnabled = locationHasBiometricsOn(location_settings)
   const reservation = useSelector((state: any) => state.reservation)
   const guest = useSelector((state: RootState) => state.guest)
   const reservation_data = reservation.data
   const reservation_embedded = reservation_data._embedded
   const {guests: reservation_guests} = reservation_embedded ?? []
   const base_domain = location_settings.base_domain
   const {setPageTitle} = useContext(HeaderTitleContext)
   useEffect(()=>{
      setPageTitle(intl.formatMessage({id: constants.staticPageTitle.confirmation}))
   },[])

   const qrTimerId = useRef<number>()
   const [OpenQrCode, setOpenQrCode] = useState(false)
   const [QrValue, setQrValue] = useState<string>('')
   let RefreshTokenSwitch = false
   const fireErrorAlert = useErrorAlert()
   const [ShowLoader, setShowLoader] = useState(false)

   useEffect(() => {
      return () => {
         window.clearTimeout(qrTimerId.current)
         dispatch(setisTempReg(false))
      }
   }, [])

   let currentGuest: any
   if (reservation_guests) {
      currentGuest = Object.values(reservation_guests).filter(
         (gst: any) => gst.guest_id == guest.data.id
      )[0]
   }

   if (guest.isLoading) {
      return <LoadingPage />
   }

   const submitButtonHandled = () => {
      if (biometricsEnabled) {
         navigateTo(`${base_domain}/${constants.routes.private.pre_checkin_verify_your_identity}`)
      } else {
         navigateTo(`${base_domain}/${constants.routes.private.pre_checkin_add_guests}`)
      }
   }
   const setNewQRCode = async () => {
      const queryParams = {
         global_on_id: APP_GLOBAL_ON,
         user_id: location_settings.location.owner,
      }

      let early_check_in = reservation_data?.check_in_db
      if (location_settings?.early_check_in) {
         early_check_in = moment(reservation_data?.check_in_db)
            .subtract(location_settings?.early_check_in, 'minutes')
            .format('YYYY-MM-DD HH:mm:ss')
      }

      const reservationGuests = Object.values(reservation_data._embedded.guests) as any[]
      const currentGuest = reservationGuests.find((reservationGuest) => {
         return reservationGuest.guest_id == guest.data?.id
      })

      if (!currentGuest) {
         const formattedMessage = intl.formatMessage({
            id: constants.errors.static.unexpected_contact_support,
         })
         const userInput = await fireErrorAlert(formattedMessage)
         if (userInput.isConfirmed || userInput.isDismissed) {
            window.clearInterval(qrTimerId.current)
            RefreshTokenSwitch = false
            setOpenQrCode(false)
         }
         return
      }

      let DataObj: QRBadge = {
         guest: {
            id: guest.data?.id,
            first_name: guest?.data?.first_name,
            last_name: guest?.data?.last_name,
            dob: guest?.data?.date_of_birth,
         },
         location_id: location_settings.location.id,
         reservation: {
            id: reservation_data.id,
            confirmation_number: reservation_data.confirmation_number,
            check_in_date: reservation_data.check_in_db,
            check_out_date: reservation_data.check_out_db,
            early_check_in: early_check_in,
            guest_checkin_status: currentGuest.status_checkin,
         },
      }

      if (guest.auth.biometricsLogSyncId) {
         DataObj.biometrics_sync_id = guest.auth.biometricsLogSyncId
      }

      if (
         location_settings.precheckin_required != undefined &&
         location_settings.precheckin_required == constants.status.ON
      ) {
         DataObj.reservation!.guest_pre_checkin_status = Number(currentGuest.status_pre_checkin)
      }

      try {
         const tokenResponse = await axios.post(
            `${API_URL}/generate_jwt`,
            {jwt_payload: DataObj},
            {params: queryParams}
         )
         const timeNow = moment()
         const jwtExpirationTime = moment.unix(tokenResponse.data.exp)

         if (!jwtExpirationTime.isAfter(timeNow)) {
            throw Error('Token was dead on arrival')
         } else {
            // console.log('[setNewQRCode DIFF]', jwtExpirationTime.diff(timeNow, 'seconds'))
            setQrValue(tokenResponse.data.token)
            setQrRefreshTimeout(jwtExpirationTime.diff(timeNow, 'seconds'))
            setShowLoader(false)
         }
      } catch (error) {
         const standardError = parseRequestError(error)
         const userInput = await fireErrorAlert(standardError.message)
         if (userInput.isConfirmed || userInput.isDismissed) {
            window.clearInterval(qrTimerId.current)
            RefreshTokenSwitch = false
            setOpenQrCode(false)
            setShowLoader(false)
         }
      }
   }
   const setQrRefreshTimeout = (secondsToExpire: number) => {
      qrTimerId.current = window.setTimeout(function () {
         if (RefreshTokenSwitch) {
            setNewQRCode()
         }
      }, secondsToExpire * 1000)
   }

   const GenerateProfileQR = (qrBadgeVisible: boolean) => {
      if (qrBadgeVisible) {
         RefreshTokenSwitch = true
         setShowLoader(true)
         setNewQRCode()
      } else {
         RefreshTokenSwitch = false
         window.clearTimeout(qrTimerId.current)
      }
   }
   const HideQrModal = () => {
      setOpenQrCode(false)
      RefreshTokenSwitch = false
      window.clearTimeout(qrTimerId.current)
   }

   let date_at = intl.formatMessage({id: 'dates.at'})

   return (
      <>
         <div className='d-flex justify-content-center h-100 pt-5 px-5 p-lg-20'>
            <div className='w-100' style={{maxWidth: '500px'}}>
               <HeaderReservation />

               <div className='current' data-kt-stepper-element='content'>
                  <div className='card p-5'>
                     <div className='w-100'>
                        <div className='fv-row'>
                           <div className='row'>
                              <div>
                                 {/* starts card */}
                                 <div className='card h-xl-100'>
                                    <div className='border-0 pt-5'>
                                       <h3 className='card-title align-items-start flex-column'>
                                          <span className='text-hover-primary fs-6 '>
                                             <FormattedMessage
                                                id='checkin.preview.my_reservation'
                                                defaultMessage='My Reservation'
                                             />
                                          </span>
                                       </h3>
                                       <div className='card-toolbar'></div>
                                    </div>
                                    {/* starts item */}
                                    <div className='py-5'>
                                       <div>
                                          {/* starts item */}
                                          <div
                                             className='stepper-item'
                                             data-kt-stepper-element='nav'
                                          >
                                             <div className='stepper-line w-40px'></div>
                                             <div className='d-flex flex-stack'>
                                                <div className='d-flex align-items-center flex-row-fluid flex-wrap'>
                                                   <div className='flex-grow-1 me-2'>
                                                      <span className='text-hover-primary fs-6'>
                                                         <FormattedMessage
                                                            id='checkin.preview.guest_name'
                                                            defaultMessage='Guest Name'
                                                         />
                                                      </span>
                                                   </div>
                                                   <span className='text-hover-primary fs-6'>{`${guest.data.first_name} ${guest.data.last_name}`}</span>
                                                </div>
                                             </div>
                                             <div className='separator separator-dashed my-4'></div>
                                          </div>
                                          {/* ends item */}
                                          {/* starts item */}
                                          <div
                                             className='stepper-item'
                                             data-kt-stepper-element='nav'
                                          >
                                             <div className='stepper-line w-40px'></div>
                                             <div className='d-flex flex-stack'>
                                                <div className='d-flex align-items-center flex-row-fluid flex-wrap'>
                                                   <div className='flex-grow-1 me-2'>
                                                      <span className='text-hover-primary fs-6'>
                                                         <FormattedMessage
                                                            id='checkin.preview.confirmation_no'
                                                            defaultMessage='Confirmation #'
                                                         />
                                                      </span>
                                                   </div>
                                                   <span className='text-hover-primary fs-6'>
                                                      {reservation_data.confirmation_number}
                                                   </span>
                                                </div>
                                             </div>
                                             <div className='separator separator-dashed my-4'></div>
                                          </div>
                                          {/* ends item */}
                                          {/* starts item */}
                                          <div
                                             className='stepper-item'
                                             data-kt-stepper-element='nav'
                                          >
                                             <div className='stepper-line w-40px'></div>
                                             <div className='d-flex flex-stack'>
                                                <div className='d-flex align-items-center flex-row-fluid flex-wrap'>
                                                   <div className='flex-grow-1 me-2'>
                                                      <span className='text-hover-primary fs-6'>
                                                         <FormattedMessage
                                                            id='checkin.preview.check_in'
                                                            defaultMessage='Check In'
                                                         />
                                                      </span>
                                                   </div>
                                                   <span className='text-hover-primary fs-6'>
                                                      {moment(reservation_data.check_in).format(
                                                         `ddd, MMM DD [${date_at}] LT`
                                                      )}
                                                   </span>
                                                </div>
                                             </div>
                                             <div className='separator separator-dashed my-4'></div>
                                          </div>
                                          {/* ends item */}
                                          {/* starts item */}
                                          <div
                                             className='stepper-item'
                                             data-kt-stepper-element='nav'
                                          >
                                             <div className='stepper-line w-40px'></div>
                                             <div className='d-flex flex-stack'>
                                                <div className='d-flex align-items-center flex-row-fluid flex-wrap'>
                                                   <div className='flex-grow-1 me-2'>
                                                      <span className='text-hover-primary fs-6'>
                                                         <FormattedMessage
                                                            id='common.guests'
                                                            defaultMessage='Guest(s)'
                                                         />
                                                      </span>
                                                   </div>
                                                   <span className='text-hover-primary fs-6'>
                                                      {reservation_guests?.guests_count}
                                                   </span>
                                                </div>
                                             </div>
                                          </div>
                                          {/* ends item */}
                                       </div>
                                    </div>
                                    {/* ends nav */}
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     {currentGuest?.status_checkin != 1 && currentGuest?.status_pre_checkin != 1 && (
                        <div className='w-100'>
                           <button
                              type='button'
                              onClick={submitButtonHandled}
                              className={`btn btn-lg btn-primary w-100 mb-5 ${dynamicBtnColor}`}
                           >
                              <span className='indicator-label'>
                                 <FormattedMessage id='common.confirm' defaultMessage='Confirm' />
                              </span>
                           </button>
                        </div>
                     )}
                     {(currentGuest?.status_pre_checkin != 0 ||
                        location_settings?.precheckin_required == 0) && (
                        <div className='w-100'>
                           <button
                              className={`btn btn-lg btn-primary w-100  ${dynamicBtnColor}`}
                              onClick={() => {
                                 setOpenQrCode((prevState) => {
                                    GenerateProfileQR(!prevState)
                                    return !prevState
                                 })
                              }}
                              type='button'
                           >
                              <FormattedMessage
                                 id='checkin.preview.my_reservation_badge'
                                 defaultMessage='My Reservation Badge'
                              />
                           </button>
                        </div>
                     )}
                  </div>
               </div>
            </div>
         </div>
         <ShowQrModal
            show={OpenQrCode}
            toggleModalhandler={HideQrModal}
            QrValue={QrValue}
            ShowLoader={ShowLoader}
         />
      </>
   )
}

interface ModalInteface {
   show: boolean
   toggleModalhandler: () => void
   QrValue: string
   ShowLoader: boolean
}
const ShowQrModal = (props: ModalInteface) => {
   return (
      <Modal
         show={props.show}
         aria-labelledby='contained-modal-title-vcenter'
         onHide={props.toggleModalhandler}
         centered
      >
         <Modal.Header closeButton className='mx-2'>
            <Modal.Title id='contained-modal-title-vcenter'>
               {' '}
               <FormattedMessage
                  id='checkin.preview.my_reservation_badge'
                  defaultMessage='My Reservation Badge'
               />
            </Modal.Title>
         </Modal.Header>
         <Modal.Body>
            <div className='d-flex mb-7 flex-center fv-row fv-plugins-icon-container col-12'>
               <div className='d-flex flex-column h-100 flex-xl-row p-5 p-lg-10 p-xl-15'>
                  <div className='flex-column flex-lg-row-auto w-100 w-xl-350px mb-5'>
                     <div className='card'>
                        <div className='d-flex flex-center flex-column'>
                           <div className='symbol symbol-143px bg-white p-1'>
                              {props.ShowLoader ? (
                                 <div className='spinner-border text-primary' role='status'>
                                    <span className='visually-hidden'></span>
                                 </div>
                              ) : (
                                 <QRCode
                                    size={143}
                                    value={props.QrValue}
                                    style={{height: 'auto', maxWidth: '100%', width: '100%'}}
                                    viewBox={`0 0 143 143`}
                                 />
                              )}
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </Modal.Body>
      </Modal>
   )
}
