const countries : { [key: string]: any } = {
    "PK": { "id": "1", "primary": "Pakistan", "secondary": "+92", "pn_length": "10", "flag": "🇵🇰" },
    "IN": { "id": "2", "primary": "India", "secondary": "+91", "pn_length": "10", "flag": "🇮🇳" },
    "MX": { "id": "12", "primary": "Mexico", "secondary": "+52", "pn_length": "10", "flag": "🇲🇽" },
    "CA": { "id": "13", "primary": "Canada", "secondary": "+1", "pn_length": "10", "flag": "🇨🇦" },
    "US": { "id": "3", "primary": "United States", "secondary": "+1", "pn_length": "10", "flag": "🇺🇸" },
    "CN": { "id": "4", "primary": "China", "secondary": "+86", "pn_length": "13", "flag": "🇨🇳" },
    "ES": { "id": "5", "primary": "Spain", "secondary": "+34", "pn_length": "9", "flag": "🇪🇸" },
    "JP": { "id": "6", "primary": "Japan", "secondary": "+81", "pn_length": "10", "flag": "🇯🇵" },
    "BR": { "id": "9", "primary": "Brazil", "secondary": "+55", "pn_length": "11", "flag": "🇧🇷" },
    "MA": { "id": "11", "primary": "Morocco", "secondary": "+212", "pn_length": "9", "flag": "🇲🇦" },
    "VG": { "id": "17", "primary": "British Virgin Islands", "secondary": "+1", "pn_length": "10", "flag": "🇻🇬" },
    "VI": { "id": "18", "primary": "U.S Virgin Islands", "secondary": "+1", "pn_length": "10", "flag": "🇻🇮" },
    "AE": { "id": "20", "primary": "United Arab Emirates", "secondary": "+971", "pn_length": "9", "flag": "🇦🇪" },
    "SG": { "id": "23", "primary": "Singapore", "secondary": "+65", "pn_length": "8", "flag": "🇸🇬" },
    "LB": { "id": "24", "primary": "Lebanon", "secondary": "+961", "pn_length": "7", "flag": "🇱🇧" },
    "CH": { "id": "25", "primary": "Switzerland", "secondary": "+41", "pn_length": "9", "flag": "🇨🇭" },
    "PR": { "id": "26", "primary": "Puerto Rico", "secondary": "+1", "pn_length": "10", "flag": "🇵🇷" },
    "GB": { "id": "27", "primary": "United Kingdom", "secondary": "+44", "pn_length": "10", "flag": "🇬🇧" },
    "DE": { "id": "28", "primary": "Germany", "secondary": "+49", "pn_length": "11", "flag": "🇩🇪" },
    "AU": { "id": "29", "primary": "Australia", "secondary": "+61", "pn_length": "9", "flag": "🇦🇺" },
    "AF": { "id": "30", "primary": "Afghanistan", "secondary": "+93", "pn_length": "9", "flag": "🇦🇫" },
    "AL": { "id": "31", "primary": "Albania", "secondary": "+355", "pn_length": "9", "flag": "🇦🇱" },
    "DZ": { "id": "32", "primary": "Algeria", "secondary": "+213", "pn_length": "9", "flag": "🇩🇿" },
    "AS": { "id": "33", "primary": "American Samoa", "secondary": "+1", "pn_length": "10", "flag": "🇦🇸" },
    "AD": { "id": "34", "primary": "Andorra", "secondary": "+376", "pn_length": "10", "flag": "🇦🇩" },
    "AO": { "id": "35", "primary": "Angola", "secondary": "+244", "pn_length": "9", "flag": "🇦🇴" },
    "AI": { "id": "36", "primary": "Anguilla", "secondary": "+1", "pn_length": "10", "flag": "🇦🇮" },
    "AQ": { "id": "37", "primary": "Antarctica", "secondary": "+672", "pn_length": "10", "flag": "🇦🇶" },
    "AR": { "id": "38", "primary": "Argentina", "secondary": "+54", "pn_length": "11", "flag": "🇦🇷" },
    "AG": { "id": "39", "primary": "Antigua and Barbuda", "secondary": "+1", "pn_length": "10", "flag": "🇦🇬" },
    "AM": { "id": "40", "primary": "Armenia", "secondary": "+374", "pn_length": "8", "flag": "🇦🇲" },
    "AW": { "id": "41", "primary": "Aruba", "secondary": "+297", "pn_length": "7", "flag": "🇦🇼" },
    "AT": { "id": "42", "primary": "Austria", "secondary": "+43", "pn_length": "10", "flag": "🇦🇹" },
    "AZ": { "id": "43", "primary": "Azerbaijan", "secondary": "+994", "pn_length": "9", "flag": "🇦🇿" },
    "BS": { "id": "44", "primary": "Bahamas", "secondary": "+1", "pn_length": "10", "flag": "🇧🇸" },
    "BH": { "id": "45", "primary": "Bahrain", "secondary": "+973", "pn_length": "8", "flag": "🇧🇭" },
    "BD": { "id": "46", "primary": "Bangladesh", "secondary": "+880", "pn_length": "10", "flag": "🇧🇩" },
    "BB": { "id": "47", "primary": "Barbados", "secondary": "+1", "pn_length": "10", "flag": "🇧🇧" },
    "BY": { "id": "48", "primary": "Belarus", "secondary": "+375", "pn_length": "9", "flag": "🇧🇾" },
    "BE": { "id": "49", "primary": "Belgium", "secondary": "+32", "pn_length": "9", "flag": "🇧🇪" },
    "BZ": { "id": "50", "primary": "Belize", "secondary": "+501", "pn_length": "7", "flag": "🇧🇿" },
    "BJ": { "id": "51", "primary": "Benin", "secondary": "+229", "pn_length": "9", "flag": "🇧🇯" },
    "BM": { "id": "52", "primary": "Bermuda", "secondary": "+1", "pn_length": "10", "flag": "🇧🇲" },
    "BT": { "id": "53", "primary": "Bhutan", "secondary": "+975", "pn_length": "8", "flag": "🇧🇹" },
    "BO": { "id": "54", "primary": "Bolivia", "secondary": "+591", "pn_length": "8", "flag": "🇧🇴" },
    "BA": { "id": "55", "primary": "Bosnia and Herzegovina", "secondary": "+387", "pn_length": "8", "flag": "🇧🇦" },
    "BW": { "id": "56", "primary": "Botswana", "secondary": "+267", "pn_length": "8", "flag": "🇧🇼" },
    "BV": { "id": "57", "primary": "Bouvet Island", "secondary": "+47", "pn_length": "10", "flag": "🇧🇻" },
    "IO": { "id": "58", "primary": "British Indian Ocean Territory", "secondary": "+246", "pn_length": "7", "flag": "🇮🇴" },  
    "BN": { "id": "59", "primary": "Brunei Darussalam", "secondary": "+673", "pn_length": "7", "flag": "🇧🇳" },
    "BG": { "id": "60", "primary": "Bulgaria", "secondary": "+359", "pn_length": "9", "flag": "🇧🇬" },
    "BF": { "id": "61", "primary": "Burkina Faso", "secondary": "+226", "pn_length": "8", "flag": "🇧🇫" },
    "BI": { "id": "62", "primary": "Burundi", "secondary": "+257", "pn_length": "8", "flag": "🇧🇮" },
    "KH": { "id": "63", "primary": "Cambodia", "secondary": "+855", "pn_length": "9", "flag": "🇰🇭" },
    "CM": { "id": "64", "primary": "Cameroon", "secondary": "+237", "pn_length": "9", "flag": "🇨🇲" },
    "CV": { "id": "65", "primary": "Cape Verde", "secondary": "+238", "pn_length": "7", "flag": "🇨🇻" },
    "KY": { "id": "66", "primary": "Cayman Islands", "secondary": "+1", "pn_length": "10", "flag": "🇰🇾" },
    "CF": { "id": "67", "primary": "Central African Republic", "secondary": "+236", "pn_length": "8", "flag": "🇨🇫" },
    "TD": { "id": "68", "primary": "Chad", "secondary": "+235", "pn_length": "8", "flag": "🇹🇩" },
    "CL": { "id": "69", "primary": "Chile", "secondary": "+56", "pn_length": "9", "flag": "🇨🇱" },
    "CX": { "id": "70", "primary": "Christmas Island", "secondary": "+61", "pn_length": "10", "flag": "🇨🇽" },
    "CC": { "id": "71", "primary": "Cocos (Keeling) Islands", "secondary": "+61", "pn_length": "10", "flag": "🇨🇨" },
    "CO": { "id": "72", "primary": "Colombia", "secondary": "+57", "pn_length": "10", "flag": "🇨🇴" },
    "KM": { "id": "73", "primary": "Comoros", "secondary": "+269", "pn_length": "7", "flag": "🇰🇲" },
    "CG": { "id": "74", "primary": "Congo (Republic)", "secondary": "+242", "pn_length": "7", "flag": "🇨🇬" },
    "CK": { "id": "75", "primary": "Cook Islands", "secondary": "+682", "pn_length": "5", "flag": "🇨🇰" },
    "CR": { "id": "76", "primary": "Costa Rica", "secondary": "+506", "pn_length": "8", "flag": "🇨🇷" },
    "CI": { "id": "77", "primary": "Côte d'Ivoire", "secondary": "+225", "pn_length": "10", "flag": "🇨🇮" },
    "HR": { "id": "78", "primary": "Croatia", "secondary": "+385", "pn_length": "9", "flag": "🇭🇷" },
    "CU": { "id": "79", "primary": "Cuba", "secondary": "+53", "pn_length": "8", "flag": "🇨🇺" },
    "CY": { "id": "80", "primary": "Cyprus", "secondary": "+357", "pn_length": "8", "flag": "🇨🇾" },
    "CZ": { "id": "81", "primary": "Czech Republic", "secondary": "+420", "pn_length": "9", "flag": "🇨🇿" },
    "DK": { "id": "82", "primary": "Denmark", "secondary": "+45", "pn_length": "8", "flag": "🇩🇰" },
    "DJ": { "id": "83", "primary": "Djibouti", "secondary": "+253", "pn_length": "6", "flag": "🇩🇯" },
    "DM": { "id": "84", "primary": "Dominica", "secondary": "+1", "pn_length": "8", "flag": "🇩🇲" },
    "DO": { "id": "85", "primary": "Dominican Republic", "secondary": "+1", "pn_length": "10", "flag": "🇩🇴" },
    "TP": { "id": "86", "primary": "Timor-Leste", "secondary": "+670", "pn_length": "7", "flag": "🇹🇱" },
    "EC": { "id": "87", "primary": "Ecuador", "secondary": "+593", "pn_length": "9", "flag": "🇪🇨" },
    "EG": { "id": "88", "primary": "Egypt", "secondary": "+20", "pn_length": "10", "flag": "🇪🇬" },
    "SV": { "id": "89", "primary": "El Salvador", "secondary": "+503", "pn_length": "8", "flag": "🇸🇻" },
    "GQ": { "id": "90", "primary": "Equatorial Guinea", "secondary": "+240", "pn_length": "6", "flag": "🇬🇶" },
    "ER": { "id": "91", "primary": "Eritrea", "secondary": "+291", "pn_length": "7", "flag": "🇪🇷" },
    "EE": { "id": "92", "primary": "Estonia", "secondary": "+372", "pn_length": "8", "flag": "🇪🇪" },
    "ET": { "id": "93", "primary": "Ethiopia", "secondary": "+251", "pn_length": "9", "flag": "🇪🇹" },
    "FK": { "id": "94", "primary": "Falkland Islands (Malvinas)", "secondary": "+500", "pn_length": "5", "flag": "🇫🇰" },
    "FO": { "id": "95", "primary": "Faroe Islands", "secondary": "+298", "pn_length": "5", "flag": "🇫🇴" },
    "FJ": { "id": "96", "primary": "Fiji", "secondary": "+679", "pn_length": "7", "flag": "🇫🇯" },
    "FI": { "id": "97", "primary": "Finland", "secondary": "+358", "pn_length": "12", "flag": "🇫🇮" },
    "FR": { "id": "98", "primary": "France", "secondary": "+33", "pn_length": "9", "flag": "🇫🇷" },
    "GF": { "id": "100", "primary": "French Guiana", "secondary": "+594", "pn_length": "9", "flag": "🇬🇫" },
    "PF": { "id": "101", "primary": "French Polynesia", "secondary": "+689", "pn_length": "6", "flag": "🇵🇫" },
    "GA": { "id": "103", "primary": "Gabon", "secondary": "+241", "pn_length": "7", "flag": "🇬🇦" },
    "GE": { "id": "104", "primary": "Georgia", "secondary": "+995", "pn_length": "9", "flag": "🇬🇪" },
    "GH": { "id": "105", "primary": "Ghana", "secondary": "+233", "pn_length": "9", "flag": "🇬🇭" },
    "GI": { "id": "106", "primary": "Gibraltar", "secondary": "+350", "pn_length": "8", "flag": "🇬🇮" },
    "GR": { "id": "107", "primary": "Greece", "secondary": "+30", "pn_length": "10", "flag": "🇬🇷" },
    "GL": { "id": "108", "primary": "Greenland", "secondary": "+299", "pn_length": "6", "flag": "🇬🇱" },
    "GD": { "id": "109", "primary": "Grenada", "secondary": "+1", "pn_length": "10", "flag": "🇬🇩" },
    "GP": { "id": "110", "primary": "Guadeloupe", "secondary": "+590", "pn_length": "9", "flag": "🇬🇵" },
    "GU": { "id": "111", "primary": "Guam", "secondary": "+1", "pn_length": "10", "flag": "🇬🇺" },
    "GT": { "id": "112", "primary": "Guatemala", "secondary": "+502", "pn_length": "8", "flag": "🇬🇹" },
    "GN": { "id": "113", "primary": "Guinea", "secondary": "+224", "pn_length": "8", "flag": "🇬🇳" },
    "GW": { "id": "114", "primary": "Guinea-Bissau", "secondary": "+245", "pn_length": "7", "flag": "🇬🇼" },
    "GY": { "id": "115", "primary": "Guyana", "secondary": "+592", "pn_length": "7", "flag": "🇬🇾" },
    "HT": { "id": "116", "primary": "Haiti", "secondary": "+509", "pn_length": "8", "flag": "🇭🇹" },
    "HN": { "id": "118", "primary": "Honduras", "secondary": "+504", "pn_length": "8", "flag": "🇭🇳" },
    "HK": { "id": "119", "primary": "Hong Kong", "secondary": "+852", "pn_length": "8", "flag": "🇭🇰" },
    "HU": { "id": "120", "primary": "Hungary", "secondary": "+36", "pn_length": "9", "flag": "🇭🇺" },
    "IS": { "id": "121", "primary": "Iceland", "secondary": "+354", "pn_length": "9", "flag": "🇮🇸" },
    "ID": { "id": "122", "primary": "Indonesia", "secondary": "+62", "pn_length": "9", "flag": "🇮🇩" },
    "IR": { "id": "123", "primary": "Iran", "secondary": "+98", "pn_length": "10", "flag": "🇮🇷" },
    "IQ": { "id": "124", "primary": "Iraq", "secondary": "+964", "pn_length": "10", "flag": "🇮🇶" },
    "IE": { "id": "125", "primary": "Ireland", "secondary": "+353", "pn_length": "9", "flag": "🇮🇪" },
    "IL": { "id": "126", "primary": "Israel", "secondary": "+972", "pn_length": "9", "flag": "🇮🇱" },
    "IT": { "id": "127", "primary": "Italy", "secondary": "+39", "pn_length": "10", "flag": "🇮🇹" },
    "JM": { "id": "128", "primary": "Jamaica", "secondary": "+1", "pn_length": "10", "flag": "🇯🇲" },
    "JO": { "id": "129", "primary": "Jordan", "secondary": "+962", "pn_length": "9", "flag": "🇯🇴" },
    "KZ": { "id": "130", "primary": "Kazakhstan", "secondary": "+7", "pn_length": "10", "flag": "🇰🇿" },
    "KE": { "id": "131", "primary": "Kenya", "secondary": "+254", "pn_length": "10", "flag": "🇰🇪" },
    "KI": { "id": "132", "primary": "Kiribati", "secondary": "+686", "pn_length": "8", "flag": "🇰🇮" },
    "KP": { "id": "133", "primary": "North Korea", "secondary": "+850", "pn_length": "10", "flag": "🇰🇵" },
    "KR": { "id": "134", "primary": "South Korea", "secondary": "+82", "pn_length": "10", "flag": "🇰🇷" },
    "KW": { "id": "135", "primary": "Kuwait", "secondary": "+965", "pn_length": "8", "flag": "🇰🇼" },
    "KG": { "id": "136", "primary": "Kyrgyzstan", "secondary": "+996", "pn_length": "9", "flag": "🇰🇬" },
    "LA": { "id": "137", "primary": "Laos", "secondary": "+856", "pn_length": "11", "flag": "🇱🇦" },
    "LV": { "id": "138", "primary": "Latvia", "secondary": "+371", "pn_length": "8", "flag": "🇱🇻" },
    "LS": { "id": "140", "primary": "Lesotho", "secondary": "+266", "pn_length": "8", "flag": "🇱🇸" },
    "LR": { "id": "141", "primary": "Liberia", "secondary": "+231", "pn_length": "7", "flag": "🇱🇷" },
    "LY": { "id": "142", "primary": "Libya", "secondary": "+218", "pn_length": "10", "flag": "🇱🇾" },
    "LI": { "id": "143", "primary": "Liechtenstein", "secondary": "+423", "pn_length": "9", "flag": "🇱🇮" },
    "LT": { "id": "144", "primary": "Lithuania", "secondary": "+370", "pn_length": "8", "flag": "🇱🇹" },
    "LU": { "id": "145", "primary": "Luxembourg", "secondary": "+352", "pn_length": "9", "flag": "🇱🇺" },
    "MO": { "id": "146", "primary": "Macao", "secondary": "+853", "pn_length": "8", "flag": "🇲🇴" },
    "MK": { "id": "147", "primary": "Macedonia", "secondary": "+389", "pn_length": "8", "flag": "🇲🇰" },
    "MG": { "id": "148", "primary": "Madagascar", "secondary": "+261", "pn_length": "10", "flag": "🇲🇬" },
    "MW": { "id": "149", "primary": "Malawi", "secondary": "+265", "pn_length": "8", "flag": "🇲🇼" },
    "MY": { "id": "150", "primary": "Malaysia", "secondary": "+60", "pn_length": "7", "flag": "🇲🇾" },
    "MV": { "id": "151", "primary": "Maldives", "secondary": "+960", "pn_length": "7", "flag": "🇲🇻" },
    "ML": { "id": "152", "primary": "Mali", "secondary": "+223", "pn_length": "8", "flag": "🇲🇱" },
    "MT": { "id": "153", "primary": "Malta", "secondary": "+356", "pn_length": "8", "flag": "🇲🇹" },
    "MH": { "id": "154", "primary": "Marshall Islands", "secondary": "+692", "pn_length": "7", "flag": "🇲🇭" },
    "MQ": { "id": "155", "primary": "Martinique", "secondary": "+596", "pn_length": "9", "flag": "🇲🇶" },
    "MR": { "id": "156", "primary": "Mauritania", "secondary": "+222", "pn_length": "7", "flag": "🇲🇷" },
    "MU": { "id": "157", "primary": "Mauritius", "secondary": "+230", "pn_length": "8", "flag": "🇲🇺" },
    "YT": { "id": "158", "primary": "Mayotte", "secondary": "+262", "pn_length": "10", "flag": "🇾🇹" },
    "FM": { "id": "159", "primary": "Micronesia", "secondary": "+691", "pn_length": "7", "flag": "🇫🇲" },
    "MD": { "id": "160", "primary": "Moldova", "secondary": "+373", "pn_length": "8", "flag": "🇲🇩" },
    "MC": { "id": "161", "primary": "Monaco", "secondary": "+377", "pn_length": "9", "flag": "🇲🇨" },
    "MN": { "id": "162", "primary": "Mongolia", "secondary": "+976", "pn_length": "8", "flag": "🇲🇳" },
    "MS": { "id": "163", "primary": "Montserrat", "secondary": "+1", "pn_length": "10", "flag": "🇲🇸" },
    "MZ": { "id": "164", "primary": "Mozambique", "secondary": "+258", "pn_length": "12", "flag": "🇲🇿" },
    "MM": { "id": "165", "primary": "Myanmar", "secondary": "+95", "pn_length": "8", "flag": "🇲🇲" },
    "NA": { "id": "166", "primary": "Namibia", "secondary": "+264", "pn_length": "10", "flag": "🇳🇦" },
    "NR": { "id": "167", "primary": "Nauru", "secondary": "+674", "pn_length": "7", "flag": "🇳🇷" },
    "NP": { "id": "168", "primary": "Nepal", "secondary": "+977", "pn_length": "10", "flag": "🇳🇵" },
    "NL": { "id": "169", "primary": "Netherlands", "secondary": "+31", "pn_length": "9", "flag": "🇳🇱" },
    "NC": { "id": "171", "primary": "New Caledonia", "secondary": "+687", "pn_length": "6", "flag": "🇳🇨" },
    "NZ": { "id": "172", "primary": "New Zealand", "secondary": "+64", "pn_length": "9", "flag": "🇳🇿" },
    "NI": { "id": "173", "primary": "Nicaragua", "secondary": "+505", "pn_length": "8", "flag": "🇳🇮" },
    "NE": { "id": "174", "primary": "Niger", "secondary": "+227", "pn_length": "8", "flag": "🇳🇪" },
    "NG": { "id": "175", "primary": "Nigeria", "secondary": "+234", "pn_length": "8", "flag": "🇳🇬" },
    "NU": { "id": "176", "primary": "Niue", "secondary": "+683", "pn_length": "4", "flag": "🇳🇺" },
    "NF": { "id": "177", "primary": "Norfolk Island", "secondary": "+672", "pn_length": "6", "flag": "🇳🇫" },
    "MP": { "id": "178", "primary": "Northern Mariana Islands", "secondary": "+1", "pn_length": "10", "flag": "🇲🇵" },
    "NO": { "id": "179", "primary": "Norway", "secondary": "+47", "pn_length": "8", "flag": "🇳🇴" },
    "OM": { "id": "180", "primary": "Oman", "secondary": "+968", "pn_length": "8", "flag": "🇴🇲" },
    "PW": { "id": "181", "primary": "Palau", "secondary": "+680", "pn_length": "7", "flag": "🇵🇼" },
    "PA": { "id": "182", "primary": "Panama", "secondary": "+507", "pn_length": "8", "flag": "🇵🇦" },
    "PG": { "id": "183", "primary": "Papua New Guinea", "secondary": "+675", "pn_length": "11", "flag": "🇵🇬" },
    "PY": { "id": "184", "primary": "Paraguay", "secondary": "+595", "pn_length": "9", "flag": "🇵🇾" },
    "PE": { "id": "185", "primary": "Peru", "secondary": "+51", "pn_length": "9", "flag": "🇵🇪" },
    "PH": { "id": "186", "primary": "Philippines", "secondary": "+63", "pn_length": "10", "flag": "🇵🇭" },
    "PN": { "id": "187", "primary": "Pitcairn", "secondary": "+64", "pn_length": "9", "flag": "🇵🇳" },
    "PL": { "id": "188", "primary": "Poland", "secondary": "+48", "pn_length": "9", "flag": "🇵🇱" },
    "PT": { "id": "189", "primary": "Portugal", "secondary": "+351", "pn_length": "9", "flag": "🇵🇹" },
    "QA": { "id": "190", "primary": "Qatar", "secondary": "+974", "pn_length": "8", "flag": "🇶🇦" },
    "RE": { "id": "191", "primary": "Reunion", "secondary": "+262", "pn_length": "10", "flag": "🇷🇪" },
    "RO": { "id": "192", "primary": "Romania", "secondary": "+40", "pn_length": "10", "flag": "🇷🇴" },
    "RU": { "id": "193", "primary": "Russia", "secondary": "+7", "pn_length": "10", "flag": "🇷🇺" },
    "RW": { "id": "194", "primary": "Rwanda", "secondary": "+250", "pn_length": "9", "flag": "🇷🇼" },
    "KN": { "id": "195", "primary": "Saint Kitts and Nevis", "secondary": "+1", "pn_length": "10", "flag": "🇰🇳" },
    "LC": { "id": "196", "primary": "Saint Lucia", "secondary": "+1", "pn_length": "10", "flag": "🇱🇨" },
    "VC": { "id": "197", "primary": "Saint Vincent and The Grenadines", "secondary": "+1", "pn_length": "10", "flag": "🇻🇨" },
    "WS": { "id": "198", "primary": "Samoa", "secondary": "+685", "pn_length": "10", "flag": "🇼🇸" },
    "SM": { "id": "199", "primary": "San Marino", "secondary": "+378", "pn_length": "10", "flag": "🇸🇲" },
    "ST": { "id": "200", "primary": "Sao Tome and Principe", "secondary": "+239", "pn_length": "7", "flag": "🇸🇹" },
    "SA": { "id": "201", "primary": "Saudi Arabia", "secondary": "+966", "pn_length": "9", "flag": "🇸🇦" },
    "SN": { "id": "202", "primary": "Senegal", "secondary": "+221", "pn_length": "9", "flag": "🇸🇳" },
    "SC": { "id": "203", "primary": "Seychelles", "secondary": "+248", "pn_length": "6", "flag": "🇸🇨" },
    "SL": { "id": "204", "primary": "Sierra Leone", "secondary": "+232", "pn_length": "8", "flag": "🇸🇱" },
    "SK": { "id": "205", "primary": "Slovakia", "secondary": "+421", "pn_length": "9", "flag": "🇸🇰" },
    "SI": { "id": "206", "primary": "Slovenia", "secondary": "+386", "pn_length": "8", "flag": "🇸🇮" },
    "SB": { "id": "207", "primary": "Solomon Islands", "secondary": "+677", "pn_length": "7", "flag": "🇸🇧" },
    "SO": { "id": "208", "primary": "Somalia", "secondary": "+252", "pn_length": "8", "flag": "🇸🇴" },
    "ZA": { "id": "209", "primary": "South Africa", "secondary": "+27", "pn_length": "9", "flag": "🇿🇦" },
    "LK": { "id": "211", "primary": "Sri Lanka", "secondary": "+94", "pn_length": "7", "flag": "🇱🇰" },
    "SH": { "id": "212", "primary": "Saint Helena", "secondary": "+290", "pn_length": "4", "flag": "🇸🇭" },
    "SD": { "id": "214", "primary": "Sudan", "secondary": "+249", "pn_length": "9", "flag": "🇸🇩" },
    "SR": { "id": "215", "primary": "Suriname", "secondary": "+597", "pn_length": "7", "flag": "🇸🇷" },
    "SZ": { "id": "216", "primary": "Swaziland", "secondary": "+268", "pn_length": "8", "flag": "🇸🇿" },
    "SE": { "id": "217", "primary": "Sweden", "secondary": "+46", "pn_length": "7", "flag": "🇸🇪" },
    "SY": { "id": "218", "primary": "Syria", "secondary": "+963", "pn_length": "9", "flag": "🇸🇾" },
    "TW": { "id": "219", "primary": "Taiwan", "secondary": "+886", "pn_length": "9", "flag": "🇹🇼" },
    "TJ": { "id": "220", "primary": "Tajikistan", "secondary": "+992", "pn_length": "9", "flag": "🇹🇯" },
    "TZ": { "id": "221", "primary": "Tanzania", "secondary": "+255", "pn_length": "6", "flag": "🇹🇿" },
    "TH": { "id": "222", "primary": "Thailand", "secondary": "+66", "pn_length": "9", "flag": "🇹🇭" },
    "TG": { "id": "223", "primary": "Togo", "secondary": "+228", "pn_length": "8", "flag": "🇹🇬" },
    "TK": { "id": "224", "primary": "Tokelau", "secondary": "+690", "pn_length": "4", "flag": "🇹🇰" },
    "TO": { "id": "225", "primary": "Tonga", "secondary": "+676", "pn_length": "7", "flag": "🇹🇴" },
    "TT": { "id": "226", "primary": "Trinidad and Tobago", "secondary": "+1", "pn_length": "10", "flag": "🇹🇹" },
    "TN": { "id": "227", "primary": "Tunisia", "secondary": "+216", "pn_length": "8", "flag": "🇹🇳" },
    "TR": { "id": "228", "primary": "Turkey", "secondary": "+90", "pn_length": "10", "flag": "🇹🇷" },
    "TM": { "id": "229", "primary": "Turkmenistan", "secondary": "+993", "pn_length": "8", "flag": "🇹🇲" },
    "TC": { "id": "230", "primary": "Turks and Caicos Islands", "secondary": "+1", "pn_length": "10", "flag": "🇹🇨" },
    "TV": { "id": "231", "primary": "Tuvalu", "secondary": "+688", "pn_length": "6", "flag": "🇹🇻" },
    "UG": { "id": "232", "primary": "Uganda", "secondary": "+256", "pn_length": "9", "flag": "🇺🇬" },
    "UA": { "id": "233", "primary": "Ukraine", "secondary": "+380", "pn_length": "9", "flag": "🇺🇦" },
    "UY": { "id": "234", "primary": "Uruguay", "secondary": "+598", "pn_length": "11", "flag": "🇺🇾" },
    "UZ": { "id": "235", "primary": "Uzbekistan", "secondary": "+998", "pn_length": "9", "flag": "🇺🇿" },
    "VU": { "id": "236", "primary": "Vanuatu", "secondary": "+678", "pn_length": "7", "flag": "🇻🇺" },
    "VE": { "id": "237", "primary": "Venezuela", "secondary": "+58", "pn_length": "7", "flag": "🇻🇪" },
    "VN": { "id": "238", "primary": "Vietnam", "secondary": "+84", "pn_length": "9", "flag": "🇻🇳" },
    "WF": { "id": "239", "primary": "Wallis and Futuna", "secondary": "+681", "pn_length": "6", "flag": "🇼🇫" },
    "EH": { "id": "240", "primary": "Western Sahara", "secondary": "+212", "pn_length": "10", "flag": "🇪🇭" },
    "YE": { "id": "241", "primary": "Yemen", "secondary": "+967", "pn_length": "9", "flag": "🇾🇪" },
    "ZM": { "id": "244", "primary": "Zambia", "secondary": "+260", "pn_length": "9", "flag": "🇿🇲" },
    "ZW": { "id": "245", "primary": "Zimbabwe", "secondary": "+263", "pn_length": "9", "flag": "🇿🇼" },
    "CD": { "id": "250", "primary": "Congo (DRC)", "secondary": "+243", "pn_length": "9", "flag": "🇨🇩" },
    "GM": { "id": "252", "primary": "Gambia", "secondary": "+220", "pn_length": "7", "flag": "🇬🇲" },
    "PS": { "id": "266", "primary": "Palestinian Territory, Occupied", "secondary": "+970", "pn_length": "10", "flag": "🇵🇸" },
    "PM": { "id": "268", "primary": "Saint Pierre and Miquelon", "secondary": "+508", "pn_length": "6", "flag": "🇵🇲" },
    "CS": { "id": "270", "primary": "Serbia and Montenegro", "secondary": "+381", "pn_length": "8", "flag": "🇷🇸" },
    "SJ": { "id": "274", "primary": "Svalbard and Jan Mayen", "secondary": "+47", "pn_length": "10", "flag": "🇸🇯" },
    "TB": { "id": "284", "primary": "Saint Barthélemy", "secondary": "+590", "pn_length": "10", "flag": "🇧🇱" },
    "CW": { "id": "286", "primary": "Curacao", "secondary": "+599", "pn_length": "10", "flag": "🇨🇼" },
    "GK": { "id": "287", "primary": "Guernsey", "secondary": "+44", "pn_length": "10", "flag": "🇬🇬" },
    "IM": { "id": "288", "primary": "Isle of Man", "secondary": "+44", "pn_length": "10", "flag": "🇮🇲" },
    "JE": { "id": "289", "primary": "Jersey", "secondary": "+44", "pn_length": "10", "flag": "🇯🇪" },
    "MJ": { "id": "290", "primary": "Montenegro", "secondary": "+382", "pn_length": "8", "flag": "🇲🇪" },
    "RN": { "id": "291", "primary": "Saint Martin", "secondary": "+590", "pn_length": "10", "flag": "🇲🇫" },
    "RI": { "id": "292", "primary": "Serbia", "secondary": "+381", "pn_length": "8", "flag": "🇷🇸" },
    "OD": { "id": "293", "primary": "South Sudan", "secondary": "+211", "pn_length": "9", "flag": "🇸🇸" },
    "NN": { "id": "294", "primary": "Sint Maarten", "secondary": "+1", "pn_length": "10", "flag": "🇸🇽" },
    "KV": { "id": "296", "primary": "Kosovo", "secondary": "+381", "pn_length": "10", "flag": "🇽🇰" }
};

const baseCountries : { [key: string]: any } =  {
1: { id:"PK"},
2: { id:"IN"},
3: { id:"US"},
4: { id:"CN"},
5: { id:"ES"},
6: { id:"JP"},
9: { id:"BR"},
11: { id:"MA"},
12: { id:"MX"},
13: { id:"CA"},
17: { id:"VG"},
18: { id:"VI"},
20: { id:"AE"},
23: { id:"SG"},
24: { id:"LB"},
25: { id:"CH"},
26: { id:"PR"},
27: { id:"GB"},
28: { id:"DE"},
29: { id:"AU"},
30: { id:"AF"},
31: { id:"AL"},
32: { id:"DZ"},
33: { id:"AS"},
34: { id:"AD"},
35: { id:"AO"},
36: { id:"AI"},
37: { id:"AQ"},
38: { id:"AR"},
39: { id:"AG"},
40: { id:"AM"},
41: { id:"AW"},
42: { id:"AT"},
43: { id:"AZ"},
44: { id:"BS"},
45: { id:"BH"},
46: { id:"BD"},
47: { id:"BB"},
48: { id:"BY"},
49: { id:"BE"},
50: { id:"BZ"},
51: { id:"BJ"},
52: { id:"BM"},
53: { id:"BT"},
54: { id:"BO"},
55: { id:"BA"},
56: { id:"BW"},
57: { id:"BV"},
58: { id:"IO"},
59: { id:"BN"},
60: { id:"BG"},
61: { id:"BF"},
62: { id:"BI"},
63: { id:"KH"},
64: { id:"CM"},
65: { id:"CV"},
66: { id:"KY"},
67: { id:"CF"},
68: { id:"TD"},
69: { id:"CL"},
70: { id:"CX"},
71: { id:"CC"},
72: { id:"CO"},
73: { id:"KM"},
74: { id:"CG"},
75: { id:"CK"},
76: { id:"CR"},
77: { id:"CI"},
78: { id:"HR"},
79: { id:"CU"},
80: { id:"CY"},
81: { id:"CZ"},
82: { id:"DK"},
83: { id:"DJ"},
84: { id:"DM"},
85: { id:"DO"},
86: { id:"TP"},
87: { id:"EC"},
88: { id:"EG"},
89: { id:"SV"},
90: { id:"GQ"},
91: { id:"ER"},
92: { id:"EE"},
93: { id:"ET"},
94: { id:"FK"},
95: { id:"FO"},
96: { id:"FJ"},
97: { id:"FI"},
98: { id:"FR"},
100: { id:"GF"},
101: { id:"PF"},
103: { id:"GA"},
104: { id:"GE"},
105: { id:"GH"},
106: { id:"GI"},
107: { id:"GR"},
108: { id:"GL"},
109: { id:"GD"},
110: { id:"GP"},
111: { id:"GU"},
112: { id:"GT"},
113: { id:"GN"},
114: { id:"GW"},
115: { id:"GY"},
116: { id:"HT"},
118: { id:"HN"},
119: { id:"HK"},
120: { id:"HU"},
121: { id:"IS"},
122: { id:"ID"},
123: { id:"IR"},
124: { id:"IQ"},
125: { id:"IE"},
126: { id:"IL"},
127: { id:"IT"},
128: { id:"JM"},
129: { id:"JO"},
130: { id:"KZ"},
131: { id:"KE"},
132: { id:"KI"},
133: { id:"KP"},
134: { id:"KR"},
135: { id:"KW"},
136: { id:"KG"},
137: { id:"LA"},
138: { id:"LV"},
140: { id:"LS"},
141: { id:"LR"},
142: { id:"LY"},
143: { id:"LI"},
144: { id:"LT"},
145: { id:"LU"},
146: { id:"MO"},
147: { id:"MK"},
148: { id:"MG"},
149: { id:"MW"},
150: { id:"MY"},
151: { id:"MV"},
152: { id:"ML"},
153: { id:"MT"},
154: { id:"MH"},
155: { id:"MQ"},
156: { id:"MR"},
157: { id:"MU"},
158: { id:"YT"},
159: { id:"FM"},
160: { id:"MD"},
161: { id:"MC"},
162: { id:"MN"},
163: { id:"MS"},
164: { id:"MZ"},
165: { id:"MM"},
166: { id:"NA"},
167: { id:"NR"},
168: { id:"NP"},
169: { id:"NL"},
171: { id:"NC"},
172: { id:"NZ"},
173: { id:"NI"},
174: { id:"NE"},
175: { id:"NG"},
176: { id:"NU"},
177: { id:"NF"},
178: { id:"MP"},
179: { id:"NO"},
180: { id:"OM"},
181: { id:"PW"},
182: { id:"PA"},
183: { id:"PG"},
184: { id:"PY"},
185: { id:"PE"},
186: { id:"PH"},
187: { id:"PN"},
188: { id:"PL"},
189: { id:"PT"},
190: { id:"QA"},
191: { id:"RE"},
192: { id:"RO"},
193: { id:"RU"},
194: { id:"RW"},
195: { id:"KN"},
196: { id:"LC"},
197: { id:"VC"},
198: { id:"WS"},
199: { id:"SM"},
200: { id:"ST"},
201: { id:"SA"},
202: { id:"SN"},
203: { id:"SC"},
204: { id:"SL"},
205: { id:"SK"},
206: { id:"SI"},
207: { id:"SB"},
208: { id:"SO"},
209: { id:"ZA"},
211: { id:"LK"},
212: { id:"SH"},
214: { id:"SD"},
215: { id:"SR"},
216: { id:"SZ"},
217: { id:"SE"},
218: { id:"SY"},
219: { id:"TW"},
220: { id:"TJ"},
221: { id:"TZ"},
222: { id:"TH"},
223: { id:"TG"},
224: { id:"TK"},
225: { id:"TO"},
226: { id:"TT"},
227: { id:"TN"},
228: { id:"TR"},
229: { id:"TM"},
230: { id:"TC"},
231: { id:"TV"},
232: { id:"UG"},
233: { id:"UA"},
234: { id:"UY"},
235: { id:"UZ"},
236: { id:"VU"},
237: { id:"VE"},
238: { id:"VN"},
239: { id:"WF"},
240: { id:"EH"},
241: { id:"YE"},
244: { id:"ZM"},
245: { id:"ZW"},
250: { id:"CD"},
252: { id:"GM"},
266: { id:"PS"},
268: { id:"PM"},
270: { id:"CS"},
274: { id:"SJ"},
284: { id:"TB"},
286: { id:"CW"},
287: { id:"GK"},
288: { id:"IM"},
289: { id:"JE"},
290: { id:"MJ"},
291: { id:"RN"},
292: { id:"RI"},
293: { id:"OD"},
294: { id:"NN"},
296: { id:"KV"},
}
export {countries,baseCountries}
