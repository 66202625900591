import {setPaymentData,setLoading,setError,setUrlData} from "./paymentSlice";
import * as ApiCallService from "../../../ApiCallService";

export const getPayments = (payment_id: number, LocSettings: any) => {
    return async (dispatch: (arg0: { payload: any; type: string; }) => void, state: any) => {
        dispatch(setLoading(true))
        ApiCallService.ApiCall({with: 'guests'}, `payments/${payment_id}`, 'GET', LocSettings)
            .then((res: any) => {
                if(res?.error?.message != undefined) {
                    dispatch( setError(res.error) )
                } else {
                    dispatch( setPaymentData(res.data) )
                }
        }).catch((err) => {
            dispatch( setPaymentData({}) )
        });
    }
}
export const getUrlData = (short_code: string | undefined,LocSettings: any) => {
    return async (dispatch: (arg0: { payload: any; type: string; }) => void, state: any) => {
        dispatch(setLoading(true))

        ApiCallService.ApiCall({}, `url_data/${short_code}`, 'GET', LocSettings,true)
            .then((res: any) => {
                if(res?.error?.message != undefined) {
                    dispatch( setUrlData({}) )
                } else {
                    dispatch( setUrlData(res.data) )
                }
        }).catch((err) => {
            dispatch( setUrlData({}) )
        });
    }
}
