/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useRef, useState} from 'react'
import clsx from 'clsx'
import {useLocation, useNavigate} from 'react-router-dom'
import {useLayout} from '../../core'
import Inputmask from 'inputmask'
import {RootState, AppDispatch} from '../../../../app/store'
import {useLocationBasedBtnColor} from '../../../../app/utilities/helpers'
import {useDispatch, useSelector} from 'react-redux'
import {removeItemFromCart} from '../../../../app/services/store/ordering/cart'
import {setCartItemIdToUpdate} from '../../../../app/services/store/ordering/cart/updateCartItemSlice'
import StaticRoutePath from '../../../../app/routing/StaticRoutePath'
import {useAppAlerts, useErrorAlert, useWarningConfirmAlert} from '../../../../app/utilities/alerts'
import {Field} from 'formik'
import {
   setFinalTipAmount,
   setGuestCardDetails,
   setGuestDraftCardDetails,
   setPaymentMethod,
   setPhoneNo,
   setSelectedItemId,
} from '../../../../app/services/store/ordering/GuestOrder/OrderSlice'
import {ICartItemState} from '../../../../app/services/store/ordering/cart/cartSlice'
import {setCartButtonDisplay} from '../../../../app/services/store/ordering/cart/CartButtonSlice'
import {CartBarItem} from './CartbarItem'
import {KTSVG} from '../../../helpers'
import EmptyCartPlaceholder from '../../../../app/pages/online_ordering/components/EmptyCartPlaceholder'
import Swal from 'sweetalert2'
import {setSelectedCardToPay} from '../../../../app/services/store/ticket/payment'
import {FormattedMessage, useIntl} from 'react-intl'
import {isStoreOnline} from '../../../../app/pages/online_ordering/common/utilities'

const CartBar = () => {
   const intl = useIntl()
   const {classes} = useLayout()
   const cbarRef = useRef<HTMLDivElement | null>(null)
   const LocSettings = useSelector((state: RootState) => state.LocSettings.value)
   const guest = useSelector((state: RootState) => state.guest)
   const dynamicBtnColor = useLocationBasedBtnColor()
   const dispatch = useDispatch<AppDispatch>()
   const fireErrorAlert = useErrorAlert()
   const navigateTo = useNavigate()
   const base_domain = LocSettings.base_domain
   const [cartTotal, setCartTotal] = useState('0.00')
   const oloSettings = useSelector((state: RootState) => state.LocSettings.value.olo)
   //incoming values are 1 and 0 --> 1 is true & 0 is false;
   const payByCreditOrDebitCard: number = oloSettings.pay_by_card
   const payByGiftCard: number = oloSettings.pay_by_gift_card
   const payInStore: number = oloSettings.pay_in_store
   const cartItems = useSelector((state: RootState) => state.cartItems.data)
   const isLocationScheduleAvailable = useSelector(
      (state: RootState) => state.locationSchedual.isLocationScheduleAvailable
   )

   useEffect(() => {
      let total: number = 0
      if (cartItems.length > 0) {
         cartItems.forEach((item) => {
            total += item.itemPrice * item.itemQty
            item.mods.forEach((mod) => {
               total += mod.price * item.itemQty
            })
         })
         setCartTotal((total / 100).toFixed(2))
      } else {
         setCartTotal('0.00')
      }
   }, [cartItems])

   if (Object.keys(LocSettings).length === 0) {
      return <></>
   }

   const removeItemFromCartClickHandler = (item: ICartItemState) => {
      dispatch(removeItemFromCart(item.id))
   }

   const handleclick = (item: ICartItemState) => {
      let categorySectionId = ''
      cartItems.forEach((e) => {
         if (e.itemId == item.itemId) categorySectionId = e.categorySectionId
      })

      dispatch(setCartItemIdToUpdate({id: item.id, itemId: item.itemId}))

      let itemId = item.itemId
      let categoryName = item.categoryName
      dispatch(setSelectedItemId(item.itemId))
      dispatch(setCartButtonDisplay(false))

      navigateTo(base_domain + StaticRoutePath.OLO_ITEM_DETAILS, {
         state: {itemId, categoryName, categorySectionId},
      })
      const cartToggleRef: HTMLElement | null = document.getElementById('kt_cbar_mobile_toggle')
      if (cartToggleRef) {
         //Toggles cart render state
         cartToggleRef.click()
      }
   }

   const handleCheckoutClick = () => {
      if (!payByCreditOrDebitCard && !payByGiftCard && !payInStore) {
         Swal.close()
         fireErrorAlert(intl.formatMessage({id: 'cartbar.location_has_no_payment_option'}))
         return
      }

      Swal.showLoading(null)
      isStoreOnline(LocSettings, (isOnline) => {
         Swal.close()
         if (isOnline) {
            dispatch(setFinalTipAmount('0.00'))
            const cartToggleRef: HTMLElement | null =
               document.getElementById('kt_cbar_mobile_toggle')
            if (cartToggleRef) {
               cartToggleRef.click()

               let cartOverLay: HTMLCollectionOf<Element> =
                  document.getElementsByClassName('drawer-overlay')
               if (cartOverLay.length > 0) cartOverLay[0].remove()

               dispatch(setCartButtonDisplay(false))
               if (!guest.auth.isAuthenticated) {
                  dispatch(setSelectedCardToPay({}))
                  dispatch(setPaymentMethod(null))
                  dispatch(setGuestCardDetails(null))
                  dispatch(setGuestDraftCardDetails(null))
                  navigateTo(`${base_domain}/auth`)
               } else {
                  dispatch(setPaymentMethod('Credit Card'))
                  navigateTo(`${base_domain}/olo/checkout`, {state: {checkoutPageAccess: true}})
               }
            }
         } else {
            fireErrorAlert(intl.formatMessage({id: 'olo.ordering_offline'}))
         }
      })
   }

   if (Object.keys(LocSettings).length === 0) {
      return <></>
   }

   return (
      <div
         id='kt_cartbar'
         className={clsx('aside theme-bg', classes.aside.join(' '))}
         data-kt-drawer='true'
         data-kt-drawer-name='cbar'
         data-kt-drawer-activate='{default: true, lg: true}'
         data-kt-drawer-overlay='true'
         data-kt-drawer-width="{default:'100%', 'sm': '100%', 'md': '385px'}"
         data-kt-drawer-direction='end'
         data-kt-drawer-toggle='#kt_cbar_mobile_toggle'
         ref={cbarRef}
         style={{position: 'fixed', right: '0', left: 'unset'}}
         data-kt-drawer-close='#kt_cartbar_close'
      >
         {/* begin::Brand */}
         <div className='card shadow-none rounded-0'>
            <div className='card-header px-10 px-md-5' id='kt_cartbar_header'>
               <h3 className='card-title fs-4 fw-bold text-gray-900  me-1 mb-2 lh-1'>
                  <FormattedMessage id='cartbar.your_order' defaultMessage='Your Order' />
               </h3>

               <div className='card-toolbar'>
                  <button type='button' className='btn btn-sm btn-icon me-n5' id='kt_cartbar_close'>
                     <KTSVG
                        path='/media/icons/duotune/arrows/arr061.svg'
                        className='svg-icon-1 text-primary'
                     />
                  </button>
               </div>
            </div>

            {cartItems.length == 0 ? (
               <>
                  <div className='card-body my-auto'>
                     <EmptyCartPlaceholder />
                  </div>
               </>
            ) : (
               <>
                  <div className='card-body position-relative px-10 px-md-5' id='kt_cartbar_body'>
                     <div
                        id='kt_cartbar_scroll'
                        className='position-relative scroll-y me-n5 pe-5'
                        data-kt-scroll='true'
                        data-kt-scroll-height='auto'
                        data-kt-scroll-wrappers='#kt_cartbar_body'
                        data-kt-scroll-dependencies='#kt_cartbar_header, #kt_cartbar_footer'
                        data-kt-scroll-offset='5px'
                     >
                        <div className='menu-state-bg menu-extended overflow-hidden overflow-lg-visible'>
                           {/* <TicketItemSummary/> */}
                           {cartItems.map((i: ICartItemState) => {
                              return <CartBarItem key={i.id} cartItem={i} />
                           })}
                        </div>
                     </div>
                  </div>
                  <div
                     className='card-footer py-5 text-center px-10 px-md-5'
                     id='kt_cartbar_footer'
                  >
                     <div className='row'>
                        <div className='col-7 '>
                           <p className='fw-bold text-gray-800 cursor-pointer fs-6 mb-0 mx-0 text-start'>
                              <FormattedMessage
                                 id='profile.receipt.subtotal'
                                 defaultMessage='Subtotal'
                              />
                           </p>
                        </div>
                        <div className='col-5'>
                           <p className='fw-bold text-gray-800 cursor-pointer fs-6 mb-0 mx-0 text-end'>
                              ${cartTotal}
                           </p>
                        </div>
                     </div>

                     <div className='text-center mx-0 mt-5 '>
                        <button
                           onClick={handleCheckoutClick}
                           type='button'
                           id='kt_checkout_submit'
                           className={`btn btn-lg btn-primary w-100 mb-5 ord-add-to-cart-btn my-auto ${dynamicBtnColor}`}
                        >
                           <span className='indicator-label my-auto'>
                              <FormattedMessage id='cartbar.checkout' defaultMessage='Checkout' />
                           </span>
                        </button>
                     </div>
                  </div>
               </>
            )}
         </div>
      </div>
   )
}

export {CartBar}
