import React, {useEffect} from 'react'
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../store";
import {getTicket, getUrlData, setTicket} from "../services/store/ticket";
import {LoadingPage} from "../modules/widgets/Loaders/LoadingPage";
import {setFlow} from "../services/SourceSlice";
import {constants} from "../config/constants";

export const ReceiptRedirect = () => {
    let [searchParams] = useSearchParams();
    const {short_code} = useParams();
    const dispatch = useDispatch<AppDispatch>();
    const navigateTo = useNavigate()
    const ticket:any = useSelector((state: RootState) => state.ticket)
    const query_params = searchParams.toString();

    useEffect(() => {
        dispatch(setTicket({}))
        dispatch(getUrlData(short_code))
        dispatch(setFlow(constants.flow.public_receipt))
    }, [])

    if(ticket.isLoading) {
        return <LoadingPage />
    }
    console.log('ticket',ticket);
   
    if(ticket?.fromUrl?.payment_id > 0) {
        navigateTo(`/${ticket.fromUrl.location_id}/rp/${short_code}?${query_params}`);
    }else  if(ticket?.fromUrl?.ticket_id > 0) {
        navigateTo(`/${ticket.fromUrl.location_id}/r/${short_code}?${query_params}`);
    }

    return (
        <>
            <h1>Receipt not found</h1>
        </>
    )
}
